import { useState, useEffect, useRef } from 'react';
import { CircularProgress, Modal, Tooltip, Paper, Tab, Tabs, Box, LinearProgress, Snackbar, Alert, IconButton, Typography } from '@mui/material';
import { Close, Edit, Check, ArrowForward, SwapHoriz, Assignment, Refresh } from '@mui/icons-material';
import Select from 'react-select';
import { twMerge } from 'tailwind-merge';
import { useDriverData } from './driverDataFetch';
import { useDockData, DockDoor } from '../DockManagement/dockDataFetch';
import axios from 'axios';
import React from 'react';

// Types for slots
type SlotType = 'dock' | 'yard';
type SlotStatus = 'available' | 'in-use' | 'unavailable' | 'scheduled';

interface Slot {
  id: string;
  number: string;
  type: SlotType;
  status: SlotStatus;
  assignedDriverId?: string | null;
  scheduledTimes?: {
    start: string;
    end: string;
    driverId?: string;
  }[];
}

const UnassignedSlotDriversWithRealData = () => {
  // Get real driver data from API using our custom hook
  const {
    loading: apiLoading,
    drivers: apiDrivers,
    error: driverError,
    facilities,
    selectedFacility,
    setSelectedFacility,
    timeInterval,
    setTimeInterval
  } = useDriverData();

  // Get real dock data from API
  const {
    loading: dockLoading,
    dockDoors,
    error: dockError,
    selectedFacility: dockSelectedFacility,
    setSelectedFacility: setDockSelectedFacility
  } = useDockData();

  // Local state
  const [slots, setSlots] = useState<Slot[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<any | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [assignmentType, setAssignmentType] = useState<'driver-to-slot' | 'slot-to-driver'>('driver-to-slot');
  const [tabValue, setTabValue] = useState(0);
  const [driverFilter, setDriverFilter] = useState('');
  const [slotFilter, setSlotFilter] = useState<SlotType | 'all'>('all');
  const [directionFilter, setDirectionFilter] = useState<'all' | 'inbound' | 'outbound'>('all');
  const [autoAssignStatus, setAutoAssignStatus] = useState<{
    active: boolean;
    current: number;
    total: number;
    success: number;
    error: number;
  }>({
    active: false,
    current: 0,
    total: 0,
    success: 0,
    error: 0
  });
  const [showAutoAssignResult, setShowAutoAssignResult] = useState(false);

  // Add a useRef for the refresh interval and a state for refresh indicators
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const refreshIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // Use effect to set time interval to max (8760 hours = 1 year)
  useEffect(() => {
    setTimeInterval(8760); // Always set to max (1 year)
  }, [setTimeInterval]);

  // Keep facility selection in sync between both hooks
  useEffect(() => {
    if (selectedFacility && (!dockSelectedFacility || dockSelectedFacility.id !== selectedFacility.id)) {
      setDockSelectedFacility(selectedFacility);
    }
  }, [selectedFacility, dockSelectedFacility, setDockSelectedFacility]);

  // Convert dock doors to slots format
  useEffect(() => {
    // Always update slots regardless of whether dockDoors has data
    const doorsToConvert = directionFilter === 'all' 
      ? dockDoors 
      : dockDoors.filter(door => door.group === directionFilter);
      
    const convertedSlots: Slot[] = doorsToConvert.map(door => ({
      id: door.id,
      number: door.number,
      type: 'dock' as SlotType,
      status: door.status as SlotStatus,
      assignedDriverId: door.assignedDriverId,
      scheduledTimes: door.scheduledTimes
    }));

    setSlots(convertedSlots);
  }, [dockDoors, directionFilter]);

  // Replace the refreshData function with direct API calls
  const refreshData = async (showIndicator = false) => {
    if (!selectedFacility) return;
    
    if (showIndicator) {
      setIsRefreshingData(true);
    }
    
    try {
      // Make direct API calls without changing state unnecessarily
      const READ_API_URL = process.env.REACT_APP_READ_API_URL;
      
      // Fetch drivers directly
      const driversResponse = await axios.get(
        `${READ_API_URL}/api/v1/admin/drivers/unassigned_slots?facility_id=${selectedFacility.id}&da_interval=${timeInterval}`
      );
      
      // Fetch dock slots directly
      const docksResponse = await axios.get(
        `${READ_API_URL}/api/v1/admin/drivers/assigned_dock_slots?facility_id=${selectedFacility.id}&da_interval=${timeInterval}`
      );
      
      // Only update state if data actually changed (you would need to implement comparison logic)
      // For now, we'll let the hooks handle data processing and state updates
      
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      if (showIndicator) {
        setTimeout(() => setIsRefreshingData(false), 300);
      }
    }
  };

  // Define the refresh function with useCallback to avoid dependency issues
  const memoizedRefreshData = React.useCallback(refreshData, [selectedFacility, timeInterval]);

  // Update the auto-refresh interval
  useEffect(() => {
    if (!selectedFacility) return;
    
    // Clear existing interval if any
    if (refreshIntervalRef.current) {
      clearInterval(refreshIntervalRef.current);
    }
    
    // Set up new interval - refresh silently (no indicator)
    refreshIntervalRef.current = setInterval(() => {
      memoizedRefreshData(false);
    }, 30000); // 30 seconds
    
    return () => {
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
        refreshIntervalRef.current = null;
      }
    };
  }, [selectedFacility, memoizedRefreshData]);

  // Manual refresh function - shows indicator
  const handleManualRefresh = () => {
    if (!isRefreshingData && selectedFacility) {
      refreshData(true);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const openAssignModal = (type: 'driver-to-slot' | 'slot-to-driver', driver?: any, slot?: Slot) => {
    setAssignmentType(type);
    if (driver) setSelectedDriver(driver);
    if (slot) setSelectedSlot(slot);
    setShowAssignModal(true);
  };

  const closeAssignModal = () => {
    setShowAssignModal(false);
    setSelectedDriver(null);
    setSelectedSlot(null);
  };

  const handleSingleAssignment = async (driver: any, slot: Slot): Promise<boolean> => {
    try {
      // Define the payload with proper TypeScript type that includes ALL possible properties
      interface AssignmentPayload {
        driver_id: any;
        bol_id: any;
        shipment_id: any;
        dock_door?: string;
        yard_spot?: string;
      }
      
      // Create the payload with required fields
      const payload: AssignmentPayload = {
        driver_id: driver.id,
        bol_id: driver.bol_id,
        shipment_id: driver.shipment_id
      };
      
      // Add either dock_door or yard_spot based on the slot type
      if (slot.type === 'dock') {
        payload.dock_door = slot.number;
      } else if (slot.type === 'yard') {
        payload.yard_spot = slot.number;
      }

      // Use the READ_API_URL prefix
      const READ_API_URL = process.env.REACT_APP_READ_API_URL;
      const response = await axios.post(
        `${READ_API_URL}/api/v1/admin/drivers/slot_message`, 
        payload
      );

      return true;
    } catch (error) {
      console.error('Error assigning slot:', error);
      return false;
    }
  };

  const handleAssignment = async () => {
    if (!selectedDriver || !selectedSlot) return;

    const success = await handleSingleAssignment(selectedDriver, selectedSlot);
    
    if (success) {
      // Update slot in local state
      const updatedSlots = slots.map(slot => 
        slot.id === selectedSlot.id 
          ? { ...slot, status: 'in-use' as SlotStatus, assignedDriverId: selectedDriver.id } 
          : slot
      );

      setSlots(updatedSlots);
      closeAssignModal();
      
      // Refresh data without causing a full re-render
      refreshData(true);
    } else {
      alert('Failed to assign driver to slot. Please try again.');
    }
  };

  const handleAutoAssignAll = async () => {
    // Get all unassigned API drivers
    const driversToAssign = [...unassignedDrivers];
    
    // Get all available slots
    const slotsToFill = [...availableSlots];
    
    // No drivers or slots to assign
    if (driversToAssign.length === 0 || slotsToFill.length === 0) {
      return;
    }
    
    // Determine how many assignments we'll make
    const maxAssignments = Math.min(driversToAssign.length, slotsToFill.length);
    
    // Initialize auto-assign status
    setAutoAssignStatus({
      active: true,
      current: 0,
      total: maxAssignments,
      success: 0,
      error: 0
    });
    
    // Track updates to slots
    let updatedSlots = [...slots];
    
    // Process each assignment individually with delay to show the process
    for (let i = 0; i < maxAssignments; i++) {
      const driver = driversToAssign[i];
      const slot = slotsToFill[i];
      
      // Update status to show current assignment
      setAutoAssignStatus(prev => ({
        ...prev,
        current: i + 1
      }));
      
      // Make the API call for this assignment
      const success = await handleSingleAssignment(driver, slot);
      
      // Update success/error count
      setAutoAssignStatus(prev => ({
        ...prev,
        success: success ? prev.success + 1 : prev.success,
        error: success ? prev.error : prev.error + 1
      }));
      
      if (success) {
        // Find the slot in the updatedSlots array and update it
        updatedSlots = updatedSlots.map(s => 
          s.id === slot.id 
            ? { ...s, status: 'in-use' as SlotStatus, assignedDriverId: driver.id } 
            : s
        );
        
        // Update slots after each successful assignment to show real-time progress
        setSlots(updatedSlots);
        
        // Add a small delay to make the visual process more visible
        await new Promise(resolve => setTimeout(resolve, 500));
      }
    }
    
    // Show results
    setShowAutoAssignResult(true);
    
    // Reset status after a delay
    setTimeout(() => {
      setAutoAssignStatus({
        active: false,
        current: 0,
        total: 0,
        success: 0,
        error: 0
      });
    }, 3000);
  };

  const handleUnassignment = (driverId: string, slotId: string) => {
    // Update slot
    const updatedSlots = slots.map(slot => 
      slot.id === slotId 
        ? { ...slot, status: 'available' as SlotStatus, assignedDriverId: null } 
        : slot
    );

    setSlots(updatedSlots);
  };

  const handleClearAll = () => {    
    const updatedSlots = slots.map(slot => ({
      ...slot,
      status: slot.status === 'in-use' ? 'available' as SlotStatus : slot.status,
      assignedDriverId: null
    }));
    
    // Update state
    setSlots(updatedSlots);
  };

  const getStatusColor = (status: SlotStatus): string => {
    switch (status) {
      case 'available':
        return 'bg-green-500';
      case 'in-use':
        return 'bg-red-500';
      case 'unavailable':
        return 'bg-gray-500';
      case 'scheduled':
        return 'bg-yellow-500';
      default:
        return 'bg-gray-500';
    }
  };

  const getDriverTypeColor = (type: string): string => {
    switch (type) {
      case 'outbound':
        return 'bg-blue-100 text-blue-800';
      case 'inbound':
        return 'bg-green-100 text-green-800';
      case 'both':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Get facility options for filter
  const facilityOptions = facilities.map(facility => ({
    value: facility,
    label: `(${facility.id}) ${facility.name}`
  }));

  // Filter unassigned drivers - use real API data
  const unassignedDrivers = apiDrivers
    .filter(driver => !slots.some(slot => slot.assignedDriverId === driver.id))
    .filter(driver => 
      driver.name.toLowerCase().includes(driverFilter.toLowerCase()) || 
      driver.loadNumber?.toLowerCase().includes(driverFilter.toLowerCase())
    );

  // Filter available slots
  const availableSlots = slots
    .filter(slot => slot.status === 'available')
    .filter(slot => slotFilter === 'all' ? true : slot.type === slotFilter);

  // Filter assigned pairs
  const assignedPairs = slots
    .filter(slot => slot.assignedDriverId)
    .map(slot => {
      const driver = apiDrivers.find(d => d.id === slot.assignedDriverId);
      return { slot, driver };
    })
    .filter(pair => pair.driver !== undefined);

  const loading = apiLoading || dockLoading;

  if (loading) {
    return (
      <div className="flex flex-col items-center mt-4 w-full">
        <CircularProgress />
        <p className="mt-2">Loading...</p>
      </div>
    );
  }

  if (driverError || dockError) {
    return (
      <div className="p-5 text-red-500">
        <h1 className="font-bold text-xl mb-3">Error</h1>
        <p>{driverError || dockError}</p>
      </div>
    );
  }

  return (
    <div className="p-5">
      <div className="shadow bg-[#FBFBFB] p-3 mb-5">
        <div className="flex flex-col md:flex-row gap-3 mb-3">
          <div>
            <h1 className="font-bold text-xl mb-3">Driver Assignments</h1>
            <p>Match drivers to dock doors and yard spots.</p>
          </div>
          <div className="flex gap-3 ml-auto">
            <div className="flex items-center mr-2">
              {isRefreshingData ? (
                <div className="flex items-center">
                  <CircularProgress size={16} className="mr-2" />
                  <Typography variant="caption" color="textSecondary">
                    Refreshing...
                  </Typography>
                </div>
              ) : (
                <IconButton 
                  size="small" 
                  onClick={handleManualRefresh} 
                  title="Refresh"
                >
                  <Refresh fontSize="small" />
                </IconButton>
              )}
            </div>
            <Select
              options={facilityOptions}
              isSearchable={true}
              isClearable
              value={facilityOptions.find(option => option.value.id === selectedFacility?.id)}
              placeholder="Facilities"
              onChange={(selectedOption: any) => {
                setSelectedFacility(selectedOption?.value);
              }}
              className="w-60"
            />
            <div className="w-40 flex items-center justify-center border rounded px-3 py-2 bg-gray-50">
              <span className="text-sm font-medium">Time: Max</span>
            </div>
          </div>
        </div>
      </div>

    

      {tabValue === 0 && (
        <div className="flex flex-col md:flex-row gap-5">
          {/* Unassigned Drivers Panel */}
          <div className="w-full md:w-1/2 bg-white p-4 shadow">
            <div className="flex justify-between items-center mb-4">
              <h2 className="font-bold text-lg">Unassigned Drivers ({unassignedDrivers.length})</h2>
              <div className="flex gap-2">
                <input 
                  type="text" 
                  placeholder="Search drivers..." 
                  className="border rounded p-2 text-sm w-40"
                  value={driverFilter}
                  onChange={(e) => setDriverFilter(e.target.value)}
                />
                <button 
                  className="bg-sky-600 hover:bg-sky-500 text-white px-3 py-1 rounded text-sm"
                  disabled={unassignedDrivers.length === 0}
                  onClick={() => unassignedDrivers.length > 0 && openAssignModal('driver-to-slot', unassignedDrivers[0])}
                >
                  Assign Next
                </button>
              </div>
            </div>
            
            <div className="max-h-96 overflow-y-auto">
              {unassignedDrivers.length === 0 ? (
                <div className="text-center py-4 text-gray-500">
                  No unassigned drivers
                </div>
              ) : (
                <div className="space-y-2">
                  {unassignedDrivers.map(driver => (
                    <div 
                      key={driver.id} 
                      className="p-3 border rounded mb-3 bg-gray-50 hover:bg-gray-100 cursor-pointer"
                      onClick={() => openAssignModal('driver-to-slot', driver)}
                    >
                      <div className="flex justify-between">
                        <span className="font-bold">{driver.name}</span>
                        <span className={`text-xs px-2 py-1 rounded ${getDriverTypeColor(driver.type)}`}>
                          {driver.type.charAt(0).toUpperCase() + driver.type.slice(1)}
                        </span>
                      </div>
                      <p className="text-sm text-gray-600">Ref #: {driver.loadNumber}</p>
                      {driver.sealNumber && (
                        <p className="text-sm">
                          <span className="font-medium">Seal:</span> {driver.sealNumber}
                        </p>
                      )}
                      <div className="flex gap-2">
                        <p className="text-sm">
                          <span className="font-medium">Truck:</span> {driver.truckNumber}
                        </p>
                        {driver.trailerNumber && (
                          <p className="text-sm">
                            <span className="font-medium">Trailer:</span> {driver.trailerNumber}
                          </p>
                        )}
                      </div>
                      {driver.weight && (
                        <p className="text-sm">
                          <span className="font-medium">Weight:</span> {driver.weight}
                        </p>
                      )}
                      <div className="flex justify-between mt-2 text-xs text-gray-500">
                        <span>Checked in: {driver.arrivalTime}</span>
                       
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          
          {/* Available Slots Panel */}
          <div className="w-full md:w-1/2 bg-white p-4 shadow">
            <div className="flex justify-between items-center mb-4">
              <h2 className="font-bold text-lg">Available Slots ({availableSlots.length})</h2>
              <div className="flex gap-2">
                <Select
                  options={[
                    { value: 'all', label: 'All Types' },
                    { value: 'dock', label: 'Dock Doors Only' },
                    { value: 'yard', label: 'Yard Spots Only' }
                  ]}
                  placeholder="Filter Slots"
                  className="w-40"
                  defaultValue={{ value: 'all', label: 'All Types' }}
                  onChange={(option: any) => setSlotFilter(option.value)}
                />
                <Select
                  options={[
                    { value: 'all', label: 'All Directions' },
                    { value: 'inbound', label: 'Inbound' },
                    { value: 'outbound', label: 'Outbound' }
                  ]}
                  placeholder="Direction"
                  className="w-40"
                  defaultValue={{ value: 'all', label: 'All Directions' }}
                  onChange={(option: any) => {
                    setDirectionFilter(option.value);
                  }}
                />
                <button 
                  className="bg-sky-600 hover:bg-sky-500 text-white px-3 py-1 rounded text-sm"
                  disabled={availableSlots.length === 0 || unassignedDrivers.length === 0}
                  onClick={() => availableSlots.length > 0 && openAssignModal('slot-to-driver', undefined, availableSlots[0])}
                >
                  Assign First
                </button>
              </div>
            </div>
            
            <div className="max-h-96 overflow-y-auto">
              {availableSlots.length === 0 ? (
                <div className="text-center py-8 text-gray-500">
                  No available slots
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                  {availableSlots.map(slot => (
                    <div 
                      key={slot.id} 
                      className="border rounded p-3 bg-green-50 border-green-200 cursor-pointer"
                      onClick={() => openAssignModal('slot-to-driver', undefined, slot)}
                    >
                      <div className="flex justify-between items-center">
                        <span className="font-bold">{slot.type === 'dock' ? 'Door' : 'Spot'} {slot.number}</span>
                        <span className="text-xs px-2 py-1 rounded bg-green-100 text-green-800">
                          Available
                        </span>
                      </div>
                      <p className="text-sm text-gray-600 mt-1">
                        {slot.type === 'dock' ? 'Dock Door' : 'Yard Spot'}
                        {dockDoors.find(door => door.id === slot.id)?.group && 
                          ` (${dockDoors.find(door => door.id === slot.id)?.group})`}
                      </p>
                      <div className="mt-2 flex justify-end">
                       
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      

      {/* Assignment Modal */}
      {showAssignModal && (
        <Modal
          open={showAssignModal}
          onClose={closeAssignModal}
          className="flex justify-center items-center"
        >
          <div className="w-96 bg-white border p-5 max-h-[80vh] overflow-y-auto relative flex flex-col">
            <Close
              className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
              onClick={closeAssignModal}
            />
            <h1 className="text-xl mb-5">
              {assignmentType === 'driver-to-slot' 
                ? 'Assign Driver to Slot' 
                : 'Assign Slot to Driver'}
            </h1>
            
            {/* Driver Selection */}
            <div className="mb-4">
              <h2 className="font-bold mb-2">Driver</h2>
              {selectedDriver ? (
                <div className="p-3 border rounded bg-gray-50">
                  <div className="flex justify-between">
                    <span className="font-bold">{selectedDriver.name}</span>
                    <span className={`text-xs px-2 py-1 rounded ${getDriverTypeColor(selectedDriver.type)}`}>
                      {selectedDriver.type.charAt(0).toUpperCase() + selectedDriver.type.slice(1)}
                    </span>
                  </div>
                  <p className="text-sm text-gray-600">Ref #: {selectedDriver.loadNumber}</p>
                  <p className="text-xs text-gray-500">
                    Checked in: {selectedDriver.arrivalTime}
                  </p>
                </div>
              ) : (
                <Select
                  options={unassignedDrivers.map(driver => ({
                    value: driver.id,
                    label: `${driver.name} - ${driver.loadNumber || 'No Load'}`
                  }))}
                  placeholder="Select Driver"
                  onChange={(option: any) => {
                    const driver = apiDrivers.find(d => d.id === option.value);
                    if (driver) setSelectedDriver(driver);
                  }}
                />
              )}
            </div>
            
            {/* Slot Selection */}
            <div className="mb-4">
              <h2 className="font-bold mb-2">Door</h2>
              {selectedSlot ? (
                <div className="p-3 border rounded bg-green-50">
                  <div className="flex justify-between items-center">
                    <span className="font-bold">{selectedSlot.type === 'dock' ? 'Door' : 'Spot'} {selectedSlot.number}</span>
                    <span className="text-xs px-2 py-1 rounded bg-green-100 text-green-800">
                      Available
                    </span>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">
                    {selectedSlot.type === 'dock' ? 'Dock Door' : 'Yard Spot'}
                    {dockDoors.find(door => door.id === selectedSlot.id)?.group && 
                      ` (${dockDoors.find(door => door.id === selectedSlot.id)?.group})`}
                  </p>
                </div>
              ) : (
                <Select
                  options={availableSlots.map(slot => ({
                    value: slot.id,
                    label: `${slot.type === 'dock' ? 'Door' : 'Spot'} ${slot.number}${
                      dockDoors.find(door => door.id === slot.id)?.group 
                        ? ` (${dockDoors.find(door => door.id === slot.id)?.group})`
                        : ''
                    }`
                  }))}
                  placeholder="Select Slot"
                  onChange={(option: any) => {
                    const slot = slots.find(s => s.id === option.value);
                    if (slot) setSelectedSlot(slot);
                  }}
                />
              )}
            </div>
            
            {/* Activity Type */}
            <div className="mb-4">
              <h2 className="font-bold mb-2">Activity Type</h2>
              <Select
                options={[
                  { value: 'drop', label: 'Drop' },
                  { value: 'live_outbound', label: 'Live Outbound' },
                  { value: 'live_inbound', label: 'Live Inbound' },
                  { value: 'hook', label: 'Hook' },
                  { value: 'drop_and_hook', label: 'Drop & Hook' }
                ]}
                placeholder="Activity Type"
              />
            </div>
            
            {/* Notes */}
            <div className="mb-4">
              <h2 className="font-bold mb-2">Notes (Optional)</h2>
              <textarea
                className="w-full border rounded p-2 h-20"
                placeholder="Add any notes about this assignment"
              />
            </div>
            
            <div className="mt-auto pt-3 border-t flex justify-between">
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 p-2 rounded"
                onClick={closeAssignModal}
              >
                Cancel
              </button>
              <button
                className={twMerge(
                  'bg-sky-600 hover:bg-sky-500 text-white p-2 rounded',
                  (!selectedDriver || !selectedSlot) && 'bg-gray-300 hover:bg-gray-300 cursor-not-allowed'
                )}
                onClick={handleAssignment}
                disabled={!selectedDriver || !selectedSlot}
              >
                Confirm Assignment
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* Visual Assignment Board */}
      <div className="mt-8 bg-white p-4 shadow">
        <h2 className="font-bold text-lg mb-4">Visual Assignment Board</h2>
        
        <div className="flex flex-col md:flex-row gap-6">
          {/* Unassigned Drivers Column */}
          <div className="md:w-1/3 bg-gray-50 p-3 rounded-md">
            <h3 className="font-bold mb-3 text-center text-gray-700">Unassigned Drivers</h3>
            
            <div className="space-y-2 max-h-96 overflow-y-auto p-1">
              {unassignedDrivers.length === 0 ? (
                <div className="text-center py-4 text-gray-500">
                  No unassigned drivers
                </div>
              ) : (
                unassignedDrivers.map(driver => (
                  <div 
                    key={driver.id} 
                    className="p-2 border rounded bg-white hover:bg-gray-100 cursor-pointer"
                    onClick={() => openAssignModal('driver-to-slot', driver)}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-bold">{driver.name}</p>
                        <p className="text-xs text-gray-600">{driver.loadNumber}</p>
                      </div>
                      <ArrowForward className="text-sky-600" />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          
          {/* Connection Zone */}
          <div className="md:w-1/3 flex flex-col justify-center items-center">
            <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 w-full">
              <h3 className="font-bold text-center text-blue-700 mb-3">Create Assignment</h3>
              
              <div className="flex justify-center mb-4">
                <button 
                  className="bg-sky-600 hover:bg-sky-500 text-white px-4 py-2 rounded"
                  onClick={() => openAssignModal('driver-to-slot')}
                >
                  Assign Driver to Slot
                </button>
              </div>
              
              <div className="text-center text-sm text-gray-600">
                <p>Select a driver or slot to begin</p>
                <p>or use the quick assignment options</p>
              </div>
              
              <div className="flex justify-center mt-4 gap-2 flex-col">
                <button 
                  className={`${autoAssignStatus.active ? 'bg-blue-400 cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-300'} text-gray-800 px-3 py-1 rounded text-sm flex items-center justify-center gap-1`}
                  onClick={handleAutoAssignAll}
                  disabled={unassignedDrivers.length === 0 || availableSlots.length === 0 || autoAssignStatus.active}
                >
                  {autoAssignStatus.active ? (
                    <>Processing...</>
                  ) : (
                    <>
                      <Assignment fontSize="small" />
                      Auto-Assign All
                    </>
                  )}
                </button>
                
                {autoAssignStatus.active && (
                  <div className="mt-2">
                    <div className="text-xs text-gray-600 mb-1 flex justify-between">
                      <span>Assigning {autoAssignStatus.current} of {autoAssignStatus.total}</span>
                      <span>{Math.round((autoAssignStatus.current / autoAssignStatus.total) * 100)}%</span>
                    </div>
                    <LinearProgress 
                      variant="determinate" 
                      value={(autoAssignStatus.current / autoAssignStatus.total) * 100} 
                      color="primary"
                    />
                    <div className="flex justify-between text-xs text-gray-600 mt-1">
                      <span className="text-green-600">Success: {autoAssignStatus.success}</span>
                      {autoAssignStatus.error > 0 && (
                        <span className="text-red-600">Failed: {autoAssignStatus.error}</span>
                      )}
                    </div>
                  </div>
                )}
                
                <button 
                  className="bg-gray-200 hover:bg-gray-300 text-gray-800 px-3 py-1 rounded text-sm mt-2"
                  onClick={handleClearAll}
                  disabled={assignedPairs.length === 0 || autoAssignStatus.active}
                >
                  Clear All
                </button>
              </div>
            </div>
          </div>
          
          {/* Available Slots Column */}
          <div className="md:w-1/3 bg-gray-50 p-3 rounded-md">
            <h3 className="font-bold mb-3 text-center text-gray-700">Available Slots</h3>
            
            <div className="space-y-2 max-h-96 overflow-y-auto p-1">
              {availableSlots.length === 0 ? (
                <div className="text-center py-4 text-gray-500">
                  No available slots
                </div>
              ) : (
                availableSlots.map(slot => (
                  <div 
                    key={slot.id} 
                    className="p-2 border rounded bg-white hover:bg-gray-100 cursor-pointer"
                    onClick={() => openAssignModal('slot-to-driver', undefined, slot)}
                  >
                    <div className="flex justify-between items-center">
                      <ArrowForward className="text-sky-600 transform rotate-180" />
                      <div className="text-right">
                        <p className="font-bold">{slot.type === 'dock' ? 'Door' : 'Spot'} {slot.number}</p>
                        <p className="text-xs text-gray-600">{slot.type === 'dock' ? 'Dock Door' : 'Yard Spot'}</p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      <Snackbar 
        open={showAutoAssignResult} 
        autoHideDuration={6000} 
        onClose={() => setShowAutoAssignResult(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowAutoAssignResult(false)} 
          severity={autoAssignStatus.error > 0 ? "warning" : "success"} 
          sx={{ width: '100%' }}
        >
          Auto-assignment complete: {autoAssignStatus.success} successful, {autoAssignStatus.error} failed
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UnassignedSlotDriversWithRealData; 