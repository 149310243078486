import axios from 'axios';
import { useState, useEffect } from 'react';

// Types for dock doors and activities
export type ScheduledTime = {
  start: string;
  end: string;
  shipmentId?: string;
};

export type Status = 'available' | 'in-use' | 'unavailable' | 'scheduled';

export type SpotGroup = 'inbound' | 'outbound' | 'unassigned' | 'assigned';

export type DockDoor = {
  id: string;
  number: string;
  status: Status;
  group: SpotGroup;
  assignedDriverId?: string | null;
  driverName?: string | null;  // Add driver name
  shipmentNumber?: string | null;  // Add shipment number (PUID)
  trailerNumber?: string | null;  // Add trailer number
  scheduledTimes: ScheduledTime[];
};

export type HistoryEntry = {
  driverId: string;
  driverName: string;
  arrivalTime: string;
  departureTime: string;
  duration: number; // in minutes
  activity: 'load' | 'unload' | 'both';
  shipmentId: string;
};

export type DockHistory = {
  [dockId: string]: HistoryEntry[];
};

// API response type
interface ApiDockSlot {
  id: string;
  number: string;
  status?: string;
  group?: string;
  direction?: string;
  assigned_driver_id?: string | null;
  dock_number?: string;
  dock_door?: string;
  driver_id?: number;
  driver_name?: string;
  puid?: string;
  trailer_number?: string;
  scheduled_times?: {
    start: string;
    end: string;
    shipment_id?: string;
  }[];
  history?: {
    driver_id: string;
    driver_name: string;
    arrival_time: string;
    departure_time: string;
    duration: number;
    activity: string;
    shipment_id: string;
  }[];
}

// Convert API data to our DockDoor format
const mapApiDockSlotToDockDoor = (slot: ApiDockSlot): DockDoor => {
  // Extract just the number part from dock_number (e.g., "D7" from "DOCK:D7" or just "D7")
  const doorNumber = slot.dock_number || 
                     (slot.dock_door && slot.dock_door.includes(':') ? 
                      slot.dock_door.split(':')[1] : slot.dock_door) || 
                     slot.number;
  
  // Determine if this door has an assigned driver, trailer, or shipment
  const hasAssignedDriver = !!(slot.driver_id || slot.assigned_driver_id);
  const hasTrailer = !!slot.trailer_number;
  const hasShipment = !!slot.puid;
  const isInUse = hasAssignedDriver || hasTrailer || hasShipment;
  
  // Determine the group - if a driver is assigned, mark as 'assigned'
  // Or use the direction as the group if provided
  let group: SpotGroup = 'unassigned';
  if (slot.group) {
    group = slot.group as SpotGroup;
  } else if (slot.direction === 'inbound') {
    group = 'inbound';
  } else if (slot.direction === 'outbound') {
    group = 'outbound';
  } else if (isInUse) {
    group = 'assigned';
  }
  
  return {
    id: slot.id,
    number: doorNumber,
    status: (slot.status as Status) || (isInUse ? 'in-use' : 'available'),
    group: group,
    assignedDriverId: slot.assigned_driver_id || (slot.driver_id?.toString() || null),
    driverName: slot.driver_name || null,
    shipmentNumber: slot.puid || null,
    trailerNumber: slot.trailer_number || null,
    scheduledTimes: (slot.scheduled_times || []).map(time => ({
      start: time.start,
      end: time.end,
      shipmentId: time.shipment_id
    }))
  };
};

// Convert API history data to our HistoryEntry format
const mapApiHistoryToHistoryEntry = (entry: any): HistoryEntry => {
  return {
    driverId: entry.driver_id,
    driverName: entry.driver_name,
    arrivalTime: entry.arrival_time,
    departureTime: entry.departure_time,
    duration: entry.duration,
    activity: entry.activity as 'load' | 'unload' | 'both',
    shipmentId: entry.shipment_id
  };
};

// Custom hook to fetch dock data
export const useDockData = (facilityId?: number) => {
  const [loading, setLoading] = useState(false);
  const [dockDoors, setDockDoors] = useState<DockDoor[]>([]);
  const [dockHistory, setDockHistory] = useState<DockHistory>({});
  const [error, setError] = useState<string | null>(null);
  const [facilities, setFacilities] = useState<{ id: number; name: string }[]>([]);
  const [selectedFacility, setSelectedFacility] = useState<{ id: number; name: string } | null>(null);
  const [timeInterval, setTimeInterval] = useState(12); // Default 12 hours

  // Fetch facilities
  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const READ_API_URL = process.env.REACT_APP_READ_API_URL;
        const response = await axios.get(`${READ_API_URL}/api/v1/admin/facilities`);
        setFacilities(response.data);
        
        // Set default facility if one was provided or use the first one
        if (facilityId) {
          const facility = response.data.find((f: any) => f.id === facilityId);
          if (facility) setSelectedFacility(facility);
        } else if (response.data.length > 0) {
          setSelectedFacility(response.data[0]);
        }
      } catch (err) {
        setError('Failed to fetch facilities');
        console.error(err);
      }
    };
    
    fetchFacilities();
  }, [facilityId]);

  // Initialize a base set of dock doors only once
  useEffect(() => {
    // Create a base set of dock doors (this would normally come from another API)
    const createBaseDockDoors = (): DockDoor[] => {
      return Array.from({ length: 20 }, (_, i) => {
        const doorNumber = `D${i + 1}`;
        return {
          id: `dock-${i + 1}`,
          number: doorNumber, // Just the door number (e.g., "D7") without any prefix
          status: 'available' as Status,
          group: 'unassigned' as SpotGroup,
          assignedDriverId: null,
          scheduledTimes: []
        };
      });
    };

    // Set initial dock doors only once
    setDockDoors(createBaseDockDoors());
  }, []); // Empty dependency array ensures this runs only once

  // Fetch dock data when facility changes
  useEffect(() => {
    if (!selectedFacility) return;
    
    const fetchDockData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        const READ_API_URL = process.env.REACT_APP_READ_API_URL;
        const response = await axios.get(
          `${READ_API_URL}/api/v1/admin/drivers/assigned_dock_slots?facility_id=${selectedFacility.id}&da_interval=${timeInterval}`
        );
        
        // Debug: Log the API response
        console.log("API Dock Slots Response:", response.data);
        
        // Map API data to our formats
        const assignedDockDoors: DockDoor[] = response.data.map(mapApiDockSlotToDockDoor);
        
        // Debug: Log the mapped doors
        console.log("Mapped Dock Doors:", assignedDockDoors);
        
        setDockDoors(prevDockDoors => {
          // Create a new array combining our base doors with updated assigned doors
          const updatedDockDoors: DockDoor[] = [...prevDockDoors];
          
          // Debug: Log the base doors
          console.log("Base Dock Doors:", updatedDockDoors.map(d => d.number));
          
          // Update existing doors with assigned data from API
          assignedDockDoors.forEach((assignedDoor: DockDoor) => {
            // Debug: Log which door we're trying to match
            console.log(`Trying to match door: ${assignedDoor.number}`);
            
            const existingDoorIndex = updatedDockDoors.findIndex(
              (door: DockDoor) => door.number === assignedDoor.number
            );
            
            if (existingDoorIndex >= 0) {
              // Debug: Log when we find a match
              console.log(`Found matching door at index ${existingDoorIndex}: ${updatedDockDoors[existingDoorIndex].number}`);
              
              // Update existing door with assigned data
              updatedDockDoors[existingDoorIndex] = assignedDoor;
            } else {
              // Debug: Log when we don't find a match
              console.log(`No match found for door ${assignedDoor.number}, adding as new door`);
              
              // Add new door if it doesn't exist in our list
              updatedDockDoors.push(assignedDoor);
            }
          });
          
          // Debug: Log the final updated doors
          console.log("Final Updated Dock Doors:", updatedDockDoors);
          
          return updatedDockDoors;
        });
        
        // Process history data
        const history: DockHistory = {};
        response.data.forEach((slot: ApiDockSlot) => {
          if (slot.history && slot.history.length > 0) {
            history[slot.id] = slot.history.map(mapApiHistoryToHistoryEntry);
          }
        });
        setDockHistory(history);
      } catch (err) {
        setError('Failed to fetch dock data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    
    // Fetch immediately and then every 30 seconds
    fetchDockData();
    
  }, [selectedFacility, timeInterval]); // Only depend on selectedFacility and timeInterval

  return {
    loading,
    dockDoors,
    dockHistory,
    error,
    facilities,
    selectedFacility,
    setSelectedFacility,
    timeInterval,
    setTimeInterval
  };
}; 