import { useState, useEffect, useRef, useContext } from 'react';
import { 
  CircularProgress, 
  Tabs, 
  Tab, 
  Button, 
  TextField, 
  Avatar, 
  Badge, 
  IconButton,
  Divider,
  Paper,
  InputAdornment,
  Typography,
  Menu,
  MenuItem,
  Chip
} from '@mui/material';
import { 
  Search, 
  Send, 
  MoreVert, 
  Phone, 
  AttachFile, 
  FilterList,
  CheckCircle,
  AccessTime,
  ExitToApp,
  Error as ErrorIcon,
  Refresh
} from '@mui/icons-material';
import Select from 'react-select';
import axios from 'axios';
import { UserContext } from '../../App';

// Environment variables
const READ_API_URL = process.env.REACT_APP_READ_API_URL;
const WRITE_API_URL = process.env.REACT_APP_WRITE_API_URL;
const REFRESH_INTERVAL = 2500; // 30 seconds refresh interval

// Types
interface Facility {
  id: number;
  name: string;
}

interface ActiveChat {
  shipment_id: string;
  shipment_number: string;
  driver_name: string;
  driver_phone: string;
  truck_number: string;
  trailer_number: string;
  last_activity: string;
  last_message: string;
  message_count: number;
}

interface DriverWithoutChat {
  driver_id: string;
  driver_name: string;
  driver_phone: string;
  shipment_id: string;
  shipment_number: string;
  truck_number: string;
  trailer_number: string;
  display_text: string;
}

interface ChatHistoryMessage {
  message: string;
  timestamp: string;
  sender: 'YARD' | 'DRIVER';
  sender_name?: string; // Sender name for YARD messages
}

interface Message {
  id: string;
  senderId: string;
  senderName: string;
  senderRole: 'driver' | 'staff';
  content: string;
  timestamp: Date;
  isRead: boolean;
}

type DriverStatus = 'pre-checkin' | 'checkin' | 'checkout';

// Helper functions
const formatTime = (date: Date): string => {
  return date.toLocaleTimeString('en-US', { 
    hour: 'numeric', 
    minute: '2-digit',
    hour12: true 
  });
};

const formatDate = (date: Date): string => {
  const today = new Date();
  
  if (date.toDateString() === today.toDateString()) {
    return formatTime(date);
  }
  
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  
  if (date.toDateString() === yesterday.toDateString()) {
    return 'Yesterday ' + formatTime(date);
  }
  
  return date.toLocaleDateString('en-US', { 
    month: 'short', 
    day: 'numeric' 
  }) + ' ' + formatTime(date);
};

const getInitials = (name: string | null | undefined): string => {
  if (!name) return ''; // Return empty string if name is null or undefined
  
  return name
    .split(' ')
    .map(part => part[0])
    .join('')
    .toUpperCase();
};

function DriverComms() {
  // Add UserContext
  // @ts-ignore
  const { user } = useContext(UserContext);
  
  // State for facilities
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [selectedFacility, setSelectedFacility] = useState<Facility | null>(null);
  
  // State for drivers and chats
  const [activeChats, setActiveChats] = useState<ActiveChat[]>([]);
  const [driversWithoutChats, setDriversWithoutChats] = useState<DriverWithoutChat[]>([]);
  const [filteredDriversList, setFilteredDriversList] = useState<(ActiveChat | DriverWithoutChat)[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<ActiveChat | DriverWithoutChat | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  
  // UI state
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [tabValue, setTabValue] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  
  const messagesEndRef = useRef<HTMLDivElement>(null);
  
  // Add refs for interval management
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  
  // Function to fetch drivers and chats
  const fetchDriversAndChats = async (showLoadingIndicator = true) => {
    if (!selectedFacility) return Promise.resolve();
    
    if (showLoadingIndicator) {
      setLoading(true);
    } else {
      setIsRefreshing(true);
    }
    
    setError(null);
    
    try {
      // Fetch active chats
      const activeChatsResponse = await axios.get(
        `${READ_API_URL}/api/v1/admin/active_chats?facility_id=${selectedFacility.id}`
      );
      setActiveChats(activeChatsResponse.data.active_chats);
      
      // Fetch drivers without chats
      const driversWithoutChatsResponse = await axios.get(
        `${READ_API_URL}/api/v1/admin/drivers/without_chats?facility_id=${selectedFacility.id}${searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : ''}`
      );
      setDriversWithoutChats(driversWithoutChatsResponse.data.results);
      
      // Combine and filter based on current tab and search
      const combined = [...activeChatsResponse.data.active_chats, ...driversWithoutChatsResponse.data.results];
      setFilteredDriversList(filterDriversList(combined, tabValue, searchTerm));
      
      console.log('Data refreshed successfully');
    } catch (err) {
      console.error('Failed to fetch drivers and chats:', err);
      setError('Failed to fetch drivers and chats');
    } finally {
      if (showLoadingIndicator) {
        setLoading(false);
      } else {
        // Small delay to ensure the refreshing indicator is visible
        setTimeout(() => setIsRefreshing(false), 500);
      }
    }
    
    return Promise.resolve();
  };
  
  // Function to fetch messages for the selected driver
  const fetchMessages = async (driver: ActiveChat | DriverWithoutChat, showLoadingIndicator = true) => {
    if (showLoadingIndicator) {
      setLoadingMessages(true);
    }
    
    try {
      // Fetch chat history from the API
      const response = await axios.get(
        `${READ_API_URL}/api/v1/admin/shipments/${driver.shipment_id}/chat`
      );
      
      if (response.data.chat_history && Array.isArray(response.data.chat_history)) {
        // Convert the API response to our Message format
        const chatMessages: Message[] = response.data.chat_history.map((chatMsg: ChatHistoryMessage, index: number) => ({
          id: `msg-${index}-${new Date(chatMsg.timestamp).getTime()}`,
          senderId: chatMsg.sender === 'DRIVER' ? 'driver-id' : 'staff-id',
          senderName: chatMsg.sender === 'DRIVER' 
            ? driver.driver_name 
            : (chatMsg.sender_name || (user?.name || 'Facility Staff')),
          senderRole: chatMsg.sender === 'DRIVER' ? 'driver' : 'staff',
          content: chatMsg.message,
          timestamp: new Date(chatMsg.timestamp),
          isRead: true
        }));
        
        setMessages(chatMessages);
        
        // If there are no messages yet, show a welcome message for new chats
        if (chatMessages.length === 0) {
          setMessages([{
            id: 'msg-system-new',
            senderId: 'system',
            senderName: 'System',
            senderRole: 'staff',
            content: `Start a new conversation with ${driver.driver_name}`,
            timestamp: new Date(),
            isRead: true
          }]);
        }
      } else {
        // Fallback in case the API doesn't return expected format
        setMessages([{
          id: 'msg-system-new',
          senderId: 'system',
          senderName: 'System',
          senderRole: 'staff',
          content: `Start a new conversation with ${driver.driver_name}`,
          timestamp: new Date(),
          isRead: true
        }]);
      }
      
      console.log('Messages refreshed successfully');
    } catch (err) {
      console.error('Error fetching chat history:', err);
      // Show a fallback message only if this is the initial load
      if (showLoadingIndicator) {
        setMessages([{
          id: 'msg-system-error',
          senderId: 'system',
          senderName: 'System',
          senderRole: 'staff',
          content: 'Could not load chat history. Please try again later.',
          timestamp: new Date(),
          isRead: true
        }]);
      }
    } finally {
      if (showLoadingIndicator) {
        setLoadingMessages(false);
      }
    }
    
    return Promise.resolve();
  };
  
  // Fetch facilities
  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const response = await axios.get(`${READ_API_URL}/api/v1/admin/facilities`);
        setFacilities(response.data);
        
        // Set default facility to the first one
        if (response.data.length > 0) {
          setSelectedFacility(response.data[0]);
        }
      } catch (err) {
        setError('Failed to fetch facilities');
        console.error(err);
      }
    };
    
    fetchFacilities();
  }, []);
  
  // Fetch drivers and chats when facility changes
  useEffect(() => {
    if (!selectedFacility) return;
    
    fetchDriversAndChats(true);
  }, [selectedFacility, searchTerm]);
  
  // Auto-refresh data at regular intervals
  useEffect(() => {
    if (!selectedFacility) return;
    
    console.log('Setting up auto-refresh interval');
    
    // Clear existing interval if any
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    
    // Set up interval for refreshing data
    intervalRef.current = setInterval(() => {
      console.log('Auto-refreshing data...');
      // Refresh drivers and chats data without showing loading indicator
      fetchDriversAndChats(false);
      
      // Refresh messages for selected driver without showing loading indicator
      if (selectedDriver) {
        fetchMessages(selectedDriver, false);
      }
    }, REFRESH_INTERVAL);
    
    // Clean up interval on unmount
    return () => {
      console.log('Cleaning up auto-refresh interval');
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [selectedFacility, selectedDriver]); // Add selectedDriver as a dependency
  
  // Filter drivers based on tab and search term
  useEffect(() => {
    if (activeChats.length === 0 && driversWithoutChats.length === 0) return;
    
    const combined = [...activeChats, ...driversWithoutChats];
    setFilteredDriversList(filterDriversList(combined, tabValue, searchTerm));
  }, [tabValue, searchTerm, activeChats, driversWithoutChats]);
  
  // Fetch messages when driver is selected
  useEffect(() => {
    if (!selectedDriver) {
      setMessages([]);
      return;
    }
    
    fetchMessages(selectedDriver, true);
    scrollToBottom();
  }, [selectedDriver]);
  
  // Scroll to bottom on new messages
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  
  // Helper functions
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  
  const filterDriversList = (
    driversList: (ActiveChat | DriverWithoutChat)[], 
    tab: number, 
    search: string
  ) => {
    let filtered = [...driversList];
    
    // Apply tab filter
    // 0: All, 1: Active Chats, 2: Available Drivers
    if (tab === 1) {
      filtered = filtered.filter(driver => 'last_message' in driver);
    } else if (tab === 2) {
      filtered = filtered.filter(driver => !('last_message' in driver));
    }
    
    // Apply search filter for the UI filtering (server filtering is already done for drivers without chats)
    if (search) {
      const searchLower = search.toLowerCase();
      filtered = filtered.filter(driver => {
        // Common properties
        const nameMatch = driver.driver_name.toLowerCase().includes(searchLower);
        const shipmentMatch = driver.shipment_number.toLowerCase().includes(searchLower);
        const truckMatch = driver.truck_number?.toLowerCase()?.includes(searchLower) || false;
        const trailerMatch = driver.trailer_number?.toLowerCase()?.includes(searchLower) || false;
        
        return nameMatch || shipmentMatch || truckMatch || trailerMatch;
      });
    }
    
    return filtered;
  };
  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  
  const handleDriverSelect = (driver: ActiveChat | DriverWithoutChat) => {
    setSelectedDriver(driver);
  };
  
  const handleSendMessage = async () => {
    if (!newMessage.trim() || !selectedDriver) return;
    
    try {
      // Add message to UI immediately for better UX
      const newMsg: Message = {
        id: `msg-new-${Date.now()}`,
        senderId: 'staff-1',
        senderName: user?.name || 'Facility Staff',
        senderRole: 'staff',
        content: newMessage,
        timestamp: new Date(),
        isRead: true
      };
      
      setMessages([...messages, newMsg]);
      setNewMessage('');
      
      // Send message to API
      await axios.post(
        `${WRITE_API_URL}/api/v1/admin/shipments/${selectedDriver.shipment_id}/chat`,
        { message: newMessage }
      );
      
      // Refresh active chats to show updated message count
      if (selectedFacility) {
        const activeChatsResponse = await axios.get(
          `${READ_API_URL}/api/v1/admin/active_chats?facility_id=${selectedFacility.id}`
        );
        setActiveChats(activeChatsResponse.data.active_chats);
        
        // If this was a driver without a chat before, they now have a chat
        // so we should refresh the drivers without chats list
        const driversWithoutChatsResponse = await axios.get(
          `${READ_API_URL}/api/v1/admin/drivers/without_chats?facility_id=${selectedFacility.id}${searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : ''}`
        );
        setDriversWithoutChats(driversWithoutChatsResponse.data.results);
      }
    } catch (err) {
      console.error('Failed to send message:', err);
      // Show error in the UI
      setMessages([...messages, {
        id: `msg-error-${Date.now()}`,
        senderId: 'system',
        senderName: 'System',
        senderRole: 'staff',
        content: 'Failed to send message. Please try again.',
        timestamp: new Date(),
        isRead: true
      }]);
    }
  };
  
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };
  
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  const getDriverStatus = (driver: ActiveChat | DriverWithoutChat): DriverStatus => {
    // All drivers that show up in either list are considered checked in
    return 'checkin';
  };
  
  const getStatusColor = (status: DriverStatus): string => {
    switch (status) {
      case 'pre-checkin':
        return 'bg-yellow-500';
      case 'checkin':
        return 'bg-green-500';
      case 'checkout':
        return 'bg-gray-500';
      default:
        return 'bg-blue-500';
    }
  };
  
  const getStatusIcon = (status: DriverStatus) => {
    switch (status) {
      case 'pre-checkin':
        return <AccessTime className="text-yellow-500" />;
      case 'checkin':
        return <CheckCircle className="text-green-500" />;
      case 'checkout':
        return <ExitToApp className="text-gray-500" />;
      default:
        return null;
    }
  };
  
  const canSendMessage = (driver: ActiveChat | DriverWithoutChat | null) => {
    if (!driver) return false;
    return getDriverStatus(driver) !== 'checkout';
  };
  
  // Add a manual refresh handler
  const handleManualRefresh = () => {
    console.log('Manual refresh triggered');
    
    // Show the refreshing indicator
    setIsRefreshing(true);
    
    // Refresh drivers and chats
    fetchDriversAndChats(false)
      .then(() => {
        // Refresh messages if a driver is selected
        if (selectedDriver) {
          return fetchMessages(selectedDriver, false);
        }
      })
      .finally(() => {
        // Ensure the refreshing indicator is hidden
        setTimeout(() => setIsRefreshing(false), 500);
      });
  };
  
  if (error) {
    return (
      <div className="p-5 flex flex-col items-center justify-center h-full">
        <ErrorIcon color="error" fontSize="large" />
        <Typography variant="h6" color="error" className="mt-2">
          {error}
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          className="mt-4"
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </div>
    );
  }
  
  return (
    <div className="p-5 h-full">
      <div className="shadow bg-white p-5 mb-5 rounded">
        <div className="flex flex-col md:flex-row gap-3 mb-3">
          <div>
            <h1 className="text-2xl font-bold mb-2">Driver Communications</h1>
            <p className="text-gray-600">Communicate with drivers before, during, and after facility visits</p>
          </div>
          <div className="flex gap-3 ml-auto items-center">
            <div className="flex items-center">
              {isRefreshing ? (
                <div className="flex items-center">
                  <CircularProgress size={16} className="mr-2" />
                  <Typography variant="caption" color="textSecondary">
                    Refreshing...
                  </Typography>
                </div>
              ) : (
                <IconButton 
                  size="small" 
                  onClick={handleManualRefresh} 
                  title="Refresh"
                  className="mr-2"
                >
                  <Refresh fontSize="small" />
                </IconButton>
              )}
            </div>
            <Select
              options={facilities.map(facility => ({
                value: facility,
                label: `(${facility.id}) ${facility.name}`
              }))}
              isSearchable={true}
              isClearable={false}
              value={selectedFacility ? {
                value: selectedFacility,
                label: `(${selectedFacility.id}) ${selectedFacility.name}`
              } : null}
              placeholder="Select Facility"
              onChange={(selectedOption: any) => {
                setSelectedFacility(selectedOption?.value);
              }}
              className="w-60"
            />
          </div>
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 h-[calc(100vh-220px)]">
        {/* Drivers List Panel */}
        <div className="md:col-span-1 bg-white shadow rounded overflow-hidden flex flex-col">
          <div className="p-3 border-b">
            <div className="mb-3">
              <TextField
                fullWidth
                placeholder="Search drivers, shipments..."
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="All" />
              <Tab label="Active Chats" />
              <Tab label="Available" />
            </Tabs>
          </div>
          
          <div className="overflow-y-auto flex-grow">
            {loading ? (
              <div className="flex justify-center items-center h-full">
                <CircularProgress />
              </div>
            ) : filteredDriversList.length === 0 ? (
              <div className="text-center p-5 text-gray-500">
                No drivers match your filters
              </div>
            ) : (
              <div>
                {filteredDriversList.map((driver, index) => {
                  const isActiveChat = 'last_message' in driver;
                  const driverStatus = getDriverStatus(driver);
                  const unreadCount = isActiveChat ? (driver as ActiveChat).message_count : 0;
                  
                  return (
                    <div 
                      key={`${driver.shipment_id}-${index}`}
                      className={`p-3 border-b cursor-pointer hover:bg-gray-50 ${
                        selectedDriver?.shipment_id === driver.shipment_id ? 'bg-blue-50' : ''
                      }`}
                      onClick={() => handleDriverSelect(driver)}
                    >
                      <div className="flex items-center">
                        <div className="mr-3 relative">
                          <Badge 
                            color="error" 
                            badgeContent={unreadCount}
                            invisible={unreadCount === 0}
                            overlap="circular"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                          >
                            <Avatar 
                              className={`${getStatusColor(driverStatus)} w-10 h-10`}
                            >
                              {getInitials(driver.driver_name)}
                            </Avatar>
                          </Badge>
                        </div>
                        <div className="flex-grow">
                          <div className="flex justify-between">
                            <span className="font-medium">{driver.driver_name}</span>
                            {isActiveChat && (
                              <span className="text-xs text-gray-500">
                                {formatTime(new Date((driver as ActiveChat).last_activity))}
                              </span>
                            )}
                          </div>
                          <div className="flex items-center text-sm text-gray-500">
                            {getStatusIcon(driverStatus)}
                            <span className="ml-1 capitalize">{driverStatus}</span>
                          </div>
                          <div className="text-xs mt-1">
                            <span className="text-gray-500">Shipment: </span>
                            <span className="font-medium">{driver.shipment_number}</span>
                          </div>
                          {driver.truck_number && (
                            <div className="text-xs mt-1">
                              <span className="text-gray-500">Truck: </span>
                              <span className="font-medium">{driver.truck_number}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        
        {/* Chat Panel */}
        <div className="md:col-span-2 lg:col-span-3 bg-white shadow rounded overflow-hidden flex flex-col">
          {selectedDriver ? (
            <>
              {/* Chat Header */}
              <div className="p-3 border-b flex justify-between items-center">
                <div className="flex items-center">
                  <Avatar 
                    className={`${getStatusColor(getDriverStatus(selectedDriver))} mr-3`}
                  >
                    {getInitials(selectedDriver.driver_name)}
                  </Avatar>
                  <div>
                    <div className="font-medium">{selectedDriver.driver_name}</div>
                    <div className="flex items-center text-sm text-gray-500 flex-wrap gap-2">
                      {getStatusIcon(getDriverStatus(selectedDriver))}
                      <span className="ml-1 capitalize">{getDriverStatus(selectedDriver)}</span>
                      {selectedDriver.truck_number && (
                        <Chip 
                          size="small" 
                          label={`Truck: ${selectedDriver.truck_number}`} 
                          className="ml-2"
                        />
                      )}
                      {selectedDriver.trailer_number && (
                        <Chip 
                          size="small" 
                          label={`Trailer: ${selectedDriver.trailer_number}`} 
                          className="ml-2"
                        />
                      )}
                      <Chip 
                        size="small" 
                        label={`Shipment: ${selectedDriver.shipment_number}`} 
                        className="ml-2"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <IconButton size="small" className="mr-1" title={`Call ${selectedDriver.driver_phone}`}>
                    <Phone />
                  </IconButton>
                  <IconButton size="small" onClick={handleMenuOpen}>
                    <MoreVert />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleMenuClose}>View Driver Details</MenuItem>
                    <MenuItem onClick={handleMenuClose}>View Shipment</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Send Pre-written Message</MenuItem>
                  </Menu>
                </div>
              </div>
              
              {/* Chat Messages */}
              <div className="flex-grow overflow-y-auto p-3 bg-gray-50">
                {loadingMessages ? (
                  <div className="flex justify-center items-center h-full">
                    <CircularProgress size={24} />
                    <Typography variant="body2" className="ml-2">
                      Loading messages...
                    </Typography>
                  </div>
                ) : (
                  <div className="space-y-3">
                    {messages.map(message => (
                      <div
                        key={message.id}
                        className={`flex ${
                          message.senderRole === 'staff' ? 'justify-end' : 'justify-start'
                        }`}
                      >
                        <div
                          className={`max-w-[70%] rounded-lg p-3 shadow-sm ${
                            message.senderRole === 'staff'
                              ? 'bg-blue-500 text-white'
                              : 'bg-white'
                          }`}
                        >
                          <div className="flex justify-between items-start mb-1">
                            <span className={`font-medium ${
                              message.senderRole === 'staff' ? 'text-blue-100' : 'text-gray-700'
                            }`}>
                              {message.senderName}
                            </span>
                            <span className={`text-xs ml-3 ${
                              message.senderRole === 'staff' ? 'text-blue-100' : 'text-gray-500'
                            }`}>
                              {formatDate(message.timestamp)}
                            </span>
                          </div>
                          <p className="whitespace-pre-wrap">{message.content}</p>
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>
                )}
              </div>
              
              {/* Chat Input */}
              <div className="p-3 border-t">
                {canSendMessage(selectedDriver) ? (
                  <div className="flex items-end">
                    <TextField
                      fullWidth
                      placeholder="Type a message..."
                      variant="outlined"
                      multiline
                      maxRows={4}
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyDown={handleKeyPress}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton size="small">
                              <AttachFile />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className="ml-2"
                      disabled={!newMessage.trim() || loadingMessages}
                      onClick={handleSendMessage}
                      sx={{ minWidth: '40px', height: '56px' }}
                    >
                      <Send />
                    </Button>
                  </div>
                ) : (
                  <Paper className="p-3 bg-gray-100">
                    <Typography align="center" color="textSecondary">
                      This driver has checked out and can no longer be contacted.
                    </Typography>
                  </Paper>
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-col justify-center items-center h-full p-5 text-center text-gray-500">
              <div className="text-xl mb-3">Select a driver to start chatting</div>
              <div className="text-sm">
                Send messages to drivers at any stage of their visit:
                <ul className="mt-2">
                  <li className="flex items-center justify-center">
                    <AccessTime className="text-yellow-500 mr-1" /> Available: Start a new conversation
                  </li>
                  <li className="flex items-center justify-center mt-1">
                    <CheckCircle className="text-green-500 mr-1" /> Active Chats: Continue existing conversations
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DriverComms; 