import axios from 'axios';
import { useState, useEffect, useRef, useCallback } from 'react';
import React from 'react';

// Types for yard spots and activities
export type ScheduledTime = {
  startTime: Date;
  endTime: Date;
  driverId: string | null;
  driverName: string | null;
  trailerId: string | null;
  trailerNumber: string | null;
  shipmentNumber: string | null;
};

export type Status = 'available' | 'in-use' | 'inactive';

export type Zone = {
  id: string;
  name: string;
  color: string;
};

export type YardSpot = {
  id: string;
  number: number;
  displayNumber: string;
  zoneId: string;
  status: Status;
  driverId: string | null;
  driverName: string | null;
  trailerId: string | null;
  trailerNumber: string | null;
  trailerType?: 'dry_van' | 'reefer' | 'flatbed' | 'tanker' | 'container';
  shipmentNumber: string | null;
  bol_id?: string | null;
  carrier_name?: string | null;
  scheduledTimes?: ScheduledTime[];
};

export type HistoryEntry = {
  id: string;
  timestamp: Date;
  driverName: string;
  activity: 'loading' | 'unloading' | 'parked' | 'maintenance';
  duration: number;
  details?: string;
};

export type YardHistory = {
  [spotId: string]: HistoryEntry[];
};

// API response type
interface ApiYardSlot {
  id: string;
  number: string;
  status: string;
  zone_id: string;
  zone_name: string;
  zone_color: string;
  assigned_driver_id?: string | null;
  assigned_driver_name?: string | null;
  assigned_trailer_id?: string | null;
  assigned_trailer_number?: string | null;
  assigned_trailer_type?: string | null;
  yard_number?: string;
  yard_spot?: string;
  driver_id?: number;
  driver_name?: string | null;
  driver_phone_number?: string;
  truck_number?: string;
  trailer_number?: string;
  puid?: string;
  check_in_time?: string;
  updated_at?: string;
  active_truck_id?: number;
  bol_id?: number;
  scheduled_times?: {
    start_time: string;
    end_time: string;
    driver_id: string | null;
    driver_name: string | null;
    trailer_id: string | null;
    trailer_number: string | null;
    shipment_number: string | null;
  }[];
  history?: {
    id: string;
    timestamp: string;
    driver_name: string;
    activity: string;
    duration: number;
    details?: string;
  }[];
  carrier_name?: string | null;
  yard_data?: any;
}

// Convert API data to our YardSpot format
const mapApiYardSlotToYardSpot = (slot: ApiYardSlot): YardSpot => {
  // Extract yard number from either yard_number or yard_spot (e.g., "Y1" from "YARD:Y1")
  const displayYardNumber = slot.yard_number || 
                         (slot.yard_spot && slot.yard_spot.includes(':') ? 
                          slot.yard_spot.split(':')[1] : slot.number);
  
  // Extract just the numeric part for internal matching
  const numericPart = parseInt(displayYardNumber.replace(/\D/g, '')) || 0;
  
  // Check if this yard spot has an assigned driver
  const hasAssignedDriver = !!(slot.driver_id || slot.assigned_driver_id);
  
  // Extract carrier_name from various possible locations
  let carrier_name = slot.carrier_name;
  if (!carrier_name && slot.yard_data) {
    if (typeof slot.yard_data === 'object' && slot.yard_data.carrier_name) {
      carrier_name = slot.yard_data.carrier_name;
    } else if (typeof slot.yard_data === 'string') {
      try {
        const parsedYardData = JSON.parse(slot.yard_data);
        if (parsedYardData.carrier_name) {
          carrier_name = parsedYardData.carrier_name;
        }
      } catch (e) {
        // Not valid JSON, ignore
      }
    }
  }
  
  console.log(`Mapping yard spot: ${displayYardNumber}, numeric part: ${numericPart}`);
  
  return {
    id: slot.id || `api-yard-${numericPart}`,
    number: numericPart,
    displayNumber: displayYardNumber,
    zoneId: slot.zone_id || '', // Will be preserved from base spot if empty
    status: hasAssignedDriver ? 'in-use' as Status : (slot.status as Status || 'available'),
    driverId: slot.driver_id?.toString() || slot.assigned_driver_id || null,
    driverName: slot.driver_name || slot.assigned_driver_name || null,
    trailerId: slot.assigned_trailer_id || null,
    trailerNumber: slot.trailer_number || slot.assigned_trailer_number || null,
    trailerType: slot.assigned_trailer_type as 'dry_van' | 'reefer' | 'flatbed' | 'tanker' | 'container' || undefined,
    shipmentNumber: slot.puid || null,
    bol_id: slot.bol_id?.toString() || null,
    carrier_name,
    scheduledTimes: (slot.scheduled_times || []).map(time => ({
      startTime: new Date(time.start_time),
      endTime: new Date(time.end_time),
      driverId: time.driver_id,
      driverName: time.driver_name,
      trailerId: time.trailer_id,
      trailerNumber: time.trailer_number,
      shipmentNumber: time.shipment_number
    }))
  };
};

// Convert API history data to our HistoryEntry format
const mapApiHistoryToHistoryEntry = (entry: any): HistoryEntry => {
  return {
    id: entry.id,
    timestamp: new Date(entry.timestamp),
    driverName: entry.driver_name,
    activity: entry.activity as 'loading' | 'unloading' | 'parked' | 'maintenance',
    duration: entry.duration,
    details: entry.details
  };
};

// Custom hook to fetch yard data
export const useYardData = (facilityId?: number, refreshInterval: number = 30000) => {
  // Loading and error states
  const [yardSpotsLoading, setYardSpotsLoading] = useState(false);
  const [facilitiesLoading, setFacilitiesLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Core state for data
  const [yardSpots, setYardSpots] = useState<YardSpot[]>([]);
  const [yardHistory, setYardHistory] = useState<YardHistory>({});
  const [facilities, setFacilities] = useState<{ id: number; name: string }[]>([]);
  const [selectedFacility, setSelectedFacility] = useState<{ id: number; name: string } | null>(null);
  const [timeInterval, setTimeInterval] = useState('24');
  const [zones, setZones] = useState<Zone[]>([
    { id: 'zone-1', name: 'Zone A', color: '#4CAF50' },
    { id: 'zone-2', name: 'Zone B', color: '#2196F3' }
  ]);

  // Use refs for tracking fetching state without causing renders
  const isFetchingRef = useRef(false);
  const lastFetchTimeRef = useRef(0);
  const abortControllerRef = useRef<AbortController | null>(null);

  // Initialize a base set of yard spots only once
  useEffect(() => {
    if (yardSpots.length === 0) {
      const createBaseYardSpots = (): YardSpot[] => {
        return Array.from({ length: 30 }, (_, i) => {
          const spotNum = i + 1;
          const zoneIndex = Math.floor(i / 15) % 2;
          const zoneId = zones[zoneIndex].id;
          
          return {
            id: `yard-${spotNum}`,
            number: spotNum,
            displayNumber: `Y${spotNum}`,
            zoneId,
            status: 'available' as Status,
            driverId: null,
            driverName: null,
            trailerId: null,
            trailerNumber: null,
            trailerType: undefined,
            shipmentNumber: null,
            scheduledTimes: []
          };
        });
      };

      setYardSpots(createBaseYardSpots());
    }
  }, []);

  // IMPROVED: Fetch facilities function with better reference handling
  const fetchFacilities = useCallback(async () => {
    if (facilities.length === 0) {
      setFacilitiesLoading(true);
    }
    
    try {
      const READ_API_URL = process.env.REACT_APP_READ_API_URL;
      const response = await axios.get(`${READ_API_URL}/api/v1/admin/facilities`);
      
      // Only update if different to prevent unnecessary renders
      if (JSON.stringify(facilities) !== JSON.stringify(response.data)) {
        setFacilities(response.data);
      }
      
      // Handle facility selection
      if (facilityId) {
        const facility = response.data.find((f: any) => f.id === facilityId);
        if (facility && (!selectedFacility || selectedFacility.id !== facility.id)) {
          setSelectedFacility(facility);
        }
      } else if (response.data.length > 0 && !selectedFacility) {
        setSelectedFacility(response.data[0]);
      }
      
      setError(null);
    } catch (err) {
      setError('Failed to fetch facilities');
      console.error(err);
    } finally {
      setFacilitiesLoading(false);
    }
  }, [facilityId, facilities, selectedFacility]);

  // Fetch facilities once on mount
  useEffect(() => {
    fetchFacilities();
  }, [fetchFacilities]);

  // IMPROVED: Yard data fetching with better data handling
  const fetchYardData = useCallback(async (force: boolean = false) => {
    if (!selectedFacility) return;
    if (isFetchingRef.current && !force) return;
    
    const now = Date.now();
    if (!force && now - lastFetchTimeRef.current < 10000) {
      return;
    }
    
    // Cancel any existing request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    
    isFetchingRef.current = true;
    setYardSpotsLoading(previous => {
      // Only show loading on first load, not on refreshes
      return previous ? false : true;
    });
    
    try {
      const READ_API_URL = process.env.REACT_APP_READ_API_URL;
      const response = await axios.get(
        `${READ_API_URL}/api/v1/admin/drivers/assigned_yard_slots`, 
        {
          params: {
            facility_id: selectedFacility.id,
            da_interval: timeInterval
          },
          signal: abortControllerRef.current.signal,
          timeout: 15000
        }
      );
      
      lastFetchTimeRef.current = now;
      
      // Skip empty responses if we already have data
      if (response.data.length === 0 && yardSpots.length > 0) {
        setYardSpotsLoading(false);
        isFetchingRef.current = false;
        return;
      }
      
      // Process zones
      if (response.data.length > 0) {
        const uniqueZones = new Map<string, Zone>();
        zones.forEach(zone => uniqueZones.set(zone.id, zone));
        
        response.data.forEach((slot: ApiYardSlot) => {
          if (slot.zone_id && !uniqueZones.has(slot.zone_id)) {
            uniqueZones.set(slot.zone_id, {
              id: slot.zone_id,
              name: slot.zone_name || `Zone ${slot.zone_id}`,
              color: slot.zone_color || '#' + Math.floor(Math.random()*16777215).toString(16)
            });
          }
        });
        
        const newZones = Array.from(uniqueZones.values());
        if (JSON.stringify(newZones) !== JSON.stringify(zones)) {
          setZones(newZones);
        }
        
        // Update yard spots with functional update to avoid stale closures
        setYardSpots(current => {
          const updatedSpots: YardSpot[] = [...current];
          const assignedSpots = response.data.map(mapApiYardSlotToYardSpot);
          let hasChanges = false;
          
          assignedSpots.forEach((assignedSpot: YardSpot) => {
            const existingIndex = updatedSpots.findIndex(
              s => s.displayNumber === assignedSpot.displayNumber
            );
            
            if (existingIndex >= 0) {
              // Update existing spot, preserving zone if needed
              const merged = {
                ...updatedSpots[existingIndex],
                ...assignedSpot,
                zoneId: assignedSpot.zoneId || updatedSpots[existingIndex].zoneId
              };
              
              // Only update if there are actual changes
              if (JSON.stringify(merged) !== JSON.stringify(updatedSpots[existingIndex])) {
                updatedSpots[existingIndex] = merged;
                hasChanges = true;
              }
            } else {
              // Add new spot
              updatedSpots.push({
                ...assignedSpot,
                zoneId: assignedSpot.zoneId || newZones[0]?.id || 'zone-1'
              });
              hasChanges = true;
            }
          });
          
          return hasChanges ? updatedSpots : current;
        });
        
        // Update history with functional update
        setYardHistory(current => {
          const updatedHistory = { ...current };
          let hasChanges = false;
          
          response.data.forEach((slot: ApiYardSlot) => {
            if (slot.history) {
              const newEntries = slot.history.map(mapApiHistoryToHistoryEntry);
              
              if (JSON.stringify(newEntries) !== JSON.stringify(updatedHistory[slot.id] || [])) {
                updatedHistory[slot.id] = newEntries;
                hasChanges = true;
              }
            }
          });
          
          return hasChanges ? updatedHistory : current;
        });
      }
      
      setError(null);
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('Request cancelled');
        return;
      }
      setError('Failed to fetch yard data');
      console.error(err);
    } finally {
      setYardSpotsLoading(false);
      isFetchingRef.current = false;
      abortControllerRef.current = null;
    }
  }, [selectedFacility, timeInterval, zones, yardSpots.length]);

  // Create a memoized refresh function
  const refreshData = useCallback(() => {
    return fetchYardData(true);
  }, [fetchYardData]);

  // Setup polling interval
  useEffect(() => {
    if (!selectedFacility) return;
    
    // Do initial fetch
    fetchYardData();
    
    let intervalId: NodeJS.Timeout | null = null;
    if (refreshInterval > 0) {
      intervalId = setInterval(() => fetchYardData(), refreshInterval);
    }
    
    return () => {
      if (intervalId) clearInterval(intervalId);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [fetchYardData, refreshInterval, selectedFacility]);

  // Create a combined loading state for external consumers
  const loading = yardSpotsLoading || facilitiesLoading;

  // Explicitly return the specific properties needed by consumers
  // This helps React's reconciliation process
  return {
    loading,
    yardSpotsLoading,
    facilitiesLoading,
    yardSpots,
    yardHistory,
    error,
    facilities,
    selectedFacility,
    setSelectedFacility,
    timeInterval,
    setTimeInterval,
    zones,
    refreshData
  };
}; 