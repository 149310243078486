import React, { useState, useEffect, useCallback, useMemo, useRef, useLayoutEffect } from 'react';
import { 
  CircularProgress, 
  Modal, 
  Tooltip, 
  Paper, 
  Tab, 
  Tabs, 
  Box, 
  ToggleButton, 
  ToggleButtonGroup, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  IconButton, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Avatar, 
  Typography,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  TextField,
  Autocomplete
} from '@mui/material';
import { 
  Close, 
  Edit, 
  Check, 
  ArrowForward, 
  History, 
  SwapHoriz, 
  FilterList, 
  Add, 
  Delete,
  LocalParking,
  Refresh
} from '@mui/icons-material';
import Select from 'react-select';
import { twMerge } from 'tailwind-merge';
import { useYardData, YardSpot, Status, Zone, HistoryEntry, YardHistory } from './yardDataFetch';
import axios from 'axios';

// Local type definitions
interface StagingTrailer {
  puid: string;
  driver_id: string;
  driver_name: string;
  driver_phone_number: string;
  truck_number: string;
  trailer_number: string;
  check_in_time: string;
  bol_id: string;
  direction: string;
  carrier_name?: string;
}

// Add interface for the payload
interface AssignYardSpotPayload {
  bol_id: string;
  yard_spot: string;
  carrier_name?: string;
  [key: string]: any; // Allow additional properties
}

// Add interface for assigned yard slots response
interface AssignedYardSlot {
  bol_id: string;
  carrier_name?: string;
  driver_id: string;
  driver_name: string;
  driver_phone_number: string;
  yard_spot: string;
  trailer_number: string;
  puid: string;
  check_in_time: string;
  [key: string]: any; // For other properties
}

// Update the imported YardSpot interface with our own that includes carrier_name
// We need to do this since we don't want to modify the import file directly
interface LocalYardSpot extends YardSpot {
  // carrier_name is now included in the YardSpot interface
}

// Types
type ScheduledTime = {
  startTime: Date;
  endTime: Date;
  driverId: string | null;
  driverName: string | null;
  trailerId: string | null;
  trailerNumber: string | null;
  shipmentNumber: string | null;
};


// Helper functions
const formatTime = (date: Date) => {
  return date.toLocaleTimeString([], { 
    hour: '2-digit', 
    minute: '2-digit' 
  });
};

const formatDate = (date: Date) => {
  return date.toLocaleDateString() + ' ' + formatTime(date);
};

const formatForDateTimeInput = (date: Date) => {
  return date.toISOString().slice(0, 16);
};

const formatDuration = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  if (hours > 0) {
    return `${hours}h ${mins > 0 ? mins + 'm' : ''}`;
  }
  return `${mins}m`;
};

const getRandomColor = () => {
  const colors = [
    '#4CAF50', '#2196F3', '#FF9800', '#E91E63', 
    '#9C27B0', '#00BCD4', '#FFEB3B', '#795548'
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

// Component
const YardSpotManagement = React.memo(function YardSpotManagement() {
  // *** PERFORMANCE OPTIMIZATION ***
  // This component implements several optimizations to prevent unnecessary re-renders:
  // 1. Data fetching is debounced to prevent excessive API calls
  // 2. State updates only occur when data actually changes (using JSON string comparison)
  // 3. Manual refresh allows users to explicitly update data when needed
  // 4. The useYardData hook implements memoization and selective updates
  // 5. Callback functions are memoized to prevent recreation on each render
  // 6. Yard spot assignments and removals update state directly without reloading all data
  // These optimizations significantly improve performance and user experience.
  
  // Local UI state declaration first
  const [filterZone, setFilterZone] = useState<string | null>(null);
  const [filterStatus, setFilterStatus] = useState<Status | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [tabValue, setTabValue] = useState<number>(0);
  const [showSpotDetailsModal, setShowSpotDetailsModal] = useState<boolean>(false);
  const [showSpotEditModal, setShowSpotEditModal] = useState<boolean>(false);
  const [showSpotHistoryModal, setShowSpotHistoryModal] = useState<boolean>(false);
  const [showZoneManageModal, setShowZoneManageModal] = useState<boolean>(false);
  const [showZoneEditModal, setShowZoneEditModal] = useState<boolean>(false);
  const [timeRange, setTimeRange] = useState<string>('week');
  const [newZoneName, setNewZoneName] = useState<string>('');
  const [newZoneColor, setNewZoneColor] = useState<string>(getRandomColor());
  const [isEndingAssignment, setIsEndingAssignment] = useState<boolean>(false);
  const [endAssignmentError, setEndAssignmentError] = useState<string | null>(null);
  const [selectedSpot, setSelectedSpot] = useState<LocalYardSpot | null>(null);
  const [editingSpot, setEditingSpot] = useState<LocalYardSpot | null>(null);
  const [editingZone, setEditingZone] = useState<Zone | null>(null);
  
  // Create refs for main content areas to maintain their dimensions
  const stagingAreaRef = useRef<HTMLDivElement>(null);
  const spotsContainerRef = useRef<HTMLDivElement>(null);
  const [stagingHeight, setStagingHeight] = useState<number | null>(null);
  const [spotsHeight, setSpotsHeight] = useState<number | null>(null);
  
  // Use a more robust scroll restoration approach
  const [isRestoringScroll, setIsRestoringScroll] = useState(false);
  const scrollY = useRef(0);
  
  // Fix the useYardData call with proper property names
  const {
    loading: apiLoading,
    yardSpots: apiYardSpots,
    yardHistory: apiYardHistory,
    error,
    facilities,
    selectedFacility,
    setSelectedFacility,
    timeInterval,
    setTimeInterval,
    zones,
    refreshData
  } = useYardData(undefined, 60000); // Use 60 second refresh interval

  // Add state for local modifications to yardSpots, with extended type to include carrier_name
  const [localModifications, setLocalModifications] = useState<{[spotId: string]: Partial<YardSpot> & {carrier_name?: string | null}}>({});

  // Always use max time interval
  useEffect(() => {
    setTimeInterval('8760'); // Always set to max (1 year)
  }, [setTimeInterval]);
  
  // Use memoized derived data from API data to prevent unnecessary renders
  const localYardSpots = useMemo(() => {
    // If the API returns empty array, return empty array
    if (apiYardSpots.length === 0) return [];
    
    // Apply local modifications on top of API data
    return apiYardSpots.map(spot => {
      const modifications = localModifications[spot.id];
      
      // Apply modifications if they exist, otherwise just use the original spot
      return { 
        ...spot, 
        ...(modifications || {})
      } as LocalYardSpot;
    });
  }, [apiYardSpots, localModifications]);
  
  // Use derivation functions instead of separate state
  const filteredYardSpots = useMemo(() => {
    if (!localYardSpots) return [];
    return localYardSpots.filter(spot => {
      if (filterZone && spot.zoneId !== filterZone) return false;
      if (filterStatus && spot.status !== filterStatus) return false;
      if (searchTerm) {
        const term = searchTerm.toLowerCase();
        return (
          spot.displayNumber.toLowerCase().includes(term) ||
          spot.driverName?.toLowerCase().includes(term) ||
          spot.trailerNumber?.toLowerCase().includes(term) ||
          spot.shipmentNumber?.toLowerCase().includes(term)
        );
      }
      return true;
    });
  }, [localYardSpots, filterZone, filterStatus, searchTerm]);
  
  // Group spots by zone - memoized to prevent recalculation
  const spotsByZone = useMemo(() => {
    if (!localYardSpots) return [];
    return zones.map(zone => ({
      zone,
      spots: localYardSpots.filter(spot => spot.zoneId === zone.id)
        .filter(spot => {
          if (filterStatus && spot.status !== filterStatus) return false;
          if (searchTerm) {
            const term = searchTerm.toLowerCase();
            return (
              spot.displayNumber.toLowerCase().includes(term) ||
              spot.driverName?.toLowerCase().includes(term) ||
              spot.trailerNumber?.toLowerCase().includes(term) ||
              spot.shipmentNumber?.toLowerCase().includes(term)
            );
          }
          return true;
        })
        .sort((a, b) => a.number - b.number)
    })).filter(group => group.spots.length > 0);
  }, [localYardSpots, zones, filterStatus, searchTerm]);

  // Add state for staging trailers
  const [stagingTrailers, setStagingTrailers] = useState<StagingTrailer[]>([]);
  const [loadingStagingTrailers, setLoadingStagingTrailers] = useState<boolean>(false);
  const [stagingTrailersError, setStagingTrailersError] = useState<string | null>(null);
  
  // Add state for moving trailer from staging to yard spot
  const [showMoveToYardSpotModal, setShowMoveToYardSpotModal] = useState<boolean>(false);
  const [selectedStagingTrailer, setSelectedStagingTrailer] = useState<StagingTrailer | null>(null);
  const [selectedYardSpotForMove, setSelectedYardSpotForMove] = useState<string>('');
  const [assigningYardSpot, setAssigningYardSpot] = useState<boolean>(false);
  const [assignYardSpotError, setAssignYardSpotError] = useState<string | null>(null);
  
  // First, add state for the dock door assignment modal
  const [showMoveToDockModal, setShowMoveToDockModal] = useState<boolean>(false);
  const [selectedDockDoor, setSelectedDockDoor] = useState<string>('');
  const [selectedShipmentPuid, setSelectedShipmentPuid] = useState<string>('');
  const [movingToDock, setMovingToDock] = useState<boolean>(false);
  const [moveToDockError, setMoveToDockError] = useState<string | null>(null);
  
  // Add state for active dock doors
  const [activeDockDoors, setActiveDockDoors] = useState<string[]>([]);
  const [loadingDockDoors, setLoadingDockDoors] = useState<boolean>(false);
  
  // Save dimensions and scroll position
  useLayoutEffect(() => {
    // Save initial dimensions of containers
    if (stagingAreaRef.current && !stagingHeight) {
      setStagingHeight(stagingAreaRef.current.offsetHeight);
    }
    if (spotsContainerRef.current && !spotsHeight) {
      setSpotsHeight(spotsContainerRef.current.offsetHeight);
    }
    
    // Save scroll position continually
    const handleScroll = () => {
      if (!isRestoringScroll) {
        scrollY.current = window.scrollY;
      }
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [stagingHeight, spotsHeight, isRestoringScroll]);
  
  // Restore scroll position after state updates
  useLayoutEffect(() => {
    if (scrollY.current > 0 && !isRestoringScroll) {
      setIsRestoringScroll(true);
      requestAnimationFrame(() => {
        window.scrollTo(0, scrollY.current);
        // Wait a bit more for any layout shifts to settle
        setTimeout(() => setIsRestoringScroll(false), 50);
      });
    }
  }, [localYardSpots, stagingTrailers]);

  // Add state for scheduled time form
  const [newScheduledTime, setNewScheduledTime] = useState<{
    startTime: string;
    endTime: string;
    driverId: string | null;
    driverName: string | null;  // Added driver name field
    trailerId: string | null;
    trailerNumber: string | null;  // Added trailer number field
    shipmentNumber: string | null;
  }>({
    startTime: formatForDateTimeInput(new Date()),
    endTime: formatForDateTimeInput(new Date(Date.now() + 2 * 60 * 60 * 1000)), // 2 hours later
    driverId: null,
    driverName: null,
    trailerId: null,
    trailerNumber: null,
    shipmentNumber: null
  });
  
  // Add state for showing add scheduled time modal
  const [showAddScheduleModal, setShowAddScheduleModal] = useState<boolean>(false);
  
  // Handler functions
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  
  const handleShowSpotDetails = (spot: LocalYardSpot) => {
    // Look up the latest state of this spot, combining API data with local modifications
    const latestSpot = localYardSpots.find(s => s.id === spot.id);
    
    if (latestSpot) {
      console.log('Selected spot details for UI display:', {
        id: latestSpot.id,
        displayNumber: latestSpot.displayNumber,
        status: latestSpot.status,
        carrier_name: latestSpot.carrier_name
      });
      setSelectedSpot(latestSpot);
    } else {
      setSelectedSpot(spot);
    }
    
    setShowSpotDetailsModal(true);
  };
  
  const handleCloseDetailsModal = () => {
    setShowSpotDetailsModal(false);
    setSelectedSpot(null);
  };
  
  const handleShowSpotEdit = (spot: LocalYardSpot) => {
    setEditingSpot({ ...spot });
    setShowSpotEditModal(true);
  };
  
  const handleSpotEditClose = () => {
    setShowSpotEditModal(false);
    setEditingSpot(null);
  };
  
  const handleShowSpotHistory = (spot: LocalYardSpot) => {
    setSelectedSpot(spot);
    setShowSpotHistoryModal(true);
  };
  
  const handleSpotHistoryClose = () => {
    setShowSpotHistoryModal(false);
    setSelectedSpot(null);
  };
  
  const handleShowZoneManage = () => {
    setShowZoneManageModal(true);
  };
  
  const handleZoneManageClose = () => {
    setShowZoneManageModal(false);
  };
  
  const handleZoneEditClose = () => {
    setShowZoneEditModal(false);
    setEditingZone(null);
  };
  
  const handleShowZoneEdit = (zone: Zone) => {
    setEditingZone({ ...zone });
    setShowZoneEditModal(true);
  };
  
  const handleAddNewZone = () => {
    if (!newZoneName.trim()) return;
    
    const newZone: Zone = {
      id: `zone-${Date.now()}`, // Generate temp ID
      name: newZoneName,
      color: newZoneColor
    };
    
    // TODO: Implement API call to add zone
    console.log('Add new zone:', newZone);
    
    // Reset form
    setNewZoneName('');
    setNewZoneColor(getRandomColor());
  };
  
  const handleSaveZone = () => {
    if (!editingZone) return;
    
    // TODO: Implement API call to save zone
    console.log('Save zone:', editingZone);
    setShowZoneEditModal(false);
  };
  
  const handleDeleteZone = (zoneId: string) => {
    // TODO: Implement API call to delete zone
    console.log('Delete zone:', zoneId);
  };
  
  // Use useMemo for activeSpots
  const activeSpots = useMemo(() => {
    return localYardSpots.filter(spot => spot.status === 'in-use' && spot.driverId);
  }, [localYardSpots]);
  
  // Use useMemo for upcomingSchedules
  const upcomingSchedules = useMemo(() => {
    return localYardSpots
      .flatMap(spot => 
        (spot.scheduledTimes || []).map(time => ({
          ...time,
          spotId: spot.id,
          spotNumber: spot.displayNumber,
          zoneId: spot.zoneId
        }))
      )
      .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
  }, [localYardSpots]);

  // Update the handleStatusChange function to use localModifications
  const handleStatusChange = (spotId: string, newStatus: Status) => {
    // Update local modifications
    setLocalModifications(prev => ({
      ...prev,
      [spotId]: { 
        ...prev[spotId],
        status: newStatus 
      }
    }));
    
    // Update the selectedSpot if it's currently selected
    if (selectedSpot && selectedSpot.id === spotId) {
      setSelectedSpot({ ...selectedSpot, status: newStatus });
    }
    
    console.log(`Changed spot ${spotId} status to ${newStatus}`);
    // In a real app, you would call an API endpoint here
    // apiService.updateSpotStatus(spotId, newStatus);
  };
  
  // Update the handleZoneChange function to use localModifications
  const handleZoneChange = (spotId: string, newZoneId: string) => {
    // Update local modifications
    setLocalModifications(prev => ({
      ...prev,
      [spotId]: { 
        ...prev[spotId],
        zoneId: newZoneId 
      }
    }));
    
    // Update the selectedSpot if it's currently selected
    if (selectedSpot && selectedSpot.id === spotId) {
      setSelectedSpot({ ...selectedSpot, zoneId: newZoneId });
    }
    
    console.log(`Changed spot ${spotId} zone to ${newZoneId}`);
    // In a real app, you would call an API endpoint here
    // apiService.updateSpotZone(spotId, newZoneId);
  };
  
  // Update the handleEndAssignment function to use localModifications
  const handleEndAssignment = async (spotId: string) => {
    const spot = localYardSpots.find(s => s.id === spotId);
    // Check for bol_id in the spot, it might be nested differently depending on your data structure
    const bolId = spot?.bol_id || null;
    
    if (!spot || !bolId) {
      // If no bol_id is available, just update local state
      updateSpotToAvailable(spotId);
      return;
    }
    
    setIsEndingAssignment(true);
    setEndAssignmentError(null);
    
    try {
      const READ_API_URL = process.env.REACT_APP_READ_API_URL;
      
      await axios.post(
        `${READ_API_URL}/api/v1/admin/drivers/delete_yard_spot`,
        {
          bol_id: bolId
        }
      );
      
      // Update local state after successful API call
      updateSpotToAvailable(spotId);
      
      // Close modal if it's open
      if (showSpotDetailsModal) {
        handleCloseDetailsModal();
      }
    } catch (err) {
      console.error('Error ending yard spot assignment:', err);
      setEndAssignmentError('Failed to end yard spot assignment. Please try again.');
    } finally {
      setIsEndingAssignment(false);
    }
  };
  
  // Helper function to update spot to available
  const updateSpotToAvailable = (spotId: string) => {
    console.log(`Clearing yard spot ${spotId}, removing carrier_name and other data`);
    
    // Update local modifications
    setLocalModifications(prev => ({
      ...prev,
      [spotId]: { 
        status: 'available' as Status, 
        driverId: null, 
        driverName: null, 
        trailerId: null, 
        trailerNumber: null, 
        shipmentNumber: null,
        carrier_name: null, // Explicitly clear carrier_name
        bol_id: null // Clear bol_id when spot is available
      }
    }));
    
    // Update the selectedSpot if it's currently selected
    if (selectedSpot && selectedSpot.id === spotId) {
      setSelectedSpot({
        ...selectedSpot,
        status: 'available' as Status, 
        driverId: null, 
        driverName: null, 
        trailerId: null, 
        trailerNumber: null, 
        shipmentNumber: null,
        carrier_name: null, // Explicitly clear carrier_name
        bol_id: null // Clear bol_id when spot is available
      });
    }
    
    console.log(`Ended assignment for spot ${spotId}`);
  };
  
  const handleAddScheduleOpen = () => {
    setShowAddScheduleModal(true);
  };
  
  const handleAddScheduleClose = () => {
    setShowAddScheduleModal(false);
    
    // Reset form
    setNewScheduledTime({
      startTime: formatForDateTimeInput(new Date()),
      endTime: formatForDateTimeInput(new Date(Date.now() + 2 * 60 * 60 * 1000)),
      driverId: null,
      driverName: null,
      trailerId: null,
      trailerNumber: null,
      shipmentNumber: null
    });
  };
  
  // Handle adding a scheduled time using API data
  const handleAddScheduleSubmit = () => {
    if (!selectedSpot) return;
    
    // Validate inputs
    if (!newScheduledTime.startTime || !newScheduledTime.endTime) {
      // In a real app, you would show an error message
      console.error('Start and end times are required');
      return;
    }
    
    // Create the new scheduled time using the values directly from the form
    const newSchedule: ScheduledTime = {
      startTime: new Date(newScheduledTime.startTime),
      endTime: new Date(newScheduledTime.endTime),
      driverId: newScheduledTime.driverId,
      driverName: newScheduledTime.driverName,
      trailerId: newScheduledTime.trailerId,
      trailerNumber: newScheduledTime.trailerNumber,
      shipmentNumber: newScheduledTime.shipmentNumber
    };
    
    // Create updated schedule times for the spot
    const updatedScheduleTimes = [...(selectedSpot.scheduledTimes || []), newSchedule];
    
    // Update local modifications
    setLocalModifications(prev => ({
      ...prev,
      [selectedSpot.id]: { 
        ...prev[selectedSpot.id],
        scheduledTimes: updatedScheduleTimes 
      }
    }));
    
    // Update the selectedSpot
    setSelectedSpot({
      ...selectedSpot,
      scheduledTimes: updatedScheduleTimes
    });
    
    console.log(`Added scheduled time for spot ${selectedSpot.id}`, newSchedule);
    // In a real app, you would call an API endpoint here
    // apiService.addSpotSchedule(selectedSpot.id, newSchedule);
    
    // Close the modal
    handleAddScheduleClose();
  };
  
  // Update the handleRemoveScheduledTime function to use localModifications
  const handleRemoveScheduledTime = (spotId: string, index: number) => {
    if (!selectedSpot || !selectedSpot.scheduledTimes) return;
    
    // Create updated schedule times
    const updatedScheduleTimes = [...selectedSpot.scheduledTimes];
    updatedScheduleTimes.splice(index, 1);
    
    // Update local modifications
    setLocalModifications(prev => ({
      ...prev,
      [spotId]: { 
        ...prev[spotId],
        scheduledTimes: updatedScheduleTimes 
      }
    }));
    
    // Update the selectedSpot
    setSelectedSpot({
      ...selectedSpot,
      scheduledTimes: updatedScheduleTimes
    });
    
    console.log(`Removed scheduled time at index ${index} for spot ${spotId}`);
    // In a real app, you would call an API endpoint here
    // apiService.removeSpotSchedule(spotId, scheduleId);
  };

  // Add handleMoveToYardSpot function to open the modal
  const handleMoveToYardSpot = (trailer: StagingTrailer) => {
    setSelectedStagingTrailer(trailer);
    setSelectedYardSpotForMove('');
    setAssignYardSpotError(null);
    setShowMoveToYardSpotModal(true);
  };

  // Add handleCloseMoveToYardSpotModal function to close the modal
  const handleCloseMoveToYardSpotModal = () => {
    setShowMoveToYardSpotModal(false);
    setSelectedStagingTrailer(null);
    setSelectedYardSpotForMove('');
    setAssignYardSpotError(null);
  };

  // Update handleAssignYardSpot to use localModifications without triggering unnecessary re-renders
  const handleAssignYardSpot = async () => {
    if (!selectedStagingTrailer || !selectedYardSpotForMove) {
      setAssignYardSpotError('Please select a yard spot');
      return;
    }

    setAssigningYardSpot(true);
    setAssignYardSpotError(null);
    
    try {
      const READ_API_URL = process.env.REACT_APP_READ_API_URL;
      
      // Create the payload
      const payload: AssignYardSpotPayload = {
        bol_id: selectedStagingTrailer.bol_id,
        yard_spot: selectedYardSpotForMove
      };
      
      // Always include carrier_name in the payload if it exists
      if (selectedStagingTrailer.carrier_name) {
        payload.carrier_name = selectedStagingTrailer.carrier_name;
        console.log('Including carrier_name in API payload:', selectedStagingTrailer.carrier_name);
      }
      
      // Make the API call to assign the yard spot
      const response = await axios.post(
        `${READ_API_URL}/api/v1/admin/drivers/assign_yard_spot_only`,
        payload
      );
      
      console.log('Assign yard spot API response:', response.data);
      
      // Find the spot to update
      const spotToUpdate = localYardSpots.find(spot => spot.displayNumber === selectedYardSpotForMove);
      
      if (spotToUpdate) {
        // Create the update object with all fields we need to update
        const updateData: any = {
          status: 'in-use' as Status,
          driverId: selectedStagingTrailer.driver_id,
          driverName: selectedStagingTrailer.driver_name,
          trailerNumber: selectedStagingTrailer.trailer_number,
          shipmentNumber: selectedStagingTrailer.puid,
          bol_id: selectedStagingTrailer.bol_id,
          // Always include carrier_name (even if null) to ensure it's explicitly set
          carrier_name: selectedStagingTrailer.carrier_name || null
        };
        
        // Update local modifications with one state update to avoid multiple re-renders
        setLocalModifications(prev => {
          const newMods = {
            ...prev,
            [spotToUpdate.id]: { 
              ...prev[spotToUpdate.id],
              ...updateData
            }
          };
          console.log('Updated local modifications for spot:', spotToUpdate.id, newMods[spotToUpdate.id]);
          return newMods;
        });
        
        // If this is the currently selected spot, update the selectedSpot state as well
        if (selectedSpot && selectedSpot.id === spotToUpdate.id) {
          setSelectedSpot(prevSpot => {
            const updatedSpot = {
              ...prevSpot!,
              ...updateData
            };
            console.log('Updated selected spot with carrier_name:', updatedSpot.carrier_name);
            return updatedSpot;
          });
        }
      }
      
      // Remove the trailer from staging since it's now assigned
      setStagingTrailers(prev => 
        prev.filter(trailer => trailer.puid !== selectedStagingTrailer.puid)
      );
      
      // Close the modal
      handleCloseMoveToYardSpotModal();
    } catch (err) {
      console.error('Error assigning yard spot:', err);
      setAssignYardSpotError('Failed to assign yard spot. Please try again.');
    } finally {
      setAssigningYardSpot(false);
    }
  };

  // Fetch staging trailers data
  const fetchTrailersInStaging = useCallback(async () => {
    if (!selectedFacility) return;
    
    setLoadingStagingTrailers(true);
    setStagingTrailersError(null);
    
    try {
      const READ_API_URL = process.env.REACT_APP_READ_API_URL;
      const response = await axios.get(
        `${READ_API_URL}/api/v1/admin/drivers/trailers_in_staging`, 
        {
          params: {
            facility_id: selectedFacility.id,
            da_interval: 24 // Default to 24 hours
          }
        }
      );
      
      // Process the trailers data to include carrier_name
      const processedData = Array.isArray(response.data) ? response.data.map(trailer => {
        let carrier_name = trailer.carrier_name;
        
        // Try to get carrier_name from yard_data if it exists and carrier_name doesn't
        if (!carrier_name && trailer.yard_data) {
          if (typeof trailer.yard_data === 'object' && trailer.yard_data.carrier_name) {
            carrier_name = trailer.yard_data.carrier_name;
          } else if (typeof trailer.yard_data === 'string') {
            try {
              const parsedYardData = JSON.parse(trailer.yard_data);
              if (parsedYardData.carrier_name) {
                carrier_name = parsedYardData.carrier_name;
              }
            } catch (e) {
              // Not valid JSON, ignore
            }
          }
        }
        
        // Return trailer with carrier_name
        return {
          ...trailer,
          carrier_name: carrier_name
        };
      }) : [];
      
      // Only update if different to avoid re-renders
      setStagingTrailers(prevTrailers => {
        if (JSON.stringify(prevTrailers) === JSON.stringify(processedData)) {
          return prevTrailers;
        }
        return processedData;
      });
    } catch (err) {
      setStagingTrailersError('Failed to fetch staging trailers');
      console.error(err);
    } finally {
      setLoadingStagingTrailers(false);
    }
  }, [selectedFacility]);
  
  // Fetch staging trailers on facility change or manual refresh
  useEffect(() => {
    if (selectedFacility) {
      fetchTrailersInStaging();
      
      // Setup less frequent polling for staging area
      const interval = setInterval(fetchTrailersInStaging, 60000);
      return () => clearInterval(interval);
    }
  }, [selectedFacility, fetchTrailersInStaging]);
  
  // Only call refresh on manual refresh
  const handleRefreshData = useCallback(() => {
    refreshData();
    fetchTrailersInStaging();
  }, [refreshData, fetchTrailersInStaging]);

  // Add function to fetch active dock doors
  const fetchActiveDockDoors = useCallback(async () => {
    if (!selectedFacility) return;
    
    setLoadingDockDoors(true);
    
    try {
      const READ_API_URL = process.env.REACT_APP_READ_API_URL;
      const response = await axios.get(
        `${READ_API_URL}/api/v1/admin/drivers/assigned_dock_slots`, 
        {
          params: {
            facility_id: selectedFacility.id,
            da_interval: 24 // 24 hours
          }
        }
      );
      
      // Log the raw API response for debugging
      console.log("Dock doors API response:", response.data);
      
      // Get list of all assigned doors from the API response
      let assignedDoors: string[] = [];
      
      if (Array.isArray(response.data)) {
        assignedDoors = response.data
          .map((door: any) => {
            const doorNumber = door.dock_number || 
                            (door.dock_door && door.dock_door.includes(':') ? 
                              door.dock_door.split(':')[1] : door.dock_door) || 
                            door.number;
            return doorNumber;
          })
          .filter(Boolean);
      }
      
      console.log("Assigned dock doors:", assignedDoors);
      
      // Create the full list of all possible dock doors
      const allDockDoors = Array.from({ length: 20 }, (_, i) => `D${i + 1}`);
      
      // Filter out the assigned doors to get available doors
      const availableDoors = allDockDoors.filter(door => !assignedDoors.includes(door));
      
      console.log("Available dock doors:", availableDoors);
      
      // If we have available doors, use them
      if (availableDoors.length > 0) {
        setActiveDockDoors(availableDoors);
      } else {
        // If no available doors (all are assigned), provide the full list with a warning
        setActiveDockDoors(allDockDoors);
        console.log("No available dock doors found, showing all doors");
      }
    } catch (err) {
      console.error('Error fetching active dock doors:', err);
      // Fallback to all doors in case of error
      const allDockDoors = Array.from({ length: 20 }, (_, i) => `D${i + 1}`);
      setActiveDockDoors(allDockDoors);
    } finally {
      setLoadingDockDoors(false);
    }
  }, [selectedFacility]);

  // Fetch active dock doors when facility changes or when opening the modal
  useEffect(() => {
    if (selectedFacility) {
      fetchActiveDockDoors();
    }
  }, [selectedFacility, fetchActiveDockDoors]);

  // Update the handleMoveToDock function to fetch active dock doors
  const handleMoveToDock = () => {
    if (!selectedSpot) return;
    setSelectedDockDoor('');
    setSelectedShipmentPuid(selectedSpot.shipmentNumber || '');
    setMoveToDockError(null);
    setShowMoveToDockModal(true);
    
    // Fetch fresh list of active dock doors
    fetchActiveDockDoors();
  };

  // Add handler for closing the move to dock modal
  const handleCloseMoveToDockModal = () => {
    setShowMoveToDockModal(false);
    setSelectedDockDoor('');
    setSelectedShipmentPuid('');
    setMoveToDockError(null);
  };

  // Update handler for moving trailer to dock
  const handleMoveTrailerToDock = async () => {
    if (!selectedShipmentPuid || !selectedDockDoor) {
      setMoveToDockError('Shipment ID and dock door are required');
      return;
    }

    // Make sure we have a bol_id to pass
    if (!selectedSpot || !selectedSpot.bol_id) {
      setMoveToDockError('Unable to find BOL ID for this yard spot. Please try again or end the assignment manually.');
      return;
    }

    setMovingToDock(true);
    setMoveToDockError(null);
    
    try {
      const READ_API_URL = process.env.REACT_APP_READ_API_URL;
      
      await axios.post(
        `${READ_API_URL}/api/v1/admin/drivers/move_trailer_to_dock`,
        {
          puid: selectedShipmentPuid,
          dock_door: selectedDockDoor,
          bol_id: selectedSpot.bol_id,
          trailer_number: selectedSpot.trailerNumber || ''
        }
      );
      
      // Update local state after successful API call
      if (selectedSpot) {
        updateSpotToAvailable(selectedSpot.id);
      }
      
      // Close both modals
      handleCloseMoveToDockModal();
      handleCloseDetailsModal();
      
      // Refresh data to show updated state
      refreshData();
    } catch (err) {
      console.error('Error moving trailer to dock:', err);
      setMoveToDockError('Failed to move trailer to dock. Please try again.');
    } finally {
      setMovingToDock(false);
    }
  };

  // Main component render
  return (
    <div className="p-5 max-w-7xl mx-auto">
      {/* Error handling */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-5" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}
      
      {/* Loading state */}
      {apiLoading ? (
        <div className="flex justify-center items-center h-64">
          <CircularProgress />
        </div>
      ) : localYardSpots.length === 0 ? (
        <div className="flex flex-col justify-center items-center h-64 bg-white p-5 shadow rounded">
          <div className="text-xl font-semibold text-gray-600 mb-2">No Yard Spots Found</div>
          <p className="text-gray-500">There are currently no yard spots available for the selected facility.</p>
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          {/* Header */}
          <div className="bg-white p-5 shadow rounded">
            <div className="flex justify-between items-center mb-2">
              <h1 className="text-2xl font-bold">Yard Spot Management</h1>
              
              <div className="flex flex-col items-end">
                {/* Refresh Button */}
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white flex items-center px-4 py-2 rounded shadow"
                  onClick={handleRefreshData}
                  disabled={apiLoading || loadingStagingTrailers}
                >
                  {apiLoading || loadingStagingTrailers ? (
                    <>
                      <CircularProgress size={16} className="mr-2" color="inherit" />
                      <span>Refreshing...</span>
                    </>
                  ) : (
                    <>
                      <Refresh fontSize="small" className="mr-2" />
                      <span>Refresh Data</span>
                    </>
                  )}
                </button>
              </div>
            </div>
            <p className="text-gray-600">Manage and monitor yard spots across different zones</p>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-5">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Facility</label>
                <Select 
                  options={facilities.map(f => ({ value: f.id, label: f.name }))}
                  value={{ value: selectedFacility?.id, label: selectedFacility?.name }}
                  onChange={(option) => {
                    const facility = facilities.find(f => f.id === option?.value);
                    if (facility) setSelectedFacility(facility);
                  }}
                  className="w-full"
                  isSearchable
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Time Interval</label>
                <div className="w-full flex items-center border rounded px-3 py-2 bg-gray-50">
                  <span className="text-sm font-medium">Time: Max</span>
                </div>
              </div>
              
              <div className="flex flex-col justify-end">
                <div className="flex items-center justify-between">
                  <div>
                    <span className="block text-sm font-medium text-gray-700 mb-1">Yard Capacity</span>
                    <span className="text-lg font-bold">{apiYardSpots.length} spots</span>
                  </div>
                  <Button 
                    variant="outlined" 
                    onClick={handleShowZoneManage}
                    size="small"
                  >
                    Manage Zones
                  </Button>
                </div>
              </div>
            </div>
          </div>
          
          {/* Tabs */}
          <div className="bg-white shadow">
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Current Status" />
              <Tab label="History & Analytics" />
            </Tabs>
          </div>
          
          {/* Tab content */}
          {tabValue === 0 && (
            <div>
              {/* Filters */}
              <div className="flex flex-wrap gap-3 items-center bg-white p-3 shadow mb-4">
                <div className="flex items-center">
                  <FilterList className="mr-2 text-gray-500" />
                  <span className="font-medium mr-2">Filters:</span>
                </div>
                
                <div className="flex items-center">
                  <span className="mr-2">Zone:</span>
                  <Select
                    options={[
                      { value: null, label: 'All Zones' },
                      ...zones.map(zone => ({
                        value: zone.id,
                        label: zone.name
                      }))
                    ]}
                    value={
                      filterZone 
                        ? { 
                            value: filterZone, 
                            label: zones.find(z => z.id === filterZone)?.name || 'Unknown Zone' 
                          }
                        : { value: null, label: 'All Zones' }
                    }
                    onChange={(option) => setFilterZone(option?.value || null)}
                    className="w-40"
                  />
                </div>
                
                <div className="flex items-center ml-4">
                  <span className="mr-2">Status:</span>
                  <Select
                    options={[
                      { value: null, label: 'All Statuses' },
                      { value: 'available', label: 'Available' },
                      { value: 'in-use', label: 'In Use' },
                      { value: 'inactive', label: 'Inactive' }
                    ]}
                    value={
                      filterStatus 
                        ? { value: filterStatus, label: filterStatus.charAt(0).toUpperCase() + filterStatus.slice(1) } 
                        : { value: null, label: 'All Statuses' }
                    }
                    onChange={(option) => setFilterStatus(option?.value as Status || null)}
                    className="w-40"
                  />
                </div>
              </div>
              
              {/* Zone Legend */}
              <div className="flex flex-wrap gap-2 bg-white p-3 shadow mb-4">
                <span className="font-medium mr-2">Zones:</span>
                {zones.map((zone) => (
                  <div 
                    key={zone.id} 
                    className="flex items-center border px-2 py-1 rounded cursor-pointer hover:bg-gray-50"
                    style={{ borderColor: zone.color }}
                    onClick={() => setFilterZone(filterZone === zone.id ? null : zone.id)}
                  >
                    <div 
                      className="w-3 h-3 mr-1 border-2 rounded-full"
                      style={{ borderColor: zone.color, backgroundColor: filterZone === zone.id ? zone.color : 'transparent' }}
                    ></div>
                    <span className="text-sm">{zone.name}</span>
                    <span className="text-xs ml-1 text-gray-500">({localYardSpots.filter(s => s.zoneId === zone.id).length})</span>
                  </div>
                ))}
              </div>
              
              {/* Staging Area - with fixed height container */}
              <div ref={stagingAreaRef} className="mb-5" style={{ minHeight: stagingHeight || 'auto' }}>
                <div className="bg-amber-100 border-2 border-amber-600 border-dashed p-3 rounded">
                  <div className="flex items-center mb-2">
                    <div className="w-4 h-4 bg-amber-400 mr-2 rounded-full"></div>
                    <h3 className="font-bold text-amber-800">Staging Area</h3>
                  </div>
                  <p className="text-xs text-amber-700 mb-3">Temporary holding area for trailers awaiting assignment</p>
                  
                  {loadingStagingTrailers ? (
                    <div className="flex justify-center items-center h-16">
                      <CircularProgress size={24} />
                    </div>
                  ) : stagingTrailersError ? (
                    <div className="text-red-600 text-sm p-2">
                      {stagingTrailersError}
                    </div>
                  ) : stagingTrailers.length === 0 ? (
                    <div className="text-center text-sm text-amber-700 p-2">
                      No trailers currently in staging area
                    </div>
                  ) : (
                    <div className="grid grid-cols-5 sm:grid-cols-6 md:grid-cols-8 gap-3">
                      {stagingTrailers.map((trailer) => (
                        <div key={`${trailer.puid}-${trailer.trailer_number}`} className="bg-white rounded border-2 border-orange-400 p-2 shadow-sm">
                          <div className="text-sm font-bold">{trailer.trailer_number}</div>
                          <div className="text-xs text-blue-600">{trailer.driver_name}</div>
                          <div className="text-xs text-gray-600">{new Date(trailer.check_in_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                          <button 
                            className="mt-2 w-full text-xs bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded flex items-center justify-center"
                            onClick={() => handleMoveToYardSpot(trailer)}
                          >
                            <ArrowForward fontSize="small" className="mr-1" />
                            Move to Spot
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              
              {/* Grouped Yard Spot Display - with fixed height container */}
              <div ref={spotsContainerRef} className="space-y-6" style={{ minHeight: spotsHeight || 'auto' }}>
                {filterZone ? (
                  // If filtering by a specific zone, show only that zone's spots
                  <div className="bg-white p-4 shadow rounded">
                    <div className="flex justify-between items-center mb-3">
                      <h3 className="font-bold text-lg" style={{ color: zones.find(z => z.id === filterZone)?.color }}>
                        {zones.find(z => z.id === filterZone)?.name || 'Unknown Zone'}
                      </h3>
                      <span className="text-sm text-gray-500">
                        {filteredYardSpots.length} spots
                      </span>
                    </div>
                    
                    <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-10 xl:grid-cols-12 gap-2">
                      {filteredYardSpots.map(spot => (
                        <Tooltip
                          key={spot.id}
                          title={
                            <div className="p-1">
                              <div className="font-bold">{spot.displayNumber} - {zones.find(z => z.id === spot.zoneId)?.name}</div>
                              {spot.status === 'in-use' && (
                                <>
                                  {spot.trailerNumber && <div className="text-xs">Trailer: {spot.trailerNumber}</div>}
                                  {spot.driverName && <div className="text-xs">Driver: {spot.driverName}</div>}
                                  {spot.carrier_name && <div className="text-xs">SCAC: {spot.carrier_name}</div>}
                                  {spot.shipmentNumber && <div className="text-xs">Shipment: {spot.shipmentNumber}</div>}
                                </>
                              )}
                              <div className="text-xs mt-1">
                                Status: {spot.status.charAt(0).toUpperCase() + spot.status.slice(1)}
                              </div>
                            </div>
                          }
                          arrow
                        >
                          <div
                            className={`
                              w-16 h-16 rounded shadow cursor-pointer flex flex-col relative
                              ${spot.status === 'available' ? 'bg-green-100 border-green-400' :
                                spot.status === 'in-use' ? 'bg-blue-100 border-blue-400' :
                                spot.status === 'inactive' ? 'bg-red-100 border-red-400' :
                                'bg-yellow-100 border-yellow-400'} 
                              border-2 hover:opacity-90 transition-opacity
                            `}
                            onClick={() => handleShowSpotDetails(spot)}
                            style={{ borderColor: zones.find(z => z.id === spot.zoneId)?.color }}
                          >
                            <div 
                              className={`h-2 w-full rounded-t ${
                                spot.status === 'available' ? 'bg-green-500' :
                                spot.status === 'in-use' ? 'bg-blue-500' :
                                spot.status === 'inactive' ? 'bg-red-500' :
                                'bg-yellow-500'
                              }`}
                            ></div>
                            
                            <div className="flex flex-1 items-center justify-center">
                              <span className="font-bold text-sm">{spot.displayNumber}</span>
                            </div>
                            
                            {spot.status === 'in-use' && (
                              <div className="absolute top-1 right-1">
                                <LocalParking fontSize="small" className="text-blue-700" />
                              </div>
                            )}
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                ) : (
                  // If not filtering, show all zones
                  spotsByZone.map(({ zone, spots }) => (
                    spots.length > 0 && (
                      <div key={zone.id} className="bg-white p-4 shadow rounded">
                        <div className="flex justify-between items-center mb-3">
                          <h3 className="font-bold text-lg" style={{ color: zone.color }}>
                            {zone.name}
                          </h3>
                          <div className="flex items-center gap-2">
                            <span className="text-sm text-gray-500">
                              {spots.length} spots
                            </span>
                            <button 
                              className="text-sm text-blue-600 hover:underline"
                              onClick={() => setFilterZone(zone.id)}
                            >
                              View All
                            </button>
                          </div>
                        </div>
                        
                        <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-10 xl:grid-cols-12 gap-2">
                          {spots.slice(0, 24).map(spot => (
                            <Tooltip
                              key={spot.id}
                              title={
                                <div className="p-1">
                                  <div className="font-bold">{spot.displayNumber} - {zone.name}</div>
                                  {spot.status === 'in-use' && (
                                    <>
                                      {spot.trailerNumber && <div className="text-xs">Trailer: {spot.trailerNumber}</div>}
                                      {spot.driverName && <div className="text-xs">Driver: {spot.driverName}</div>}
                                      {spot.carrier_name && <div className="text-xs">SCAC: {spot.carrier_name}</div>}
                                      {spot.shipmentNumber && <div className="text-xs">Shipment: {spot.shipmentNumber}</div>}
                                    </>
                                  )}
                                  <div className="text-xs mt-1">
                                    Status: {spot.status.charAt(0).toUpperCase() + spot.status.slice(1)}
                                  </div>
                                </div>
                              }
                              arrow
                            >
                              <div
                                className={`
                                  w-16 h-16 rounded shadow cursor-pointer flex flex-col relative
                                  ${spot.status === 'available' ? 'bg-green-100 border-green-400' :
                                    spot.status === 'in-use' ? 'bg-blue-100 border-blue-400' :
                                    spot.status === 'inactive' ? 'bg-red-100 border-red-400' :
                                    'bg-yellow-100 border-yellow-400'} 
                                  border-2 hover:opacity-90 transition-opacity
                                `}
                                onClick={() => handleShowSpotDetails(spot)}
                                style={{ borderColor: zone.color }}
                              >
                                <div 
                                  className={`h-2 w-full rounded-t ${
                                    spot.status === 'available' ? 'bg-green-500' :
                                    spot.status === 'in-use' ? 'bg-blue-500' :
                                    spot.status === 'inactive' ? 'bg-red-500' :
                                    'bg-yellow-500'
                                  }`}
                                ></div>
                                
                                <div className="flex flex-1 items-center justify-center">
                                  <span className="font-bold text-sm">{spot.displayNumber}</span>
                                </div>
                                
                                {spot.status === 'in-use' && (
                                  <div className="absolute top-1 right-1">
                                    <LocalParking fontSize="small" className="text-blue-700" />
                                  </div>
                                )}
                              </div>
                            </Tooltip>
                          ))}
                          
                          {spots.length > 24 && (
                            <div 
                              className="w-16 h-16 rounded shadow flex items-center justify-center bg-gray-100 border-2 border-gray-300 cursor-pointer"
                              onClick={() => setFilterZone(zone.id)}
                            >
                              <span className="text-sm font-medium text-gray-600">+{spots.length - 24} more</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  ))
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Zone Management Modal */}
      {showZoneManageModal && (
        <Modal
          open={showZoneManageModal}
          onClose={handleZoneManageClose}
        >
          <div className="flex justify-center items-center w-full h-full">
            <div className="w-[500px] bg-white border p-5 max-h-[80vh] overflow-y-auto relative">
              <Close
                className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                onClick={handleZoneManageClose}
              />
              <h1 className="text-xl font-bold mb-5">Manage Yard Zones</h1>
              
              <div className="mb-5">
                <h2 className="text-lg font-semibold mb-3">Current Zones</h2>
                <div className="space-y-2">
                  {zones.map(zone => (
                    <div 
                      key={zone.id} 
                      className="flex items-center justify-between p-3 border rounded hover:bg-gray-50"
                    >
                      <div className="flex items-center">
                        <div 
                          className="w-4 h-4 mr-2 rounded-full"
                          style={{ backgroundColor: zone.color }}
                        ></div>
                        <span className="font-medium">{zone.name}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-sm text-gray-500">
                          {localYardSpots.filter(spot => spot.zoneId === zone.id).length} spots
                        </span>
                        <IconButton size="small" onClick={() => handleShowZoneEdit(zone)}>
                          <Edit fontSize="small" />
                        </IconButton>
                        <IconButton 
                          size="small" 
                          onClick={() => handleDeleteZone(zone.id)}
                          disabled={localYardSpots.filter(spot => spot.zoneId === zone.id).length > 0}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="mb-5 border-t pt-5">
                <h2 className="text-lg font-semibold mb-3">Add New Zone</h2>
                <div className="flex gap-3 mb-3">
                  <TextField
                    label="Zone Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newZoneName}
                    onChange={(e) => setNewZoneName(e.target.value)}
                  />
                  <div className="flex items-center gap-2">
                    <input
                      type="color"
                      value={newZoneColor}
                      onChange={(e) => setNewZoneColor(e.target.value)}
                      className="w-10 h-10 rounded cursor-pointer"
                    />
                  </div>
                </div>
                <Button 
                  variant="contained" 
                  color="primary"
                  fullWidth
                  onClick={handleAddNewZone}
                  disabled={!newZoneName.trim()}
                >
                  Add Zone
                </Button>
              </div>
              
              <div className="flex justify-end pt-3 border-t">
                <Button onClick={handleZoneManageClose}>Close</Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      
      {/* Zone Edit Modal */}
      {showZoneEditModal && editingZone && (
        <Modal
          open={showZoneEditModal}
          onClose={handleZoneEditClose}
        >
          <div className="flex justify-center items-center w-full h-full">
            <div className="w-96 bg-white border p-5 relative">
              <Close
                className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                onClick={handleZoneEditClose}
              />
              <h1 className="text-xl font-bold mb-5">Edit Zone</h1>
              
              <div className="space-y-4 mb-5">
                <TextField
                  label="Zone Name"
                  variant="outlined"
                  fullWidth
                  value={editingZone.name}
                  onChange={(e) => setEditingZone({ ...editingZone, name: e.target.value })}
                />
                
                <div className="flex items-center gap-3">
                  <span>Zone Color:</span>
                  <input
                    type="color"
                    value={editingZone.color}
                    onChange={(e) => setEditingZone({ ...editingZone, color: e.target.value })}
                    className="w-12 h-12 rounded cursor-pointer"
                  />
                </div>
              </div>
              
              <div className="flex justify-between pt-3 border-t">
                <Button onClick={handleZoneEditClose}>Cancel</Button>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={handleSaveZone}
                  disabled={!editingZone.name.trim()}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {/* Spot Details Modal */}
      {showSpotDetailsModal && selectedSpot && (
        <Modal
          open={showSpotDetailsModal}
          onClose={handleCloseDetailsModal}
        >
          <div className="flex justify-center items-center w-full h-full">
            <div className="w-96 bg-white border p-5 max-h-[80vh] overflow-y-auto relative">
              <Close
                className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                onClick={handleCloseDetailsModal}
              />
              <h1 className="text-xl font-bold mb-5">Spot {selectedSpot.displayNumber} Details</h1>
              
              <div className="mb-4">
                <h2 className="font-bold mb-2">Status</h2>
                <div className="flex gap-2">
                  {(['available', 'in-use', 'inactive'] as Status[]).map(status => (
                    <button
                      key={status}
                      className={twMerge(
                        'px-3 py-1 rounded text-sm',
                        selectedSpot.status === status 
                          ? `${status === 'available' ? 'bg-green-500' : 
                             status === 'in-use' ? 'bg-blue-500' : 
                             'bg-red-500'} text-white` 
                          : 'bg-gray-200 hover:bg-gray-300'
                      )}
                      onClick={() => handleStatusChange(selectedSpot.id, status)}
                    >
                      {status.charAt(0).toUpperCase() + status.slice(1)}
                    </button>
                  ))}
                </div>
              </div>
              
              <div className="mb-4">
                <h2 className="font-bold mb-2">Zone</h2>
                <div className="flex gap-2">
                  {zones.map(zone => (
                    <button
                      key={zone.id}
                      className={twMerge(
                        'px-3 py-1 rounded text-sm',
                        selectedSpot.zoneId === zone.id 
                          ? `border-2 border-${zone.color} bg-${zone.color}/10` 
                          : 'bg-gray-200 hover:bg-gray-300 border border-gray-300'
                      )}
                      style={{
                        borderColor: selectedSpot.zoneId === zone.id ? zone.color : undefined,
                        backgroundColor: selectedSpot.zoneId === zone.id ? `${zone.color}20` : undefined
                      }}
                      onClick={() => handleZoneChange(selectedSpot.id, zone.id)}
                    >
                      {zone.name}
                    </button>
                  ))}
                </div>
              </div>
              
              <div className="mb-4">
                <div className="flex items-center justify-between mb-2">
                  <h2 className="font-bold">Trailer Assignment</h2>
                </div>
                
                {selectedSpot.status === 'in-use' ? (
                  <div className="p-3 border rounded bg-gray-50">
                    {selectedSpot.trailerNumber && (
                      <p className="text-sm font-semibold text-gray-800 mb-1">
                        Trailer: {selectedSpot.trailerNumber}
                      </p>
                    )}
                    {selectedSpot.carrier_name && (
                      <p className="text-sm font-semibold text-gray-800 mb-1">
                        SCAC: {selectedSpot.carrier_name}
                      </p>
                    )}
                    {selectedSpot.shipmentNumber && (
                      <p className="text-sm font-semibold text-gray-800 mb-3">
                        Shipment: {selectedSpot.shipmentNumber}
                      </p>
                    )}
                    
                    {endAssignmentError && (
                      <p className="text-sm text-red-600 mt-2 mb-2">
                        {endAssignmentError}
                      </p>
                    )}
                    
                    <div className="flex justify-between mt-2 gap-2">
                      <button 
                        className="bg-blue-500 hover:bg-blue-600 text-white text-sm py-1 px-2 rounded flex items-center"
                        onClick={handleMoveToDock}
                        disabled={!selectedSpot.shipmentNumber || isEndingAssignment}
                      >
                        <ArrowForward fontSize="small" className="mr-1" />
                        <span>Move to Dock</span>
                      </button>
                      
                      <button 
                        className="text-red-600 hover:text-red-800 text-sm py-1 px-2 border border-red-300 rounded flex items-center"
                        onClick={() => handleEndAssignment(selectedSpot.id)}
                        disabled={isEndingAssignment}
                      >
                        {isEndingAssignment ? (
                          <>
                            <CircularProgress size={14} className="mr-1" />
                            <span>Ending...</span>
                          </>
                        ) : (
                          <>
                            <Close fontSize="small" className="mr-1" />
                            <span>End Assignment</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  <p className="text-sm text-gray-500">No trailer assigned</p>
                )}
              </div>
              
              <div className="mb-4">
                <div className="flex items-center justify-between mb-2">
                  <h2 className="font-bold">Scheduled Times</h2>
                  <button
                    className="text-sky-600 text-sm hover:underline"
                    onClick={handleAddScheduleOpen}
                  >
                    + Add Time
                  </button>
                </div>
                
                {(selectedSpot.scheduledTimes || []).length === 0 ? (
                  <p className="text-sm text-gray-500">No scheduled times</p>
                ) : (
                  <div className="space-y-2">
                    {selectedSpot.scheduledTimes?.map((time, index) => (
                      <div 
                        key={index} 
                        className="flex items-center justify-between bg-gray-100 p-2 rounded"
                      >
                        <div>
                          <p className="text-sm font-medium">
                            {formatTime(time.startTime)} - {formatTime(time.endTime)}
                          </p>
                          {time.trailerNumber && (
                            <p className="text-xs text-gray-700">Trailer: {time.trailerNumber}</p>
                          )}
                          {time.shipmentNumber && (
                            <p className="text-xs text-gray-700">Shipment: {time.shipmentNumber}</p>
                          )}
                        </div>
                        <button
                          className="text-red-500 hover:text-red-700"
                          onClick={() => handleRemoveScheduledTime(selectedSpot.id, index)}
                        >
                          <Close fontSize="small" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              <div className="mt-5 pt-3 border-t flex justify-end">
                <button
                  className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
                  onClick={handleCloseDetailsModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {/* Add Scheduled Time Modal */}
      {showAddScheduleModal && selectedSpot && (
        <Modal
          open={showAddScheduleModal}
          onClose={handleAddScheduleClose}
        >
          <div className="flex justify-center items-center w-full h-full">
            <div className="w-96 bg-white border p-5 relative">
              <Close
                className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                onClick={handleAddScheduleClose}
              />
              <h1 className="text-xl font-bold mb-5">Add Scheduled Time</h1>
              
              <div className="space-y-4 mb-5">
                <TextField
                  label="Start Time"
                  type="datetime-local"
                  variant="outlined"
                  fullWidth
                  value={newScheduledTime.startTime}
                  onChange={(e) => setNewScheduledTime({
                    ...newScheduledTime,
                    startTime: e.target.value
                  })}
                  InputLabelProps={{ shrink: true }}
                />
                
                <TextField
                  label="End Time"
                  type="datetime-local"
                  variant="outlined"
                  fullWidth
                  value={newScheduledTime.endTime}
                  onChange={(e) => setNewScheduledTime({
                    ...newScheduledTime,
                    endTime: e.target.value
                  })}
                  InputLabelProps={{ shrink: true }}
                />
                
                <TextField
                  label="Driver ID (Optional)"
                  variant="outlined"
                  fullWidth
                  value={newScheduledTime.driverId || ''}
                  onChange={(e) => setNewScheduledTime({
                    ...newScheduledTime,
                    driverId: e.target.value || null
                  })}
                />
                
                <TextField
                  label="Driver Name (Optional)"
                  variant="outlined"
                  fullWidth
                  value={newScheduledTime.driverName || ''}
                  onChange={(e) => setNewScheduledTime({
                    ...newScheduledTime,
                    driverName: e.target.value || null
                  })}
                />
                
                <TextField
                  label="Trailer ID (Optional)"
                  variant="outlined"
                  fullWidth
                  value={newScheduledTime.trailerId || ''}
                  onChange={(e) => setNewScheduledTime({
                    ...newScheduledTime,
                    trailerId: e.target.value || null
                  })}
                />
                
                <TextField
                  label="Trailer Number (Optional)"
                  variant="outlined"
                  fullWidth
                  value={newScheduledTime.trailerNumber || ''}
                  onChange={(e) => setNewScheduledTime({
                    ...newScheduledTime,
                    trailerNumber: e.target.value || null
                  })}
                />
                
                <TextField
                  label="Shipment Number (Optional)"
                  variant="outlined"
                  fullWidth
                  value={newScheduledTime.shipmentNumber || ''}
                  onChange={(e) => setNewScheduledTime({
                    ...newScheduledTime,
                    shipmentNumber: e.target.value || null
                  })}
                />
              </div>
              
              <div className="flex justify-between pt-3 border-t">
                <Button onClick={handleAddScheduleClose}>Cancel</Button>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={handleAddScheduleSubmit}
                >
                  Add Schedule
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {/* Move to Dock Modal */}
      {showMoveToDockModal && selectedSpot && (
        <Modal
          open={showMoveToDockModal}
          onClose={handleCloseMoveToDockModal}
        >
          <div className="flex justify-center items-center w-full h-full">
            <div className="w-[450px] bg-white border p-5 max-h-[90vh] overflow-y-auto relative">
              <Close
                className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                onClick={handleCloseMoveToDockModal}
              />
              <h1 className="text-xl font-bold mb-4">Move Trailer to Dock Door</h1>
              
              {/* Trailer details */}
              <div className="bg-blue-50 border border-blue-200 rounded p-3 mb-4">
                <div className="grid grid-cols-2 gap-2">
                  <div className="col-span-2">
                    <span className="font-semibold">Spot:</span> {selectedSpot.displayNumber}
                  </div>
                  {selectedSpot.trailerNumber && (
                    <div className="col-span-2">
                      <span className="font-semibold">Trailer:</span> {selectedSpot.trailerNumber}
                    </div>
                  )}
                  {selectedSpot.carrier_name && (
                    <div className="col-span-2">
                      <span className="font-semibold">SCAC:</span> {selectedSpot.carrier_name}
                    </div>
                  )}
                </div>
              </div>
              
              {/* Show error if any */}
              {moveToDockError && (
                <div className="mb-4 text-sm text-red-600 bg-red-50 border border-red-200 rounded p-2">
                  {moveToDockError}
                </div>
              )}
              
              {/* Shipment PUID field */}
              <div className="mb-4">
                <h2 className="font-semibold mb-2">Shipment PUID</h2>
                <TextField
                  label="Enter Shipment PUID"
                  variant="outlined"
                  fullWidth
                  value={selectedShipmentPuid}
                  onChange={(e) => setSelectedShipmentPuid(e.target.value)}
                  helperText="Enter the shipment identifier (e.g. S12345)"
                />
              </div>
              
              {/* Dock door selection */}
              <div className="mb-4">
                <h2 className="font-semibold mb-2">Select Dock Door</h2>
                
                {/* Loading state */}
                {loadingDockDoors ? (
                  <div className="flex justify-center items-center py-2">
                    <CircularProgress size={24} />
                    <span className="ml-2 text-sm text-gray-600">Loading available dock doors...</span>
                  </div>
                ) : (
                  <>
                    <TextField
                      label="Enter Dock Door Number"
                      variant="outlined"
                      fullWidth
                      value={selectedDockDoor}
                      onChange={(e) => setSelectedDockDoor(e.target.value)}
                      helperText="Enter the dock door identifier (e.g. D10)"
                    />
                    
                    {/* Only show available dock doors */}
                    {activeDockDoors.length > 0 && (
                      <div className="mt-3">
                        <div className="text-sm text-gray-600 mb-1">Active dock doors:</div>
                        <div className="flex flex-wrap gap-2">
                          {activeDockDoors.map(door => (
                            <button
                              key={door}
                              className={`px-2 py-1 border rounded text-sm ${
                                selectedDockDoor === door 
                                  ? 'bg-blue-100 border-blue-500' 
                                  : 'bg-gray-50 hover:bg-gray-100'
                              }`}
                              onClick={() => setSelectedDockDoor(door)}
                            >
                              {door}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                    
                    {/* No available dock doors message */}
                    {activeDockDoors.length === 0 && !loadingDockDoors && (
                      <div className="mt-3 text-amber-600 bg-amber-50 p-2 border border-amber-200 rounded text-sm">
                        No active dock doors are currently available. You can still manually enter a dock door number.
                      </div>
                    )}
                  </>
                )}
              </div>
              
              <div className="flex justify-between pt-3 border-t">
                <Button 
                  onClick={handleCloseMoveToDockModal}
                >
                  Cancel
                </Button>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={handleMoveTrailerToDock}
                  disabled={!selectedShipmentPuid.trim() || !selectedDockDoor.trim() || movingToDock}
                >
                  {movingToDock ? <CircularProgress size={24} /> : 'Move to Dock'}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      
      {/* Move to Yard Spot Modal */}
      {showMoveToYardSpotModal && selectedStagingTrailer && (
        <Modal
          open={showMoveToYardSpotModal}
          onClose={handleCloseMoveToYardSpotModal}
        >
          <div className="flex justify-center items-center w-full h-full">
            <div className="w-[450px] bg-white border p-5 max-h-[90vh] overflow-y-auto relative">
              <Close
                className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                onClick={handleCloseMoveToYardSpotModal}
              />
              <h1 className="text-xl font-bold mb-4">Move Trailer to Yard Spot</h1>
              
              {/* Trailer details */}
              <div className="bg-amber-50 border border-amber-200 rounded p-3 mb-4">
                <div className="grid grid-cols-2 gap-2">
                  <div className="col-span-2">
                    <span className="font-semibold">Trailer:</span> {selectedStagingTrailer.trailer_number}
                  </div>
                  <div>
                    <span className="font-semibold">Driver:</span> {selectedStagingTrailer.driver_name}
                  </div>
                  <div>
                    <span className="font-semibold">Phone:</span> {selectedStagingTrailer.driver_phone_number}
                  </div>
                  <div>
                    <span className="font-semibold">Check-in:</span> {new Date(selectedStagingTrailer.check_in_time).toLocaleString()}
                  </div>
                  {selectedStagingTrailer.carrier_name && (
                    <div className="col-span-2">
                      <span className="font-semibold">SCAC:</span> {selectedStagingTrailer.carrier_name}
                    </div>
                  )}
                </div>
              </div>
              
              {/* Show error if any */}
              {assignYardSpotError && (
                <div className="mb-4 text-sm text-red-600 bg-red-50 border border-red-200 rounded p-2">
                  {assignYardSpotError}
                </div>
              )}
              
              {/* Available yard spots selection */}
              <div className="mb-4">
                <h2 className="font-semibold mb-2">Select Yard Spot</h2>
                
                <div className="space-y-3">
                  {/* Manual entry */}
                  <TextField
                    label="Enter Yard Spot ID"
                    variant="outlined"
                    fullWidth
                    value={selectedYardSpotForMove}
                    onChange={(e) => setSelectedYardSpotForMove(e.target.value)}
                    helperText="Enter the yard spot identifier (e.g. A15)"
                  />
                  
                  {/* Quick selection from available spots */}
                  <div>
                    <div className="text-sm text-gray-600 mb-1">Or select from available spots:</div>
                    <div className="flex flex-wrap gap-2">
                      {(() => {
                        // Get available spots - show all available spots
                        const availableSpots = localYardSpots
                          .filter(spot => spot.status === 'available');
                          
                        if (availableSpots.length === 0) {
                          return (
                            <div className="text-amber-600 p-2 border border-amber-200 rounded text-sm bg-amber-50 w-full">
                              No available yard spots found. Please enter a yard spot ID manually.
                            </div>
                          );
                        }
                        
                        return availableSpots.map(spot => (
                          <button
                            key={spot.id}
                            className={`px-2 py-1 border rounded text-sm ${
                              selectedYardSpotForMove === spot.displayNumber 
                                ? 'bg-blue-100 border-blue-500' 
                                : 'bg-gray-50 hover:bg-gray-100'
                            }`}
                            onClick={() => setSelectedYardSpotForMove(spot.displayNumber)}
                          >
                            {spot.displayNumber}
                          </button>
                        ));
                      })()}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="flex justify-between pt-3 border-t">
                <Button onClick={handleCloseMoveToYardSpotModal}>Cancel</Button>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={handleAssignYardSpot}
                  disabled={!selectedYardSpotForMove.trim() || assigningYardSpot}
                >
                  {assigningYardSpot ? <CircularProgress size={24} /> : 'Move Trailer'}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
});

export default YardSpotManagement; 