import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { twMerge } from 'tailwind-merge';
import { useLinkClickHandler } from 'react-router-dom';
import { FacilityType } from '../../types/Facility';
import { Modal } from '@mui/material';
import { Close } from '@mui/icons-material';

const Facilities = () => {
  const [facilities, setFacilities] = useState<FacilityType[]>([]);
  const [searchedFacilities, setSearchedFacilities] = useState<FacilityType[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAddFacilityModal, setShowAddFacilityModal] = useState(false);
  const [addFacilitySuccess, setAddFacilitySuccess] = useState(false);

  useEffect(() => {
    const fetchFacilities = async () => {
      setLoading(true);
      try {
        // const response = await axios.get('/facilities');
        // setFacilities(response.data);
        setFacilities([
          {
            id: '123',
            name: 'Demo',
            address: '330 E. Liberty, Ann Arbor, MI 48104',
            gate_count: 2
          }
          // {
          //   id: '2',
          //   name: 'Facility 2',
          //   address: '456 Elm St',
          //   gate_count: 3
          // }
        ]);
      } catch (error) {
        setError('An error occurred while fetching facilities');
      }
      setLoading(false);
    };
    fetchFacilities();
  }, []);

  useEffect(() => {
    setSearchedFacilities(
      facilities.filter((facility) =>
        facility.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, facilities]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleAddShipmentModalClose = () => {
    setShowAddFacilityModal(false);
    setAddFacilitySuccess(false);
  };

  return (
    <div className="p-5 w-full">
      <div className="bg-[#FBFBFB] p-3 shadow-[1px_0_10px_1px_rgba(0,0,0,0.3)] text-sm">
        <div className="flex justify-between mb-2">
          <div className="w-full">
            <div className="flex gap-2 items-center mt-2">
              Filter By:
              <input
                type="search"
                className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                onChange={handleSearchChange}
                value={search}
                placeholder="Search"
              />
              <button
                className={twMerge(
                  'bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded ml-auto',
                  loading && 'bg-gray-300 hover:bg-gray-300'
                )}
                onClick={() => setShowAddFacilityModal(true)}
              >
                Add Facility
              </button>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </div>
        </div>

        <table className="table-auto border-slate-300 border-[1px] min-w-[66%]">
          <thead className="border-red-400 border-b-[3px]">
            <tr>
              <th className="p-2">Facility Name</th>

              <th className="p-2">ID</th>
              <th className="p-2">Address</th>
              <th className="p-2"># of Gates</th>
            </tr>
          </thead>
          {!loading && (
            <tbody className="text-center">
              {searchedFacilities.map((facility: FacilityType) => (
                <Row key={facility.id} facility={facility} />
              ))}
            </tbody>
          )}
        </table>

        {loading && (
          <div className="flex flex-col items-center mt-4">
            <CircularProgress />
            <p className="mt-2">Loading...</p>
          </div>
        )}
      </div>
      <Modal
        open={showAddFacilityModal}
        onClose={handleAddShipmentModalClose}
        className="flex justify-center items-center"
      >
        <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
          <Close
            className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
            onClick={handleAddShipmentModalClose}
          />
          <h1 className="text-xl mb-5">Add Facility</h1>

          {addFacilitySuccess ? (
            <div className="bg-green-100 p-2 border-slate-300 border-[1px] text-sm mb-3">
              Facility Added!
            </div>
          ) : (
            <>
              <label className="mt-3 flex flex-col">
                Name
                <input
                  type="text"
                  placeholder="Name"
                  className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                />
              </label>
              <label className="mt-3 flex flex-col">
                IDs
                <input
                  type="text"
                  placeholder="IDs"
                  className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                />
              </label>
              <label className="mt-3 flex flex-col">
                Address
                <input
                  type="text"
                  placeholder="Address"
                  className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                />
              </label>
              <label className="mt-3 flex flex-col">
                # Gates
                <input
                  type="text"
                  placeholder="# Gates"
                  className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                />
              </label>
              <label className="mt-3 flex flex-col">
                Open Gate Hours
                <input
                  type="text"
                  placeholder="Open Gate Hours"
                  className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                />
              </label>
              <button
                className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded ml-auto mt-3"
                onClick={() => {
                  setAddFacilitySuccess(true);
                  setTimeout(() => {
                    handleAddShipmentModalClose();
                  }, 3000);
                }}
              >
                Add Facility
              </button>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

interface RowProps {
  facility: FacilityType;
}

const Row = ({ facility }: RowProps) => {
  const { id, name, address, gate_count } = facility;
  const handleClick = useLinkClickHandler(`/facilities/${id}`);

  return (
    <tr
      onClick={(e: any) => handleClick(e)}
      className="hover:bg-[#f5f5f5] border-slate-200 border-b-[1px] cursor-pointer odd:bg-[#f9f9f9] last:border-b-0"
    >
      <td className="p-2">{name}</td>
      <td className="p-2">{id}</td>
      <td className="p-2">{address}</td>
      <td className="p-2">{gate_count}</td>
    </tr>
  );
};

export default Facilities;
