import { useState, useEffect } from 'react';
import { CircularProgress, Modal, Tooltip, Paper, Tab, Tabs, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Close, Edit, Check, ArrowForward, History, SwapHoriz, FilterList } from '@mui/icons-material';
import Select from 'react-select';
import { twMerge } from 'tailwind-merge';
import { useDockData, DockDoor, Status, SpotGroup, HistoryEntry, DockHistory } from './dockDataFetch';

const DockManagement = () => {
  // Get real dock data from API using our custom hook
  const {
    loading: apiLoading,
    dockDoors: apiDockDoors,
    dockHistory: apiDockHistory,
    error,
    facilities,
    selectedFacility,
    setSelectedFacility,
    timeInterval,
    setTimeInterval
  } = useDockData();

  // Set time interval to max always
  useEffect(() => {
    setTimeInterval(8760); // Always set to max (1 year)
  }, [setTimeInterval]);

  // Local state
  const [selectedDoor, setSelectedDoor] = useState<DockDoor | null>(null);
  const [showDoorDetailsModal, setShowDoorDetailsModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [groupFilter, setGroupFilter] = useState<SpotGroup | 'all'>('all');
  const [statusFilter, setStatusFilter] = useState<Status | 'all'>('all');
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [selectedHistoryDoor, setSelectedHistoryDoor] = useState<DockDoor | null>(null);
  const [timeRange, setTimeRange] = useState<'today' | 'week' | 'month'>('today');
  
  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  
  // Open door details modal
  const handleDoorClick = (door: DockDoor) => {
    setSelectedDoor(door);
    setShowDoorDetailsModal(true);
  };
  
  // Close door details modal
  const handleCloseDetailsModal = () => {
    setShowDoorDetailsModal(false);
    setSelectedDoor(null);
  };
  
  // Handle status change
  const handleStatusChange = (status: Status) => {
    if (!selectedDoor) return;
    
    // TODO: Implement API call to update status
    console.log('Update status:', status);
  };
  
  // Handle group change
  const handleGroupChange = (group: SpotGroup) => {
    if (!selectedDoor) return;
    
    // TODO: Implement API call to update group
    console.log('Update group:', group);
  };
  
  // Open history modal
  const handleShowHistory = (door: DockDoor) => {
    setSelectedHistoryDoor(door);
    setShowHistoryModal(true);
  };
  
  // Close history modal
  const handleCloseHistoryModal = () => {
    setShowHistoryModal(false);
    setSelectedHistoryDoor(null);
  };
  
  // Get color based on status
  const getStatusColor = (status: Status): string => {
    switch (status) {
      case 'available':
        return 'bg-green-500';
      case 'in-use':
        return 'bg-red-500';
      case 'unavailable':
        return 'bg-gray-500';
      case 'scheduled':
        return 'bg-yellow-500';
      default:
        return 'bg-gray-500';
    }
  };
  
  // Get color based on group
  const getGroupColor = (group: SpotGroup): string => {
    switch (group) {
      case 'inbound':
        return 'border-blue-500 border-4';
      case 'outbound':
        return 'border-orange-500 border-4';
      default:
        return 'border-gray-400 border';
    }
  };
  
  // Apply filters to dock doors
  const filteredDockDoors = apiDockDoors.filter(door => {
    if (groupFilter !== 'all' && door.group !== groupFilter) return false;
    if (statusFilter !== 'all' && door.status !== statusFilter) return false;
    return true;
  });
  
  // Doors with active assignments
  const activeDoors = apiDockDoors.filter(door => door.status === 'in-use');
  
  // Upcoming schedule based on scheduled times
  const upcomingSchedules = apiDockDoors.flatMap(door => 
    door.scheduledTimes.map(time => ({
      doorId: door.id,
      doorNumber: door.number,
      ...time
    }))
  ).sort((a, b) => a.start.localeCompare(b.start));
  
  if (apiLoading) {
    return (
      <div className="flex flex-col items-center mt-4 w-full">
        <CircularProgress />
        <p className="mt-2">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-5 text-red-500">
        <h1 className="font-bold text-xl mb-3">Error</h1>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="p-5">
      <div className="shadow bg-[#FBFBFB] p-3 mb-5">
        <div className="flex flex-col md:flex-row gap-3 mb-3">
          <div>
            <h1 className="font-bold text-xl mb-3">Dock Management</h1>
            <p>Monitor and manage dock doors, view current status and historical usage.</p>
          </div>
          <div className="flex gap-3 ml-auto">
            <Select
              options={facilities.map(facility => ({
                value: facility,
                label: `(${facility.id}) ${facility.name}`
              }))}
              isSearchable={true}
              isClearable
              value={facilities.map(facility => ({
                value: facility,
                label: `(${facility.id}) ${facility.name}`
              })).find(option => option.value.id === selectedFacility?.id)}
              placeholder="Facilities"
              onChange={(selectedOption: any) => {
                setSelectedFacility(selectedOption?.value);
              }}
              className="w-60"
            />
            <div className="w-40 flex items-center justify-center border rounded px-3 py-2 bg-gray-50">
              <span className="text-sm font-medium">Time: Max</span>
            </div>
          </div>
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Current Status" />
          <Tab label="History & Analytics" />
        </Tabs>
      </Box>

      {tabValue === 0 && (
        <div className="flex flex-col gap-5">
          {/* Filters Row */}
          <div className="flex flex-wrap gap-3 items-center bg-white p-3 shadow">
            <div className="flex items-center">
              <FilterList className="mr-2 text-gray-500" />
              <span className="font-medium mr-2">Filters:</span>
            </div>
            
            <div className="flex items-center mr-4">
              <span className="mr-2">Group:</span>
              <Select
                options={[
                  { value: 'all', label: 'All Groups' },
                  { value: 'inbound', label: 'Inbound' },
                  { value: 'outbound', label: 'Outbound' },
                  { value: 'unassigned', label: 'Unassigned' }
                ]}
                defaultValue={{ value: 'all', label: 'All Groups' }}
                onChange={(option: any) => setGroupFilter(option.value)}
                className="w-40"
              />
            </div>
            
            <div className="flex items-center">
              <span className="mr-2">Status:</span>
              <Select
                options={[
                  { value: 'all', label: 'All Status' },
                  { value: 'available', label: 'Available' },
                  { value: 'in-use', label: 'In Use' },
                  { value: 'unavailable', label: 'Unavailable' },
                  { value: 'scheduled', label: 'Scheduled' }
                ]}
                defaultValue={{ value: 'all', label: 'All Status' }}
                onChange={(option: any) => setStatusFilter(option.value)}
                className="w-40"
              />
            </div>
          </div>
          
          {/* Dock Door Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {filteredDockDoors.length === 0 ? (
              <div className="col-span-full text-center py-8 text-gray-500">
                No dock doors match your filters
              </div>
            ) : (
              filteredDockDoors.map(door => (
                <div 
                  key={door.id} 
                  className={`border rounded shadow-sm overflow-hidden ${
                    door.status === 'in-use' ? 'bg-red-50 border-red-200' :
                    door.status === 'available' ? 'bg-green-50 border-green-200' :
                    door.status === 'scheduled' ? 'bg-yellow-50 border-yellow-200' :
                    'bg-gray-50 border-gray-200'
                  }`}
                >
                  {/* Door Header */}
                  <div className={`flex justify-between items-center p-3 ${getGroupColor(door.group)}`}>
                    <h3 className="font-bold">Door {door.number}</h3>
                    <span className={`px-2 py-1 rounded text-xs font-medium ${
                      door.group === 'inbound' ? 'bg-blue-100 text-blue-800' :
                      door.group === 'outbound' ? 'bg-orange-100 text-orange-800' :
                      'bg-gray-100 text-gray-800'
                    }`}>
                      {door.group ? door.group.charAt(0).toUpperCase() + door.group.slice(1) : 'Unknown'}
                    </span>
                  </div>
                  
                  {/* Door Body */}
                  <div className="p-3">
                    <div className="flex justify-between mb-2">
                      <span className="text-sm text-gray-500">Status:</span>
                      <span className={`text-xs px-2 py-1 rounded ${
                        door.status === 'available' ? 'bg-green-100 text-green-800' :
                        door.status === 'in-use' ? 'bg-red-100 text-red-800' :
                        door.status === 'unavailable' ? 'bg-gray-100 text-gray-800' :
                        'bg-yellow-100 text-yellow-800'
                      }`}>
                        {door.status ? door.status.charAt(0).toUpperCase() + door.status.slice(1) : 'Unknown'}
                      </span>
                    </div>
                    
                    {/* Show assignment info for doors with any kind of assignment */}
                    {(door.status === 'in-use' || door.trailerNumber || door.shipmentNumber) && (
                      <div className="mt-2 border-t pt-2">
                        <div className="flex items-center mb-1">
                          <span className="text-sm font-medium mr-1">Driver:</span>
                          <span className="text-sm">{door.driverName || "No Driver"}</span>
                        </div>
                        
                        {door.trailerNumber && (
                          <div className="flex items-center mb-1">
                            <span className="text-sm font-medium mr-1">Trailer:</span>
                            <span className="text-sm">{door.trailerNumber}</span>
                          </div>
                        )}
                        
                        {door.shipmentNumber && (
                          <div className="flex items-center">
                            <span className="text-sm font-medium mr-1">Shipment:</span>
                            <span className="text-sm">{door.shipmentNumber}</span>
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Scheduled Times */}
                    {door.scheduledTimes.length > 0 && (
                      <div className="mt-3 border-t pt-3">
                        <h4 className="font-medium text-sm mb-1">Next Scheduled:</h4>
                        <p className="text-sm">
                          {door.scheduledTimes[0].start} - {door.scheduledTimes[0].end}
                        </p>
                        <p className="text-xs text-gray-600">
                          {door.scheduledTimes[0].shipmentId}
                        </p>
                      </div>
                    )}
                  </div>
                  
                  {/* Actions */}
                  <div className="bg-gray-50 p-2 flex justify-between">
                    <button 
                      className="text-gray-600 hover:text-gray-800 text-xs flex items-center"
                      onClick={() => handleShowHistory(door)}
                    >
                      <History fontSize="small" className="mr-1" />
                      History
                    </button>
                    <button 
                      className="text-blue-600 hover:text-blue-800 text-xs flex items-center"
                      onClick={() => handleDoorClick(door)}
                    >
                      <Edit fontSize="small" className="mr-1" />
                      Details
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
          
        
        </div>
      )}

      {tabValue === 1 && (
        <div className="flex flex-col gap-5">
          {/* History Filters */}
          <div className="flex flex-wrap gap-3 items-center bg-white p-3 shadow">
            <div className="flex items-center">
              <FilterList className="mr-2 text-gray-500" />
              <span className="font-medium mr-2">Time Range:</span>
            </div>
            
            <ToggleButtonGroup
              value={timeRange}
              exclusive
              onChange={(event, newTimeRange) => {
                if (newTimeRange !== null) {
                  setTimeRange(newTimeRange);
                }
              }}
              size="small"
            >
              <ToggleButton value="today">Today</ToggleButton>
              <ToggleButton value="week">Week</ToggleButton>
              <ToggleButton value="month">Month</ToggleButton>
            </ToggleButtonGroup>
            
            <div className="flex items-center ml-auto">
              <span className="mr-2">Door:</span>
              <Select
                options={[
                  { value: 'all', label: 'All Doors' },
                  ...apiDockDoors.map((door: DockDoor) => ({
                    value: door.id,
                    label: `Door ${door.number}`
                  }))
                ]}
                defaultValue={{ value: 'all', label: 'All Doors' }}
                className="w-40"
              />
            </div>
          </div>
          
          {/* Activity Timeline */}
          <div className="bg-white p-4 shadow">
            <h2 className="font-bold text-lg mb-4">Activity Timeline</h2>
            
            <div className="relative overflow-x-auto" style={{ height: '300px' }}>
              {/* Time markers */}
              <div className="absolute top-0 left-0 right-0 flex border-b">
                {Array.from({ length: 12 }, (_, i) => (
                  <div key={i} className="flex-1 text-xs text-center border-r py-1">
                    {(i + 8) % 12 === 0 ? '12' : (i + 8) % 12}:00 {i + 8 < 12 ? 'AM' : 'PM'}
                  </div>
                ))}
              </div>
              
              {/* Swim lanes for dock doors */}
              <div className="mt-8">
                {apiDockDoors.slice(0, 10).map((door, index) => {
                  const doorHistory = apiDockHistory[door.id] || [];
                  
                  return (
                    <div key={door.id} className="flex items-center h-10">
                      <div className="w-16 text-xs font-bold">
                        Door {door.number}
                      </div>
                      <div className="flex-1 relative h-6 bg-gray-100">
                        {doorHistory.map((entry, entryIndex) => {
                          // For demo purposes, position entries evenly across the timeline
                          const startPercent = (entryIndex * 20) % 80;
                          const widthPercent = 15;
                          
                          return (
                            <Tooltip 
                              key={entryIndex} 
                              title={`${entry.driverName} - ${entry.activity} (${entry.duration} min)`} 
                              arrow
                            >
                              <div 
                                className={`absolute h-full text-white text-xs flex items-center justify-center overflow-hidden whitespace-nowrap ${
                                  entry.activity === 'load' ? 'bg-orange-400' :
                                  entry.activity === 'unload' ? 'bg-blue-400' :
                                  'bg-purple-400'
                                }`}
                                style={{ 
                                  left: `${startPercent}%`,
                                  width: `${widthPercent}%`
                                }}
                              >
                                {entry.duration}m
                              </div>
                            </Tooltip>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          
          {/* Statistics */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-white p-4 shadow">
              <h3 className="font-bold text-lg mb-3">Door Utilization</h3>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span>Inbound Doors:</span>
                  <span className="font-bold">{apiDockDoors.filter(d => d.group === 'inbound').length}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Outbound Doors:</span>
                  <span className="font-bold">{apiDockDoors.filter(d => d.group === 'outbound').length}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Currently Active:</span>
                  <span className="font-bold">{activeDoors.length}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Utilization Rate:</span>
                  <span className="font-bold">
                    {apiDockDoors.length > 0 ? Math.round((activeDoors.length / apiDockDoors.length) * 100) : 0}%
                  </span>
                </div>
              </div>
            </div>
            
            <div className="bg-white p-4 shadow">
              <h3 className="font-bold text-lg mb-3">Activity Types</h3>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span>Load Operations:</span>
                  <span className="font-bold">
                    {Object.values(apiDockHistory).flat().filter(e => e.activity === 'load').length}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Unload Operations:</span>
                  <span className="font-bold">
                    {Object.values(apiDockHistory).flat().filter(e => e.activity === 'unload').length}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Combined Operations:</span>
                  <span className="font-bold">
                    {Object.values(apiDockHistory).flat().filter(e => e.activity === 'both').length}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Total Operations:</span>
                  <span className="font-bold">
                    {Object.values(apiDockHistory).flat().length}
                  </span>
                </div>
              </div>
            </div>
            
            <div className="bg-white p-4 shadow">
              <h3 className="font-bold text-lg mb-3">Duration Stats</h3>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span>Avg. Dock Time:</span>
                  <span className="font-bold">
                    {Object.values(apiDockHistory).flat().length > 0 
                      ? Math.round(Object.values(apiDockHistory).flat().reduce((sum, entry) => sum + entry.duration, 0) / 
                          Object.values(apiDockHistory).flat().length)
                      : 0} min
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Max Dock Time:</span>
                  <span className="font-bold">
                    {Object.values(apiDockHistory).flat().length > 0
                      ? Math.max(...Object.values(apiDockHistory).flat().map(e => e.duration))
                      : 0} min
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Min Dock Time:</span>
                  <span className="font-bold">
                    {Object.values(apiDockHistory).flat().length > 0
                      ? Math.min(...Object.values(apiDockHistory).flat().map(e => e.duration))
                      : 0} min
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Door Details Modal */}
      {showDoorDetailsModal && selectedDoor && (
        <Modal
          open={showDoorDetailsModal}
          onClose={handleCloseDetailsModal}
          className="flex justify-center items-center"
        >
          <div className="w-96 bg-white border p-5 max-h-[80vh] overflow-y-auto relative">
            <Close
              className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
              onClick={handleCloseDetailsModal}
            />
            <h1 className="text-xl font-bold mb-5">Door {selectedDoor.number} Details</h1>
            
            <div className="mb-4">
              <h2 className="font-bold mb-2">Status</h2>
              <div className="flex gap-2">
                {(['available', 'in-use', 'unavailable', 'scheduled'] as Status[]).map(status => (
                  <button
                    key={status}
                    className={twMerge(
                      'px-3 py-1 rounded text-sm',
                      selectedDoor.status === status 
                        ? `${getStatusColor(status)} text-white` 
                        : 'bg-gray-200 hover:bg-gray-300'
                    )}
                    onClick={() => handleStatusChange(status)}
                  >
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </button>
                ))}
              </div>
            </div>
            
            <div className="mb-4">
              <h2 className="font-bold mb-2">Group</h2>
              <div className="flex gap-2">
                {(['inbound', 'outbound', 'unassigned'] as SpotGroup[]).map(group => (
                  <button
                    key={group}
                    className={twMerge(
                      'px-3 py-1 rounded text-sm',
                      selectedDoor.group === group 
                        ? `border-2 ${group === 'inbound' ? 'border-blue-500 bg-blue-100' : 
                            group === 'outbound' ? 'border-orange-500 bg-orange-100' : 
                            'border-gray-400 bg-gray-100'}` 
                        : 'bg-gray-200 hover:bg-gray-300 border border-gray-300'
                    )}
                    onClick={() => handleGroupChange(group)}
                  >
                    {group.charAt(0).toUpperCase() + group.slice(1)}
                  </button>
                ))}
              </div>
            </div>
            
            <div className="mb-4">
              <div className="flex items-center justify-between mb-2">
                <h2 className="font-bold">Current Assignment</h2>
              </div>
              
              {selectedDoor.assignedDriverId ? (
                <div className="p-3 border rounded bg-gray-50">
                  <p className="text-sm text-gray-600 mb-1">
                    Driver: {selectedDoor.driverName || "No Driver"}
                  </p>
                  {selectedDoor.trailerNumber && (
                    <p className="text-sm text-gray-600 mb-1">
                      Trailer: {selectedDoor.trailerNumber}
                    </p>
                  )}
                  {selectedDoor.shipmentNumber && (
                    <p className="text-sm text-gray-600">
                      Shipment: {selectedDoor.shipmentNumber}
                    </p>
                  )}
                  <div className="flex justify-end mt-2">
                    <button 
                      className="text-red-600 hover:text-red-800 text-sm"
                      onClick={() => {
                        // TODO: Implement API call to clear assignment
                        console.log('Clear assignment');
                      }}
                    >
                      <Close fontSize="small" className="mr-1" />
                      End Assignment
                    </button>
                  </div>
                </div>
              ) : (
                <p className="text-sm text-gray-500">No current assignment</p>
              )}
            </div>
            
            <div className="mb-4">
              <div className="flex items-center justify-between mb-2">
                <h2 className="font-bold">Scheduled Times</h2>
                <button
                  className="text-sky-600 text-sm hover:underline"
                  onClick={() => {
                    // TODO: Implement API call to add scheduled time
                    console.log('Add scheduled time');
                  }}
                >
                  + Add Time
                </button>
              </div>
              
              {selectedDoor.scheduledTimes.length === 0 ? (
                <p className="text-sm text-gray-500">No scheduled times</p>
              ) : (
                <div className="space-y-2">
                  {selectedDoor.scheduledTimes.map((time, index) => (
                    <div 
                      key={index} 
                      className="flex items-center justify-between bg-gray-100 p-2 rounded"
                    >
                      <div>
                        <p className="text-sm font-medium">
                          {time.start} - {time.end}
                        </p>
                        <p className="text-xs text-gray-600">{time.shipmentId}</p>
                      </div>
                      <button
                        className="text-red-500 hover:text-red-700"
                        onClick={() => {
                          // TODO: Implement API call to remove scheduled time
                          console.log('Remove scheduled time');
                        }}
                      >
                        <Close fontSize="small" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            <div className="mt-5 pt-3 border-t flex justify-end">
              <button
                className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
                onClick={handleCloseDetailsModal}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* History Modal */}
      {showHistoryModal && selectedHistoryDoor && (
        <Modal
          open={showHistoryModal}
          onClose={handleCloseHistoryModal}
          className="flex justify-center items-center"
        >
          <div className="w-[600px] bg-white border p-5 max-h-[80vh] overflow-y-auto relative">
            <Close
              className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
              onClick={handleCloseHistoryModal}
            />
            <h1 className="text-xl font-bold mb-5">
              Door {selectedHistoryDoor.number} - Historical Activity
            </h1>
            
            <div className="mb-4">
              <ToggleButtonGroup
                value={timeRange}
                exclusive
                onChange={(event, newTimeRange) => {
                  if (newTimeRange !== null) {
                    setTimeRange(newTimeRange);
                  }
                }}
                size="small"
              >
                <ToggleButton value="today">Today</ToggleButton>
                <ToggleButton value="week">Past Week</ToggleButton>
                <ToggleButton value="month">Past Month</ToggleButton>
              </ToggleButtonGroup>
            </div>
            
            <div className="mb-4">
              <h2 className="font-bold mb-2">Activity Log</h2>
              
              {(apiDockHistory[selectedHistoryDoor.id] || []).length === 0 ? (
                <p className="text-sm text-gray-500">No historical activity for this door</p>
              ) : (
                <div className="space-y-3">
                  {(apiDockHistory[selectedHistoryDoor.id] || []).map((entry, index) => (
                    <div 
                      key={index} 
                      className={`p-3 border rounded ${
                        entry.activity === 'load' ? 'bg-orange-50 border-orange-200' :
                        entry.activity === 'unload' ? 'bg-blue-50 border-blue-200' :
                        'bg-purple-50 border-purple-200'
                      }`}
                    >
                      <div className="flex justify-between">
                        <span className="font-bold">{entry.driverName}</span>
                        <span className={`text-xs px-2 py-1 rounded ${
                          entry.activity === 'load' ? 'bg-orange-100 text-orange-800' :
                          entry.activity === 'unload' ? 'bg-blue-100 text-blue-800' :
                          'bg-purple-100 text-purple-800'
                        }`}>
                          {entry.activity.charAt(0).toUpperCase() + entry.activity.slice(1)}
                        </span>
                      </div>
                      
                      <p className="text-sm text-gray-600 mt-1">Shipment: {entry.shipmentId}</p>
                      
                      <div className="flex justify-between text-xs text-gray-500 mt-2">
                        <span>Arrival: {entry.arrivalTime}</span>
                        <span>Departure: {entry.departureTime}</span>
                        <span>Duration: {entry.duration} min</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            <div className="mt-5">
              <h2 className="font-bold mb-2">Statistics for Door {selectedHistoryDoor.number}</h2>
              
              <div className="grid grid-cols-3 gap-4">
                <div className="p-3 bg-gray-50 rounded">
                  <p className="text-sm text-gray-600">Total Operations</p>
                  <p className="text-xl font-bold">{(apiDockHistory[selectedHistoryDoor.id] || []).length}</p>
                </div>
                
                <div className="p-3 bg-gray-50 rounded">
                  <p className="text-sm text-gray-600">Avg. Duration</p>
                  <p className="text-xl font-bold">
                    {(apiDockHistory[selectedHistoryDoor.id] || []).length > 0
                      ? Math.round((apiDockHistory[selectedHistoryDoor.id] || []).reduce((sum, entry) => sum + entry.duration, 0) / 
                          (apiDockHistory[selectedHistoryDoor.id] || []).length)
                      : 0} min
                  </p>
                </div>
                
                <div className="p-3 bg-gray-50 rounded">
                  <p className="text-sm text-gray-600">Utilization</p>
                  <p className="text-xl font-bold">
                    {(apiDockHistory[selectedHistoryDoor.id] || []).length > 0
                      ? Math.round(((apiDockHistory[selectedHistoryDoor.id] || []).reduce((sum, entry) => sum + entry.duration, 0) / 
                          (timeRange === 'today' ? 24 * 60 : timeRange === 'week' ? 7 * 24 * 60 : 30 * 24 * 60)) * 100)
                      : 0}%
                  </p>
                </div>
              </div>
            </div>
            
            <div className="mt-5 pt-3 border-t flex justify-end">
              <button
                className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
                onClick={handleCloseHistoryModal}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DockManagement; 