import { twMerge } from 'tailwind-merge';
import { NavLink } from 'react-router-dom';
import { LocalShipping, Person, ManageAccounts, Warehouse, DoorSliding, LocalParking, Forum, Dashboard } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import logo from '../../assets/freight-roll-logo.png';

interface SidePanelProps {
  showing: boolean;
  showSlotlessDrivers: boolean;
  showUserManagement: boolean;
  canViewFacilities: boolean;
}

const SidePanel = ({
  showing,
  showSlotlessDrivers,
  showUserManagement,
  canViewFacilities
}: SidePanelProps) => {
  return (
    <div
      className={twMerge(
        'bg-[#323D48] text-white text-sm flex flex-col w-48 fixed h-screen',
        !showing ? 'hidden' : ''
      )}
    >
      <Link to="/shipments">
        <img src={logo} alt="FreightRoll Logo" className="mr-5" height="70" />
      </Link>
      <h2 className="text-[#979ba7] pb-1 p-5 w-[198px]">Dashboard</h2>
      <NavLink
        to="/shipments"
        className={({ isActive }) =>
          twMerge(
            'px-5 py-3 hover:bg-[#222A33] border-[#2dc3e8] border-l-4 hover:border-[#fb6e52] flex items-center',
            isActive ? 'bg-[#222A33] border-[#fb6e52]' : ''
          )
        }
      >
        <LocalShipping className="mr-1 !h-5 transform -scale-x-100" />
        Shipments
      </NavLink>

      {!!showSlotlessDrivers && (
        <NavLink
          to="/drivers/unassigned_slot"
          className={({ isActive }) =>
            twMerge(
              'px-5 py-3 hover:bg-[#222A33] border-[#2dc3e8] border-l-4 hover:border-[#fb6e52] flex items-center',
              isActive ? 'bg-[#222A33] border-[#fb6e52]' : ''
            )
          }
        >
          <Person className="mr-1 !h-5" />
          Driver Mgt
        </NavLink>
      )}
      
      {!!showSlotlessDrivers && (
        <NavLink
          to="/drivers/comms"
          className={({ isActive }) =>
            twMerge(
              'px-5 py-3 hover:bg-[#222A33] border-[#2dc3e8] border-l-4 hover:border-[#fb6e52] flex items-center',
              isActive ? 'bg-[#222A33] border-[#fb6e52]' : ''
            )
          }
        >
          <Forum className="mr-1 !h-5" />
          Driver Comms
        </NavLink>
      )}
      
      {!!showSlotlessDrivers && (
        <NavLink
          to="/dock"
          className={({ isActive }) =>
            twMerge(
              'px-5 py-3 hover:bg-[#222A33] border-[#2dc3e8] border-l-4 hover:border-[#fb6e52] flex items-center',
              isActive ? 'bg-[#222A33] border-[#fb6e52]' : ''
            )
          }
        >
          <DoorSliding className="mr-1 !h-5" />
          Dock Mgt
        </NavLink>
      )}
      
      {!!showSlotlessDrivers && (
        <NavLink
          to="/yard/spots"
          className={({ isActive }) =>
            twMerge(
              'px-5 py-3 hover:bg-[#222A33] border-[#2dc3e8] border-l-4 hover:border-[#fb6e52] flex items-center',
              isActive ? 'bg-[#222A33] border-[#fb6e52]' : ''
            )
          }
        >
          <LocalParking className="mr-1 !h-5" />
          Yard Spot Mgt
        </NavLink>
      )}
      
      {!!showSlotlessDrivers && (
        <NavLink
          to="/whiteboard"
          className={({ isActive }) =>
            twMerge(
              'px-5 py-3 hover:bg-[#222A33] border-[#2dc3e8] border-l-4 hover:border-[#fb6e52] flex items-center',
              isActive ? 'bg-[#222A33] border-[#fb6e52]' : ''
            )
          }
        >
          <Dashboard className="mr-1 !h-5" />
          White Board
        </NavLink>
      )}
      
      <div className="mt-auto">
        {!!canViewFacilities && (
          <NavLink
            to="/facilities"
            className={({ isActive }) =>
              twMerge(
                'px-5 py-3 hover:bg-[#222A33] border-[#2dc3e8] border-l-4 hover:border-[#fb6e52] flex items-center',
                isActive ? 'bg-[#222A33] border-[#fb6e52]' : ''
              )
            }
          >
            <Warehouse className="mr-1 !h-5" />
            Facility Mgt
          </NavLink>
        )}
        {!!showUserManagement && (
          <NavLink
            to="/users"
            className={({ isActive }) =>
              twMerge(
                'px-5 py-3 hover:bg-[#222A33] border-[#2dc3e8] border-l-4 hover:border-[#fb6e52] flex items-center',
                isActive ? 'bg-[#222A33] border-[#fb6e52]' : ''
              )
            }
          >
            <ManageAccounts className="mr-1 !h-5" />
            User Mgt
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default SidePanel;
