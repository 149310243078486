import axios from 'axios';
import { useState, useEffect } from 'react';

// Driver type definition based on the real API data
export interface ApiShipment {
  driver_name: string;
  puid: string;
  id: string;
  driver_phone_number: string;
  check_in_time: string;
  truck_number: string;
  trailer_number: string;
  metadata?: {
    shipment_type?: string;
  };
  data?: {
    seal_number?: string;
    weight?: string;
  };
  bol_id?: string;
  facility_of_origin_id: number;
  direction?: 'inbound' | 'outbound';
}

// First, we need to augment the driver type to include bol_id
// Add this interface or modify the existing one
interface ExtendedDriver {
  id: string;
  name: string;
  // ... other driver properties
  bol_id?: string; // Add bol_id property
}

// Convert API shipment data to our Driver interface format
export const mapApiShipmentToDriver = (shipment: ApiShipment) => {
  // Use direction property if available, otherwise fallback to old logic
  const driverType = shipment.direction || 
    (shipment.metadata?.shipment_type === 'D' ? 'inbound' : 'outbound');
  
  // Calculate time elapsed since check-in
  const checkInDate = new Date(shipment.check_in_time);
  const now = new Date();
  const elapsedMs = now.getTime() - checkInDate.getTime();
  const elapsedMinutes = Math.floor(elapsedMs / (1000 * 60));
  
  // Format arrival time for display
  const arrivalTime = `${checkInDate.getHours()}:${checkInDate.getMinutes().toString().padStart(2, '0')} ${checkInDate.getHours() >= 12 ? 'PM' : 'AM'}`;
  
  return {
    id: shipment.id,
    name: shipment.driver_name || 'Unknown Driver',
    company: 'N/A', // Not available in API data
    type: driverType as any,
    arrivalTime,
    elapsedMinutes,
    contact: shipment.driver_phone_number,
    trailerNumber: shipment.trailer_number,
    truckNumber: shipment.truck_number,
    loadNumber: shipment.puid,
    sealNumber: shipment.data?.seal_number,
    weight: shipment.data?.weight,
    assignedSlot: null,
    facilityId: shipment.facility_of_origin_id,
    bol_id: shipment.bol_id,
    shipment_id: shipment.id  // Add shipment_id (using the shipment's ID as the shipment_id)
  };
};

// Custom hook to fetch real driver data
export const useDriverData = (facilityId?: number) => {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState<ReturnType<typeof mapApiShipmentToDriver>[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [facilities, setFacilities] = useState<{ id: number; name: string }[]>([]);
  const [selectedFacility, setSelectedFacility] = useState<{ id: number; name: string } | null>(null);
  const [timeInterval, setTimeInterval] = useState(12); // Default 12 hours

  // Fetch facilities
  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const READ_API_URL = process.env.REACT_APP_READ_API_URL;
        const response = await axios.get(`${READ_API_URL}/api/v1/admin/facilities`);
        setFacilities(response.data);
        
        // Set default facility if one was provided or use the first one
        if (facilityId) {
          const facility = response.data.find((f: any) => f.id === facilityId);
          if (facility) setSelectedFacility(facility);
        } else if (response.data.length > 0) {
          setSelectedFacility(response.data[0]);
        }
      } catch (err) {
        setError('Failed to fetch facilities');
        console.error(err);
      }
    };
    
    fetchFacilities();
  }, [facilityId]);
  

  // Fetch drivers (shipments) when facility changes
  useEffect(() => {
    if (!selectedFacility) return;
    
    const fetchDrivers = async () => {
      setLoading(true);
      setError(null);
      
      try {
        const READ_API_URL = process.env.REACT_APP_READ_API_URL;
        const response = await axios.get(
          `${READ_API_URL}/api/v1/admin/drivers/unassigned_slots?facility_id=${selectedFacility.id}&da_interval=${timeInterval}`
        );
        
        // Map API data to our driver format
        const mappedDrivers = response.data.map(mapApiShipmentToDriver);
        setDrivers(mappedDrivers);
      } catch (err) {
        setError('Failed to fetch drivers');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    
    // Fetch immediately and then every 30 seconds
    fetchDrivers();
    // const intervalId = setInterval(fetchDrivers, 30000);
    
    // return () => clearInterval(intervalId);
  }, [selectedFacility, timeInterval]);

  return {
    loading,
    drivers,
    error,
    facilities,
    selectedFacility,
    setSelectedFacility,
    timeInterval,
    setTimeInterval
  };
};

// Remove any incorrect handleAssignment code from this file
// The handle assignment function should ONLY exist in UnassignedSlotDriversWithRealData.tsx 