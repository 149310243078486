import {CircularProgress, List, ListItem, ListItemText} from '@mui/material';
import {useState, useEffect} from 'react';
import {FacilityType} from '../../types/Facility';
import {Modal} from '@mui/material';
import {Close, Check} from '@mui/icons-material';
import Select from 'react-select';
import {twMerge} from 'tailwind-merge';
import gate1 from '../../assets/gate1.png';
import gate2 from '../../assets/gate2.png';
import YardManagement from '../YardManagement/YardManagement';


const Facility = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [facility, setFacility] = useState<FacilityType | null>(null);
    const [showGateModal, setShowGateModal] = useState(false);
    const [showGateSuccess, setShowGateSuccess] = useState(false);
    const [showPinModal, setShowPinModal] = useState(false);
    const [gate, setGate] = useState('');
    const [pin, setPin] = useState('');
    //   const [showUpdateModal, setShowUpdateModal] = useState(false);

    useEffect(() => {
        const fetchFacility = async () => {
            setLoading(true);
            try {
                setFacility({
                    id: '123',
                    name: 'Aurora',
                    address: '330 E. Liberty, Ann Arbor, MI 48104',
                    gate_count: 2
                });
            } catch (error) {
                setError('An error occurred while fetching the facility');
            }
            setLoading(false);
        };
        fetchFacility();
    }, []);

    const handleGateModalClose = () => {
        setShowGateModal(false);
    };

    const handleOpenGate = () => {
        setShowGateSuccess(true);
        setTimeout(() => {
            setShowGateSuccess(false);
            setGate('');
            setShowGateModal(false);
        }, 2000);
    };

    const handlePinModalClose = () => {
        setShowPinModal(false);
        setPin('');
        setGate('');
    };

    const handleGeneratePin = () => {
        setPin(Math.floor(1000 + Math.random() * 9000).toString());
    };

    //   const handleUpdateModalClose = () => {
    //     setShowUpdateModal(false);
    //   };

    const gates = [
        {
            value: 1,
            label: 'Aurora #1'
        },
        {
            value: 2,
            label: 'Aurora #2'
        }
    ];

    if (loading) {
        return (
            <div className="flex flex-col items-center mt-4 w-full">
                <CircularProgress/>
                <p className="mt-2">Loading...</p>
            </div>
        );
    }

    if (!facility) {
        return <div>Facility not found</div>;
    }

    const workflow = (name: string, steps: {  label: string; type: string, fork:{forkLabel:string, forkColor:string, forkBackgroundColor:string}[] }[]) => {

        const title = (<h3>{name}</h3>)

        const node_steps = [];

        for (let i = 0; i < steps.length; i++) {
            let node;
            let backgroundColor = '#fff'
            let color = '#000'
            if (i===0){
                backgroundColor ='#000';
                color = '#fff';
            }else if(i===steps.length-1){
                // backgroundColor = '#006400';
                // color = '#fff'
            }

            switch (steps[i].type) {
                case 'entry':
                    backgroundColor = '#fff';
                    color = '#000'
                    break;
                // case 'verification_component':
                //     backgroundColor = 'lightblue';
                //     color = 'black'
                //     break;
                // case 'driver_auth_component':
                //     backgroundColor = 'green';
                //     color = 'white'
                //     break;
                // case 'form':
                //     backgroundColor = 'seagreen';
                //     color = 'white'
                //     break;
                // case 'logic':
                //     backgroundColor = 'black';
                //     color = 'white'
                //     break;
                 case 'black':
                  backgroundColor = 'black';
                     color = 'white'
                     break;
                 case 'gray':
                     backgroundColor = 'gray';
                     color = 'white'
                     break;
                default:
                    backgroundColor = 'darkgreen';
                    color = 'white'
                    break;
            }
            if (steps[i].fork.length > 0) {
                let fork_nodes = []


                for (let j = 0; j < steps[i].fork.length; j++) {
                    let forkLabelElement: JSX.Element;
                    const forkLabel = steps[i].fork[j].forkLabel;
                    if (forkLabel.includes("@")) {
                        const [labelPart, listPart] = forkLabel.split("@");
                        let items = listPart.split("~");
                        // kill first item
                        items.reverse().pop()
                        items.reverse()
                        forkLabelElement = (
                            <>
                                <span>{labelPart}</span>
                                <List sx={{
                                    listStyleType: 'disc',
                                    pl: 4,
                                    '& .MuiListItem-root': {
                                        display: 'list-item'
                                    }
                                }}>
                                    {items.map((item:any
                                        , index:any) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={item} />
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        );
                    } else {
                        forkLabelElement = <span>{forkLabel}</span>;
                    }


                    fork_nodes.push(<div style={{width: '2px', height: '20px', background: '#666', margin: '4px 0'}}/>)
                    fork_nodes.push(
                        <div
                            style={{
                                        backgroundColor: steps[i].fork[j].forkBackgroundColor,
                                        border: '1px solid ' + steps[i].fork[j].forkColor,
                                        padding: '8px',
                                        borderRadius: '4px',
                                        color: steps[i].fork[j].forkColor
                                    }}
                                >
                                    {forkLabelElement}
                                </div>
                            )

            }
                node = (<div
                        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div
                            style={{
                                backgroundColor: backgroundColor,
                                color: color,
                                border: '1px solid #888',
                                padding: '8px',
                                borderRadius: '4px',
                                fontFamily: 'Arial, sans-serif',
                            }}
                        >
                            {steps[i].label}
                        </div>{fork_nodes}</div>)

            }else {
                node = <div
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div
                        style={{
                            backgroundColor: backgroundColor,
                            color: color,
                            border: '1px solid #888',
                            padding: '8px',
                            borderRadius: '4px',
                            fontFamily: 'Arial, sans-serif',
                        }}
                    >
                        {steps[i].label}
                    </div></div>
                    }
                    node_steps.push(node);
                    // if last step don't add spacer
                    if (i < steps.length - 1) {
                node_steps.push(<div style={{width: '40px', height: '2px', background: '#666', margin: '2% 8px'}}/>)
            }
        }


        const template = (<div>
            {title}
            <div style={{
                display: 'flex',
                border: '1px solid #888',
                padding: '12px',
                paddingBottom: '62px'
            }}>
                {node_steps}
                <span
                    className="text-sky-600 float-right hover:underline cursor-pointer"
                    style={{padding: '50px'}}
                >
      Edit
    </span>
            </div>
        </div>)
        return template;

    }

    return (
        <div className="p-5">
            <div className="shadow bg-[#FBFBFB] p-3">
                <div className="flex">
                    <h1 className="font-bold text-xl mb-3">Facility Info:</h1>
                    <span className=" text-sky-600 ml-auto hover:underline cursor-pointer">
            Edit
          </span>
                </div>
                <p>
                    <span className="font-bold">Name: </span>
                    {facility.name}
                </p>
                <p>
                    <span className="font-bold">IDs: </span>
                    {facility.id}, KHCAU
                </p>
                <p>
                    <span className="font-bold">Address: </span>
                    {facility.address}
                </p>
                <p>
                    <span className="font-bold"># of Gates: </span>
                    {facility.gate_count}
                </p>
                <p>
                    <span className="font-bold">Open Gate Hours: </span>
                    0800-1700 CST
                </p>
            </div>
            <div>
                <h1 className="font-bold text-lg mt-5 mb-3">Actions:</h1>
                <div className="flex gap-3">
                    <button
                        className="bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded"
                        onClick={() => setShowGateModal(true)}
                    >
                        Open Gate
                    </button>
                    <button
                        className="bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded"
                        onClick={() => setShowPinModal(true)}
                    >
                        Generate Gate PIN
                    </button>
                    {/* <button
            className="bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded"
            onClick={() => setShowUpdateModal(true)}
          >
            Update Facility
          </button> */}
                </div>
            </div>
            <div className="flex gap-5">
                <div className="w-1/2">
                    <h1 className="font-bold text-lg mt-5 mb-3">On-site Cameras</h1>
                    <div className="flex gap-5">
                        <div className="bg-white p-4 min-h-52">
                            <h1>Camera #1</h1>
                            <img src={gate1} alt="gate1" className="w-full"/>
                        </div>
                        <div className="bg-white p-4 min-h-52">
                            <h1>Camera #2</h1>
                            <img src={gate2} alt="gate2" className="w-full"/>
                        </div>
                    </div>
                </div>
                <div className="w-1/2">
                    <h1 className="font-bold text-lg mt-5 mb-3">On-site Gates</h1>
                    <div className="flex gap-5">
                        <div className="bg-white p-4 min-h-40 w-1/2">
                            <h1>Gate #1</h1>
                            <p>Status: Closed</p>
                        </div>
                        <div className="bg-white p-4 min-h-40 w-1/2">
                            <h1>Gate #2</h1>
                            <p>Status: Closed</p>
                        </div>
                    </div>
                </div>
            </div>

            <br/>



            <div className="mb-5">  
                <h1 className="font-bold text-lg mt-5 mb-3">Yard Management</h1>
                <YardManagement />
            </div>
            <div className="mb-5">
                <h1 className="font-bold text-lg mt-5 mb-3">Workflows</h1>
                <div className="">
                    <button
                        className="bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded"
                        onClick={() => {

                        }
                    }
                    >
                        Add Workflow
                    </button>

                    {/* <button
            className="bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded"
            onClick={() => setShowUpdateModal(true)}
          >
            Update Facility
          </button> */}
                </div>
            </div>


            {workflow('checkin_base_aurora123', [
                    {label: 'QR Code Scan / Kiosk', type: 'entry', fork: []},
                    {
                        label: 'Hours of Operation Rules Component', type: 'fork', fork: [{
                            forkLabel: 'After Hours Text:\n' +
                                '“We are closed. Please return later.”', forkColor: '#000', forkBackgroundColor: '#fff'
                        }]
                    },

                    {
                        label: 'Driver Auth Component', type: 'driver_auth_component', fork: [{
                            forkLabel: 'FR Auth Rules: @\n' +
                                '~Skip if user recognized', forkColor: '#000', forkBackgroundColor: '#fff'
                        },
                            {forkLabel: 'Error - Retry', forkColor: '#f00', forkBackgroundColor: '#fff'}
                        ]
                    },

                    {
                        label: 'Reference # Verification Component', type: 'verification_component', fork: [{
                            forkLabel: 'FR Auth Rules:@ \n' +
                                '~Skip if pre-checked in', forkColor: '#000', forkBackgroundColor: '#fff'
                        },
                            {
                                forkLabel: 'Input(s): @\n' +
                                    '~BOL # (validation_– Appt Integration)\n' +
                                    '~Non-reference # exception (validation – NA)',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Text Box Entry Hint(s): \n' +
                                    '“208”', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Page Text:\n' +
                                    '“Enter Load #: Please enter your load number below. Possible Load # Format(s)…”',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },
                            {forkLabel: 'Error - Retry', forkColor: '#f00', forkBackgroundColor: '#fff'},
                        ]
                    },

                    {
                        label: 'Shipment Info Verification Component', type: 'verification_component', fork: [{
                            forkLabel: 'Input(s): @\n' +
                                '~full_name (validation_ field – NA)\n' +
                                '~driver_phone (validation – NA)\n' +
                                '~truck_number (validation – machine vision)\n' +
                                '~trailer_number (validation – machine vision)\n' +
                                '~drop_n_hook (validation – NA)', forkColor: '#000', forkBackgroundColor: '#fff'
                        },
                            {
                                forkLabel: 'Text Box Entry Hint(s): \n' +
                                    'NA', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Page Text:\n' +
                                    '“Confirmation\n' +
                                    'Please confirm the information below is correct. If not, please adjust.”',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },

                        ]
                    },


                    {
                        label: 'Form Acknowledgement Component', type: 'form', fork: [{
                            forkLabel: 'Text: \n' +
                                '“Safety First- Yard/Warehouse\n' +
                                'In inclement weather, ICE CLEATS may be required at some facilities\n' +
                                'Obey all posted plant specific rules and policies\n' +
                                '….”', forkColor: '#000', forkBackgroundColor: '#fff'
                        },

                        ]
                    },

                    {
                        label: 'Shipment Scenario Logic Workflow Fork', type: 'black', fork: [{
                            forkLabel: 'Direction: @\n' +
                                '~Inbound\n' +
                                '~Outbound\n' +
                                '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                        },
                            {
                                forkLabel: 'Trailer Type: @\n' +
                                    '~Dry Van\n' +
                                    '~Reefer\n' +
                                    '~Flatbed\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Exception: @\n' +
                                    '~Dropping Empty (no pickup/ delivery)\n' +
                                    '~Shuttle\n' +
                                    '~Other Visitor', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'check-in_\n' +
                                    'outbound_base', forkColor: '#fff', forkBackgroundColor: '#000'
                            },
                            {
                                forkLabel: 'check-in_\n' +
                                    'inbound_base', forkColor: '#fff', forkBackgroundColor: '#000'
                            },
                            {
                                forkLabel: 'check-in_\n' +
                                    'exception', forkColor: '#fff', forkBackgroundColor: '#000'
                            },
                        ]
                    },

                ]
            )}


            {workflow('checkin_outbound_base_aurora123', [
                    {
                        label: 'checkin_\n' +
                            'base', type: 'black', fork: []
                    },
                    {
                        label: 'Driver’s License Image Validation Component',
                        type: 'fork',
                        fork: [{
                            forkLabel: 'Error – Indication on FR Web Admin',
                            forkColor: '#f00',
                            forkBackgroundColor: '#fff'
                        }]
                    },

                    {
                        label: 'Shipment Scenario Logic Message Fork', type: 'logic', fork: [

                            {
                                forkLabel: 'Direction: @\n' +
                                    '~Inbound\n' +
                                    '~Outbound\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Scenario: @\n' +
                                    '~Live\n' +
                                    '~Drop\n' +
                                    '~Drop & Hook\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Trailer Type: @\n' +
                                    '~Dry Van\n' +
                                    '~Reefer\n' +
                                    '~Flatbed\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Ship From/To: @\n' +
                                    '~Internal\n' +
                                    '~Customer\n' +
                                    '~Supplier\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {forkLabel: 'Success / Error', forkColor: '#000', forkBackgroundColor: '#fff'},

                        ]
                    },

                    {
                        label: 'Success / Error Screen / Text Message', type: 'logic', fork: [
                            {
                                forkLabel: 'Static Text: \n' +
                                    '“Check-In Complete for Shipment: XXXXXXX… ',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Dynamic Text (based on shipment scenario): \n' +
                                    '“You will receive information about where to… ”',
                                forkColor: 'white',
                                forkBackgroundColor: 'gray'
                            },

                            {
                                forkLabel: 'Gate Entry Info:\n' +
                                    'QR Code / PIN', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'driver_\n' +
                                    'orchestration_\n' +
                                    'base', forkColor: '#fff', forkBackgroundColor: '#000'
                            },

                            {forkLabel: 'Error – “Please see DC…”', forkColor: '#f00', forkBackgroundColor: '#fff'},

                        ]
                    },


                ]
            )}

            {workflow('checkin_inbound_base_aurora123', [
                    {
                        label: 'checkin_\n' +
                            'base', type: 'black', fork: []
                    },
                    {
                        label: 'Driver’s License Image Validation Component',
                        type: 'fork',
                        fork: [{
                            forkLabel: 'Error – Indication on FR Web Admin',
                            forkColor: '#f00',
                            forkBackgroundColor: '#fff'
                        }]
                    },

                    {
                        label: 'Chain of Custody Image Validation Component', type: 'custody_component', fork: [

                            {
                                forkLabel: 'Image Capture: \n' +
                                    'Seal # on BOL', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Data Capture: \n' +
                                    'Seal # from BOL', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Validation: \n' +
                                    'NA', forkColor: '#000', forkBackgroundColor: '#fff'
                            },


                        ]
                    },


                    {
                        label: 'Chain of Custody Image Validation Component', type: 'custody_component', fork: [

                            {
                                forkLabel: 'Image Capture: \n' +
                                    'Physical Seal', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Data Capture: \n' +
                                    'Seal # from physical seal', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Validation: \n' +
                                    'Matches seal on BOL', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Error – Indication on FR Web Admin',
                                forkColor: '#f00',
                                forkBackgroundColor: '#fff'
                            },


                        ]
                    },

                    {
                        label: 'BOL Document  Capture Component', type: 'bol_capture_component', fork: [

                            {
                                forkLabel: 'Upload physical document via kiosk / DC',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },

                            {forkLabel: 'Error – “Please see DC…”', forkColor: '#f00', forkBackgroundColor: '#fff'},


                        ]
                    },

                    {
                        label: 'Shipment Scenario Logic Workflow Fork', type: 'logic', fork: [
                            {
                                forkLabel: 'Trailer Type: @\n' +
                                    '~Dry Van\n' +
                                    '~Reefer\n' +
                                    '~Flatbed\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'check_in_\n' +
                                    'inbound_reefer', forkColor: '#fff', forkBackgroundColor: '#000'
                            },


                        ]
                    },

                    {
                        label: 'Shipment Scenario Logic Message Fork', type: 'gray', fork: [
                            {
                                forkLabel: 'Direction: @\n' +
                                    '~Inbound\n' +
                                    '~Outbound\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Scenario: @\n' +
                                    '~Live, \n' +
                                    '~Drop, \n' +
                                    '~Drop & Hook,\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Trailer Type: @\n' +
                                    '~Dry Van\n' +
                                    '~Reefer\n' +
                                    '~Flatbed\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: ' Ship From/To:@\n' +
                                    '                ~Internal\n' +
                                    '                ~Customer\n' +
                                    '                ~Supplier\n' +
                                    '                ~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },


                        ]
                    },

                    {
                        label: 'Success Screen / Text Message', type: 'black', fork: [
                            {
                                forkLabel: 'Static Text: \n' +
                                    '“Check-In Complete for Shipment: XXXXXXX… ',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Dynamic Text (based on shipment scenario): \n' +
                                    '“You will receive information about where to… ”',
                                forkColor: 'white',
                                forkBackgroundColor: 'gray'
                            },

                            {
                                forkLabel: 'Gate Entry Info:\n' +
                                    'QR Code / PIN', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'driver_\n' +
                                    'orchestration_\n' +
                                    'base', forkColor: '#fff', forkBackgroundColor: '#000'
                            },

                            {forkLabel: 'Error – “Please see DC…”', forkColor: '#f00', forkBackgroundColor: '#fff'},

                        ]
                    },


                ]
            )}


            {workflow('checkin_inbound_reefer_aurora123\n', [
                    {
                        label: 'checkin_inbound_\n' +
                            'base', type: 'black', fork: []
                    },

                    {
                        label: 'Chain of Custody Image Validation Component', type: 'custody_component', fork: [

                            {
                                forkLabel: 'Image Capture: \n' +
                                    'Reefer temp', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Data Capture: \n' +
                                    'Reefer temp', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Validation: \n' +
                                    'Facility defined tolerance', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Error – Indication on FR Web Admin',
                                forkColor: '#f00',
                                forkBackgroundColor: '#fff'
                            },

                        ]
                    },


                    {
                        label: 'Chain of Custody Image Validation Component', type: 'custody_component', fork: [

                            {
                                forkLabel: 'Image Capture: \n' +
                                    'Reefer fuel gauge', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Data Capture: \n' +
                                    'Reefer fuel gauge %', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Validation: \n' +
                                    'Facility defined tolerance', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Error – Indication on FR Web Admin',
                                forkColor: '#f00',
                                forkBackgroundColor: '#fff'
                            },


                        ]
                    },


                    {
                        label: 'Shipment Scenario Logic Message Fork', type: 'logic', fork: [
                            {
                                forkLabel: 'Direction: @\n' +
                                    '~Inbound\n' +
                                    '~Outbound\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Scenario: @\n' +
                                    '~Live, \n' +
                                    '~Drop, \n' +
                                    '~Drop & Hook,\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Trailer Type: @\n' +
                                    '~Dry Van\n' +
                                    '~Reefer\n' +
                                    '~Flatbed\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: ' Ship From/To:\n@' +
                                    '                ~Internal\n' +
                                    '                ~Customer\n' +
                                    '                ~Supplier\n' +
                                    '                ~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },


                        ]
                    },

                    {
                        label: 'Success Screen / Text Message', type: 'logic', fork: [
                            {
                                forkLabel: 'Static Text: \n' +
                                    '“Check-In Complete for Shipment: XXXXXXX… ',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Dynamic Text (based on shipment scenario): \n' +
                                    '“You will receive information about where to… ”',
                                forkColor: 'white',
                                forkBackgroundColor: 'gray'
                            },

                            {
                                forkLabel: 'Gate Entry Info:\n' +
                                    'QR Code / PIN', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'driver_\n' +
                                    'orchestration_\n' +
                                    'base', forkColor: '#fff', forkBackgroundColor: '#000'
                            },

                            {forkLabel: 'Error – “Please see DC…”', forkColor: '#f00', forkBackgroundColor: '#fff'},

                        ]
                    },


                ]
            )}


            {workflow('checkin_exception_aurora123\n', [
                    {
                        label: 'checkin_inbound_\n' +
                            'base', type: 'black', fork: []
                    },


                    {
                        label: 'Shipment Scenario Logic Message Fork', type: 'gray', fork: [
                            {
                                forkLabel: 'Exception: @\n' +
                                    '~Dropping Empty (no pickup/ delivery)\n' +
                                    '~Shuttle\n' +
                                    '~Other Visitor', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {forkLabel: 'Success / Error', forkColor: '#000', forkBackgroundColor: '#fff'},


                        ]
                    },

                    {
                        label: 'Success Screen / Text Message', type: 'logic', fork: [
                            {
                                forkLabel: 'Static Text: \n' +
                                    '“Check-In Complete for Shipment: XXXXXXX… ',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Dynamic Text (based on shipment scenario): \n' +
                                    '“You will receive information about where to… ”',
                                forkColor: 'white',
                                forkBackgroundColor: 'gray'
                            },

                            {
                                forkLabel: 'Gate Entry Info:\n' +
                                    'QR Code / PIN', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'driver_\n' +
                                    'orchestration_\n' +
                                    'base', forkColor: '#fff', forkBackgroundColor: '#000'
                            },

                            {forkLabel: 'Error – “Please see DC…”', forkColor: '#f00', forkBackgroundColor: '#fff'},

                        ]
                    },


                ]
            )}


            {workflow('chainofcustody_outbound_aurora123', [
                    {label: 'Kiosk / (BOL Sign Link)', type: 'entry', fork: []},


                    {
                        label: 'Reference # Verification Component', type: 'verification_component', fork: [{
                            forkLabel: 'Input(s): @\n' +
                                '~Seal # (validation_– BOL Integration)\n' +
                                '~Skip if user + shipment recognized on their device within 3 hours of check-in',
                            forkColor: '#000',
                            forkBackgroundColor: '#fff'
                        },
                            {
                                forkLabel: 'Text Box Entry Hint(s): \n' +
                                    'NA', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Page Text:\n' +
                                    '“Enter Seal Number\n' +
                                    'Please enter your seal number below.”', forkColor: '#000', forkBackgroundColor: '#fff'
                            },


                            {forkLabel: 'Error - Retry', forkColor: '#f00', forkBackgroundColor: '#fff'},
                        ]
                    },

                    {
                        label: 'Driver Auth Component', type: 'driver_auth_component', fork: [{
                            forkLabel: 'FR Auth Rules:@ \n' +
                                '~Skip if pre-checked in within the last 3 hours on kiosk\n' +
                                '~Skip if user + shipment recognized on their device within 3 hours of check-in',
                            forkColor: '#000',
                            forkBackgroundColor: '#fff'
                        },
                            {forkLabel: 'Error - Retry', forkColor: '#f00', forkBackgroundColor: '#fff'}
                        ]
                    },


                    {
                        label: 'Shipment Info Verification Component', type: 'verification_component', fork: [{
                            forkLabel: 'Input(s): \n@' +
                                '~full_name (validation_ field – NA…prefill)\n' +
                                '~truck_number (validation – NA …prefill)', forkColor: '#000', forkBackgroundColor: '#fff'
                        },
                            {
                                forkLabel: 'Text Box Entry Hint(s): \n' +
                                    'NA', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Page Text:\n' +
                                    '“Confirmation\n' +
                                    'Please confirm the information below is correct. If not, please adjust.”',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },

                        ]
                    },


                    {
                        label: 'Chain of Custody Capture Component', type: 'custody_component', fork: [

                            {
                                forkLabel: 'Shipment Text Display: @\n' +
                                    '~Weight\n' +
                                    '~Ship To\n' +
                                    '~Trailer #\n' +
                                    '~Seal #', forkColor: '#000', forkBackgroundColor: '#fff'
                            },


                            {
                                forkLabel: 'Error – “Please see DC…” – Manual Override',
                                forkColor: '#f00',
                                forkBackgroundColor: '#fff'
                            }


                        ]
                    },

                    {
                        label: 'Outbound BOL Print Component', type: 'bol_print_component', fork: [

                            {
                                forkLabel: 'Inputs: \n@' +
                                    '~Printer IP Address (initialized on kiosk)\n' +
                                    '~# of copies to be printed (pre-configured or dynamic based on ship to / carrier)',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },


                            {
                                forkLabel: 'Error – “Please see DC…” – Manual Override',
                                forkColor: '#f00',
                                forkBackgroundColor: '#fff'
                            }


                        ]
                    },

                    {
                        label: 'Success Screen', type: 'logic', fork: [

                            {
                                forkLabel: 'Static Text: \n' +
                                    '“You are successfully checked out!\n' +
                                    '\n' +
                                    'You will receive a text with a link to your digital BOL.…”',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'driver_\n' +
                                    'orchestration_\n' +
                                    'base', forkColor: '#fff', forkBackgroundColor: '#000'
                            },

                            {
                                forkLabel: 'Success confirmation link to BOL / Gate Exit Information:\n@' +
                                    '~QR Code / PIN\n' +
                                    '~Link to BOL ', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                        ]
                    },

                ]
            )}


            {workflow('chainofcustody_intbound_aurora123', [
                    {label: 'FR Web Admin', type: 'entry', fork: []},


                    {
                        label: 'FR Admin Signature Capture Component', type: 'admin_component', fork: [{
                            forkLabel: 'Input(s): @\n' +
                                '~OS&D (validation_– NA)\n' +
                                '~Subject to Count (validation – NA)', forkColor: '#000', forkBackgroundColor: '#fff'
                        },
                        ]
                    },


                    {
                        label: 'Success Screen', type: 'logic', fork: [

                            {
                                forkLabel: 'Static Text: \n' +
                                    '“You are successfully checked out!\n' +
                                    '\n' +
                                    'You will receive a text with a link to your digital BOL.…”',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'driver_\n' +
                                    'orchestration_\n' +
                                    'base', forkColor: '#fff', forkBackgroundColor: '#000'
                            },

                            {
                                forkLabel: 'Success confirmation link to BOL / Gate Exit Information:\n' +
                                    'QR Code / PIN\n' +
                                    'Link to BOL ', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                        ]
                    },

                ]
            )}


            {workflow('checkout_base_aurora123', [
                    {label: 'QR Code Scan / Kiosk', type: 'entry', fork: []},


                    {
                        label: 'Driver Auth Component', type: 'driver_auth_component', fork: [
                            {
                                forkLabel: 'FR Auth Rules: @\n' +
                                    '~Skip if user recognized', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {forkLabel: 'Error - Retry', forkColor: '#f00', forkBackgroundColor: '#fff'}
                        ]
                    },

                    {
                        label: 'Reference # Verification Component', type: 'verification_component', fork: [

                            {
                                forkLabel: 'FR Auth Rules: @\n' +
                                    '~Skip if checked in', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Input(s): @\n' +
                                    '~BOL # (validation_– Appt Integration)\n' +
                                    '~Non-reference # exception (validation – NA)',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Text Box Entry Hint(s): \n' +
                                    '“208”', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Page Text:\n' +
                                    '“Enter Load #: Please enter your load number below. Possible Load # Format(s)…”',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },
                            {forkLabel: 'Error - Retry', forkColor: '#f00', forkBackgroundColor: '#fff'},
                        ]
                    },

                    {
                        label: 'Shipment Info Verification Component', type: 'verification_component', fork: [
                            {
                                forkLabel: 'FR Auth Rules: @\n' +
                                    '~Skip if info already known', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Input(s): @\n' +
                                    '~full_name (validation_ field – NA)\n' +
                                    '~driver_phone (validation – NA)\n' +
                                    '~truck_number (validation – machine vision)\n' +
                                    '~trailer_number (validation – machine vision)',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Text Box Entry Hint(s): \n' +
                                    'NA', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Page Text:\n' +
                                    '“Confirmation\n' +
                                    'Please confirm the information below is correct. If not, please adjust.”',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },

                        ]
                    },


                    {
                        label: 'Shipment Scenario Logic Workflow Fork', type: 'black', fork: [
                            {
                                forkLabel: 'Trailer Status: @\n' +
                                    '~Empty\n' +
                                    '~Loaded\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Trailer Type: @\n' +
                                    '~Dry Van\n' +
                                    '~Reefer\n' +
                                    '~Flatbed\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Exception: @\n' +
                                    '~Dropping Empty (no pickup/ delivery)\n' +
                                    '~Shuttle\n' +
                                    '~Other Visitor', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {forkLabel: 'check_out_loaded_base', forkColor: '#fff', forkBackgroundColor: '#000'},
                            {forkLabel: 'check_out_empty_base', forkColor: '#fff', forkBackgroundColor: '#000'},
                            {
                                forkLabel: 'check-out_\n' +
                                    'exception', forkColor: '#fff', forkBackgroundColor: '#000'
                            },
                        ]
                    },

                ]
            )}

            {workflow('checkout_loaded_base_aurora123\n', [
                    {
                        label: 'checkout_\n' +
                            'base', type: 'black', fork: []
                    },

                    {
                        label: 'Shipment Scenario Logic Workflow Fork', type: 'logic', fork: [

                            {
                                forkLabel: 'Trailer Type: @\n' +
                                    '~Dry Van\n' +
                                    '~Reefer\n' +
                                    '~Flatbed\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'check-out_\n' +
                                    'loaded_reefer', forkColor: 'white', forkBackgroundColor: 'black'
                            },


                        ]
                    },


                    {
                        label: 'Shipment Scenario Logic Message Fork', type: 'logic', fork: [
                            {
                                forkLabel: 'Direction: @\n' +
                                    '~Inbound\n' +
                                    '~Outbound\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Scenario: @\n' +
                                    '~Live \n' +
                                    '~Drop \n' +
                                    '~Drop & Hook\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Trailer Type: @\n' +
                                    '~Dry Van\n' +
                                    '~Reefer\n' +
                                    '~Flatbed\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: ' Ship From/To:\n@' +
                                    '                ~Internal\n' +
                                    '                ~Customer\n' +
                                    '                ~Supplier\n' +
                                    '                ~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {forkLabel: 'Success / Error', forkColor: '#000', forkBackgroundColor: '#fff'},
                        ]
                    },

                    {
                        label: 'Success Screen / Text Message', type: 'black', fork: [
                            {
                                forkLabel: 'Static Text: \n' +
                                    '“Check-Out Complete for Shipment: XXXXXXX…',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Dynamic Text (based on shipment scenario): \n' +
                                    '“You will receive information about where to… ”',
                                forkColor: 'white',
                                forkBackgroundColor: 'gray'
                            },

                            {
                                forkLabel: 'Gate Entry Info:\n' +
                                    'QR Code / PIN', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'driver_\n' +
                                    'orchestration_\n' +
                                    'base', forkColor: '#fff', forkBackgroundColor: '#000'
                            },

                            {forkLabel: 'Error – “Please see DC…”', forkColor: '#f00', forkBackgroundColor: '#fff'},

                        ]
                    },


                ]
            )}

            {workflow('checkout_loaded_reefer_aurora123', [
                    {
                        label: 'checkout_loaded_\n' +
                            'base', type: 'black', fork: []
                    },

                    {
                        label: 'Chain of Custody Image Validation Component', type: 'logic', fork: [

                            {
                                forkLabel: 'Image Capture: \n' +
                                    'Reefer temp', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Data Capture: \n' +
                                    'Reefer temp', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Validation: \n' +
                                    'Facility defined tolerance', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Error – Indication on FR Web Admin',
                                forkColor: '#f00',
                                forkBackgroundColor: '#fff'
                            },

                        ]
                    },


                    {
                        label: 'Chain of Custody Image Validation Component', type: 'logic', fork: [

                            {
                                forkLabel: 'Image Capture: \n' +
                                    'Reefer fuel gauge', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Data Capture: \n' +
                                    'Reefer fuel gauge %', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Validation: \n' +
                                    'Facility defined tolerance', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Error – Indication on FR Web Admin',
                                forkColor: '#f00',
                                forkBackgroundColor: '#fff'
                            },


                        ]
                    },


                    {
                        label: 'Shipment Scenario Logic Message Fork', type: 'gray', fork: [
                            {
                                forkLabel: 'Direction: @\n' +
                                    '~Inbound\n' +
                                    '~Outbound\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Scenario: @\n' +
                                    '~Live, \n' +
                                    '~Drop, \n' +
                                    '~Drop & Hook,\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Trailer Type: @\n' +
                                    '~Dry Van\n' +
                                    '~Reefer\n' +
                                    '~Flatbed\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: ' Ship From/To:\n@' +
                                    '                ~Internal\n' +
                                    '                ~Customer\n' +
                                    '                ~Supplier\n' +
                                    '                ~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },


                        ]
                    },

                    {
                        label: 'Success Screen / Text Message', type: 'logic', fork: [
                            {
                                forkLabel: '“Check-Out Complete for Shipment: XXXXXXX… ',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Dynamic Text (based on shipment scenario): \n' +
                                    '“You will receive information about where to… ”',
                                forkColor: 'white',
                                forkBackgroundColor: 'gray'
                            },

                            {
                                forkLabel: 'Gate Entry Info:\n' +
                                    'QR Code / PIN', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'driver_\n' +
                                    'orchestration_\n' +
                                    'base', forkColor: '#fff', forkBackgroundColor: '#000'
                            },

                            {forkLabel: 'Error – “Please see DC…”', forkColor: '#f00', forkBackgroundColor: '#fff'},

                        ]
                    },


                ]
            )}


            {workflow('checkout_empty_base_aurora123\n', [
                    {
                        label: 'checkout_\n' +
                            'base', type: 'black', fork: []
                    },


                    {
                        label: 'Shipment Scenario Logic Message Fork', type: 'gray', fork: [
                            {
                                forkLabel: 'Direction: @\n' +
                                    '~Inbound\n' +
                                    '~Outbound\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Scenario: @\n' +
                                    '~Live, \n' +
                                    '~Drop, \n' +
                                    '~Drop & Hook,\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Trailer Type: @\n' +
                                    '~Dry Van\n' +
                                    '~Reefer\n' +
                                    '~Flatbed\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: ' Ship From/To:@\n' +
                                    '                ~Internal\n' +
                                    '                ~Customer\n' +
                                    '                ~Supplier\n' +
                                    '                ~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                        ]
                    },

                    {
                        label: 'Success Screen / Text Message', type: 'logic', fork: [
                            {
                                forkLabel: 'Static Text: \n' +
                                    '“Check-Out Complete for Shipment: XXXXXXX…',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Dynamic Text (based on shipment scenario): \n' +
                                    '“You will receive information about where to… ”',
                                forkColor: 'white',
                                forkBackgroundColor: 'gray'
                            },

                            {
                                forkLabel: 'Gate Entry Info:\n' +
                                    'QR Code / PIN', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'driver_\n' +
                                    'orchestration_\n' +
                                    'base', forkColor: '#fff', forkBackgroundColor: '#000'
                            },

                            {forkLabel: 'Error – “Please see DC…”', forkColor: '#f00', forkBackgroundColor: '#fff'},

                        ]
                    },


                ]
            )}


            {workflow('driverorchestration_base_aurora123\n', [
                    {label: 'message_trigger', type: 'black', fork: []},


                    {
                        label: 'Shipment Scenario Logic Message Fork', type: 'gray', fork: [
                            {
                                forkLabel: 'Direction: @\n' +
                                    '~Inbound\n' +
                                    '~Outbound\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {
                                forkLabel: 'Scenario: @\n' +
                                    '~Live, \n' +
                                    '~Drop, \n' +
                                    '~Drop & Hook,\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: 'Trailer Type: @\n' +
                                    '~Dry Van\n' +
                                    '~Reefer\n' +
                                    '~Flatbed\n' +
                                    '~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },

                            {
                                forkLabel: ' Ship From/To:@\n' +
                                    '                ~Internal\n' +
                                    '                ~Customer\n' +
                                    '                ~Supplier\n' +
                                    '                ~Other', forkColor: '#000', forkBackgroundColor: '#fff'
                            },
                            {forkLabel: 'Success / Error', forkColor: '#000', forkBackgroundColor: '#fff'},
                        ]
                    },

                    {
                        label: 'Driver Text Message', type: 'logic', fork: [
                            {
                                forkLabel: 'Dynamic Text (based on shipment scenario): @\n' +
                                    '~Check-in Success:  “You\'ve successfully checked in for shipment: XXXXXXX”\n' +
                                    '~Chain of custody signature success:  “View Bill of Lading: …”\n' +
                                    '~BOL available:  “Please proceed to kiosk to sign BOL#: XXXXXXX”\n' +
                                    '~Job assignment available:\n' +
                                    '~Drop:  “Please drop your trailer in spot XX.”\n' +
                                    '~Live Pickup: “Please back into dock XX.”\n' +
                                    '~Live Delivery: “Please back into dock XX.”\n' +
                                    '~Hook: “Please pick up your trailer in spot XX.”\n' +
                                    '~Drop & Hook: “Please drop your trailer in spot XX and pick up your loaded trailer in spot YY.”\n',
                                forkColor: '#000',
                                forkBackgroundColor: '#fff'
                            },

                        ]
                    },


                ]
            )}


            {/*{workflow('aurora_checkin_fork',[*/}
            {/*    {label:'Authorize Driver', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}
            {/*    {label:'Shipment Input (shipment_number)', type:'fork', forkLabel:'Not Found', forkColor: '#f00', forkBackgroundColor:'#fff'},*/}
            {/*    {label:'Shipment Type (inbound/outbound)', type:'fork', forkLabel:'inbound: aurora_checkin_fork_inbound', forkColor: 'white', forkBackgroundColor:'black'},*/}

            {/*    {label:'Driver Input', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}
            {/*    {label:'Validate', type:'fork', forkLabel:'Error', forkColor: '#f00', forkBackgroundColor:'#fff'},*/}
            {/*    {label:'Success', type: 'fork', forkLabel:'Driver Check-In SMS', forkColor:'white', forkBackgroundColor:'#006400'},*/}

            {/*    ]*/}

            {/*)}*/}

            {/*{workflow('aurora_checkin_fork_inbound',[*/}
            {/*    {label:'Driver Input', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}
            {/*    {label:'Scan BOL', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}
            {/*    {label:'Seal Input', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}
            {/*    {label:'Seal Imaging', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}
            {/*    {label:'BOL Seal Imaging', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}
            {/*    {label:'Shipment Type (reefer/none)', type:'fork', forkLabel:'reefer: aurora_checkin_fork_inbound_reefer', forkColor: 'white', forkBackgroundColor:'black'},*/}

            {/*        {label:'Validate', type:'fork', forkLabel:'Error', forkColor: '#f00', forkBackgroundColor:'#fff'},*/}
            {/*    {label:'Success', type: 'fork', forkLabel:'Driver Check-In SMS', forkColor:'white', forkBackgroundColor:'#006400'},*/}

            {/*    ]*/}

            {/*)}*/}

            {/*{workflow('aurora_checkin_fork_inbound_reefer',[*/}
            {/*    {label:'Fuel Gauge Imaging', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}
            {/*    {label:'Temp Imaging', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}
            {/*    {label:'Temp Set Point Imaging', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}

            {/*        {label:'Validate', type:'fork', forkLabel:'Error', forkColor: '#f00', forkBackgroundColor:'#fff'},*/}
            {/*    {label:'Success', type: 'fork', forkLabel:'Driver Check-In SMS', forkColor:'white', forkBackgroundColor:'#006400'},*/}

            {/*    ]*/}

            {/*)}*/}

            {/*{workflow('aurora_checkout_fork',[*/}
            {/*        {label:'Shipment Input (seal_number)', type:'fork', forkLabel:'Not Found', forkColor: '#f00', forkBackgroundColor:'#fff'},*/}

            {/*        {label:'Authorize Driver', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}
            {/*        {label:'Shipment Type (inbound/outbound)', type:'fork', forkLabel:'inbound: aurora_checkout_fork_inbound', forkColor: 'white', forkBackgroundColor:'black'},*/}

            {/*        {label:'Validate', type:'fork', forkLabel:'Error', forkColor: '#f00', forkBackgroundColor:'#fff'},*/}
            {/*        {label:'Success', type: 'default', forkLabel:'Driver Check-In SMS', forkColor:'white', forkBackgroundColor:'#006400'},*/}

            {/*    ]*/}

            {/*)}*/}

            {/*{workflow('aurora_checkout_fork_inbound',[*/}
            {/*        {label:'Driver Input', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}

            {/*        {label:'Adopt Signature', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}
            {/*        {label:'Sign BOL', type: 'default', forkLabel:'', forkColor:'', forkBackgroundColor:''},*/}

            {/*        {label:'Validate', type:'fork', forkLabel:'Error', forkColor: '#f00', forkBackgroundColor:'#fff'},*/}
            {/*        {label:'Success', type: 'default', forkLabel:'Driver Check-Out SMS', forkColor:'white', forkBackgroundColor:'#006400'},*/}

            {/*    ]*/}

            {/*)}*/}


            {/*    <h1 className="font-bold text-lg mt-5 mb-3">Site-Specific Messages</h1>*/}
            {/*    <div className="bg-white p-4 w-full">*/}
            {/*<span className=" text-sky-600 float-right hover:underline cursor-pointer">*/}
            {/*  Edit*/}
            {/*</span>*/}
            {/*        <p>*/}
            {/*            <span className="font-bold">Load # Format:</span> Please enter your load number*/}
            {/*            below. Possible Load # Format(s): Starts with “2” (9 digits after 2)*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <span className="font-bold">Successful Check-in: </span>You will receive*/}
            {/*            information about where to drop your empty, where to dock your truck and/or*/}
            {/*            where to pick up your loaded trailer via text message*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <span className="font-bold">Check-In with an Issue: </span>Unfortunately there*/}
            {/*            was an issue with your check-in. Once you have entered the gate, please proceed*/}
            {/*            to the dock office and show them this receipt*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <span className="font-bold">Drop: </span>Please drop your trailer in spot XX.*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <span className="font-bold">Live Pickup: </span> Please back into dock XX.*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <span className="font-bold">Live Delivery: </span>Please back into dock XX.*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <span className="font-bold">Hook: </span>Please pick up your trailer in spot XX.*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <span className="font-bold">Drop & Hook: </span>Please drop your trailer in spot*/}
            {/*            XX and pick up your loaded trailer in spot YY.*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <span className="font-bold">After Hours Message: </span>We are closed. Please*/}
            {/*            return later.*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {showGateModal && (
                <Modal
                    open={showGateModal}
                    onClose={handleGateModalClose}
                    className="flex justify-center items-center"
                >
                    <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
                        <Close
                            className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                            onClick={handleGateModalClose}
                        />
                        {!!showGateSuccess ? (
                            <div className="flex justify-center items-center flex-col mt-5">
                                <h1 className="text-xl text-green-500">{gate} Opened!</h1>
                                <Check className="text-green-500 mt-2" fontSize="large"/>
                            </div>
                        ) : (
                            <>
                                <h1 className="text-xl mb-5">Open Gate</h1>
                                <h1 className="text-xl mb-5">Please select which gate to open:</h1>
                                <Select
                                    options={gates}
                                    placeholder="Gates"
                                    onChange={(selectedOption: any) => {
                                        setGate(selectedOption?.label);
                                    }}
                                />
                                <div className="flex justify-between mt-3">
                                    <button
                                        className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded w-24"
                                        onClick={handleGateModalClose}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className={twMerge(
                                            'bg-sky-600 hover:bg-sky-500 text-white p-2 rounded w-24',
                                            !gate && 'bg-gray-300 hover:bg-gray-300 cursor-not-allowed'
                                        )}
                                        onClick={handleOpenGate}
                                        disabled={!gate}
                                    >
                                        Open
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </Modal>
            )}
            {showPinModal && (
                <Modal
                    open={showPinModal}
                    onClose={handlePinModalClose}
                    className="flex justify-center items-center"
                >
                    <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
                        <Close
                            className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                            onClick={handlePinModalClose}
                        />
                        {pin ? (
                            <div className="flex justify-center items-center flex-col">
                                <h1 className="text-xl mb-2">Please use this PIN at {gate}:</h1>
                                <h1 className="text-xl">{pin}</h1>
                                <button
                                    className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded mt-10"
                                    onClick={handlePinModalClose}
                                >
                                    Close
                                </button>
                            </div>
                        ) : (
                            <>
                                <h1 className="text-xl mb-5">Generate PIN</h1>
                                <h1 className="text-xl mb-5">
                                    Please select which gate to provide a PIN for:
                                </h1>
                                <Select
                                    options={gates}
                                    placeholder="Gates"
                                    onChange={(selectedOption: any) => {
                                        setGate(selectedOption?.label);
                                    }}
                                />
                                <div className="flex justify-between mt-3">
                                    <button
                                        className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded w-24"
                                        onClick={handlePinModalClose}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className={twMerge(
                                            'bg-sky-600 hover:bg-sky-500 text-white p-2 rounded w-24',
                                            !gate && 'bg-gray-300 hover:bg-gray-300 cursor-not-allowed'
                                        )}
                                        onClick={handleGeneratePin}
                                        disabled={!gate}
                                    >
                                        Generate
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </Modal>
            )}
            {/* {showUpdateModal && (
        <Modal
          open={showUpdateModal}
          onClose={handleUpdateModalClose}
          className="flex justify-center items-center"
        >
          <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
            <Close
              className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
              onClick={handleUpdateModalClose}
            />
            <h1 className="text-xl mb-5">Update Facility</h1>

            <button
              className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded mt-3"
              onClick={handleUpdateModalClose}
            >
              Cancel
            </button>
            <button
              className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded mt-3"
              onClick={handleUpdateModalClose}
            >
              Update
            </button>
          </div>
        </Modal>
      )} */}
        </div>
    );
};

export default Facility;