import { useState, useEffect } from 'react';
import { CircularProgress, Modal, Tooltip, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Close, Edit, Check, ViewModule, ViewSidebar, ViewWeek, ViewArray } from '@mui/icons-material';
import Select from 'react-select';
import { twMerge } from 'tailwind-merge';

// Types for dock doors and yard spots
type Status = 'available' | 'in-use' | 'unavailable';

type SpotType = 'dock' | 'yard';

// Dock doors have inbound/outbound groups
type DockGroup = 'inbound' | 'outbound' | 'unassigned';

// Yard spots are organized by zones
type YardZone = {
  id: string;
  name: string;
  color: string; // CSS color for visual indication
};

// Each spot is either a dock door or a yard spot with shared properties
type BaseSpot = {
  id: string;
  number: string;
  type: SpotType;
  status: Status;
};

// Dock door extends base spot with a group
type DockDoor = BaseSpot & {
  type: 'dock';
  group: DockGroup;
};

// Yard spot extends base spot with a zone
type YardSpot = BaseSpot & {
  type: 'yard';
  zoneId: string;
};

// Union type of both spot types
type Spot = DockDoor | YardSpot;

// Define layout types
type LayoutType = 'one-sided' | 'two-sided';

const YardManagement = () => {
  const [loading, setLoading] = useState(false);
  const [spots, setSpots] = useState<Spot[]>([]);
  const [selectedSpot, setSelectedSpot] = useState<Spot | null>(null);
  const [showSpotModal, setShowSpotModal] = useState(false);
  const [showYardEditModal, setShowYardEditModal] = useState(false);
  const [statusFilter, setStatusFilter] = useState<Status | 'all'>('all');
  const [layout, setLayout] = useState<LayoutType>('two-sided');
  const [dockCount, setDockCount] = useState(20);
  const [yardCount, setYardCount] = useState(30);
  const [editingSpot, setEditingSpot] = useState<Spot | null>(null);
  const [inboundDockPercentage, setInboundDockPercentage] = useState(50);
  const [showStagingArea, setShowStagingArea] = useState(true);
  // Define zones for yard spots
  const [zones, setZones] = useState<YardZone[]>([
    { id: 'zone-1', name: 'Zone 1', color: '#3b82f6' }, // blue
    { id: 'zone-2', name: 'Zone 2', color: '#f97316' }
  ]);

  // Add back the useEffect hook for initial data loading
  useEffect(() => {
    setLoading(true);
    generateLayoutData();
  }, [layout]); // Regenerate data when layout changes

  // Modified generateLayoutData with group assignments
  const generateLayoutData = () => {
    // Create a map of existing spots to preserve custom names and status
    const existingSpots: Record<string, Spot> = {};
    spots.forEach(spot => {
      existingSpots[spot.id] = spot;
    });
    
    // Calculate how many dock doors should be inbound based on percentage
    const inboundDockCount = Math.ceil((dockCount * inboundDockPercentage) / 100);
    
    // Create mock dock doors based on dockCount
    const dockDoors: DockDoor[] = Array.from({ length: dockCount }, (_, i) => {
      const id = `dock-${i + 1}`;
      const existingSpot = existingSpots[id];
      // Assign group - first N doors are inbound, rest are outbound
      const group: DockGroup = i < inboundDockCount ? 'inbound' : 'outbound';
      
      // Check if existing spot is a dock door to preserve its group
      const existingGroup = existingSpot?.type === 'dock' ? existingSpot.group : undefined;
      
      return {
        id,
        // Preserve existing data if available
        number: existingSpot?.number || `D${i + 1}`,
        type: 'dock' as const,
        status: existingSpot?.status || 'available',
        group: existingGroup || group // Keep existing group if available, otherwise assign new
      };
    });
    
    // Create mock yard spots based on yardCount and assign to zones
    const yardSpots: YardSpot[] = Array.from({ length: yardCount }, (_, i) => {
      const id = `yard-${i + 1}`;
      const existingSpot = existingSpots[id];
      
      // Distribute yard spots across zones evenly (cyclic assignment)
      const zoneIndex = i % zones.length;
      const zoneId = zones[zoneIndex].id;
      
      // Check if existing spot is a yard spot to preserve its zoneId
      const existingZoneId = existingSpot?.type === 'yard' ? existingSpot.zoneId : undefined;
      
      return {
        id,
        // Preserve existing data if available
        number: existingSpot?.number || `Y${i + 1}`,
        type: 'yard' as const,
        status: existingSpot?.status || 'available',
        zoneId: existingZoneId || zoneId // Keep existing zoneId if available, otherwise assign new
      };
    });

    setSpots([...dockDoors, ...yardSpots]);
    setLoading(false);
  };

  const handleSpotClick = (spot: Spot) => {
    setSelectedSpot(spot);
    setShowSpotModal(true);
  };

  const handleSpotModalClose = () => {
    setShowSpotModal(false);
    setSelectedSpot(null);
  };

  const handleStatusChange = (status: Status) => {
    if (selectedSpot) {
      const updatedSpots = spots.map(spot => 
        spot.id === selectedSpot.id ? { ...spot, status } : spot
      );
      setSpots(updatedSpots);
      setSelectedSpot({ ...selectedSpot, status });
    }
  };

  const handleLayoutChange = (event: React.MouseEvent<HTMLElement>, newLayout: LayoutType) => {
    if (newLayout !== null) {
      setLayout(newLayout);
    }
  };

  const handleYardEditOpen = () => {
    setShowYardEditModal(true);
  };

  const handleYardEditClose = () => {
    setShowYardEditModal(false);
    setEditingSpot(null);
  };

  const handleYardEditSave = () => {
    // Show loading while regenerating data
    setLoading(true);
    
    // Small timeout to allow UI to update before regenerating data
    setTimeout(() => {
      // Regenerate layout data with new settings while preserving names
      generateLayoutData();
      
      // Close the modal after saving
      handleYardEditClose();
      
      // We could add a toast notification here for better UX
      // e.g., toast.success("Yard layout saved successfully");
    }, 300);
  };

  const handleSpotEdit = (spot: Spot) => {
    setEditingSpot({...spot});
  };

  const handleSpotNameChange = (id: string, newName: string) => {
    setSpots(spots.map(spot => 
      spot.id === id ? { ...spot, number: newName } : spot
    ));
  };

  const handleDockCountChange = (newCount: number) => {
    setDockCount(Math.max(1, newCount));
  };

  const handleYardCountChange = (newCount: number) => {
    setYardCount(Math.max(1, newCount));
  };

  const getStatusColor = (status: Status): string => {
    switch (status) {
      case 'available':
        return 'bg-green-500';
      case 'in-use':
        return 'bg-red-500';
      case 'unavailable':
        return 'bg-gray-500';
      default:
        return 'bg-gray-500';
    }
  };

  // Updated to only work with dock doors
  const getGroupColor = (group: DockGroup): string => {
    switch (group) {
      case 'inbound':
        return 'border-blue-500 border-4';
      case 'outbound':
        return 'border-orange-500 border-4';
      default:
        return 'border-gray-400 border';
    }
  };
  
  // New helper to get zone color
  const getZoneColor = (zoneId: string): string => {
    const zone = zones.find(z => z.id === zoneId);
    return zone ? `border-[${zone.color}] border-4` : 'border-gray-400 border';
  };

  // Updated to handle both spot types
  const getSpotTooltip = (spot: Spot): string => {
    if (spot.type === 'dock') {
      const groupText = spot.group.charAt(0).toUpperCase() + spot.group.slice(1);
      return `Door ${spot.number} - ${spot.status} (${groupText})`;
    } else {
      const zone = zones.find(z => z.id === spot.zoneId);
      const zoneText = zone ? zone.name : 'Unassigned Zone';
      return `Spot ${spot.number} - ${spot.status} (${zoneText})`;
    }
  };

  const filteredSpots = statusFilter === 'all' 
    ? spots 
    : spots.filter(spot => spot.status === statusFilter);

  // Update dock group change handler
  const handleDockGroupChange = (id: string, newGroup: DockGroup) => {
    setSpots(spots.map(spot => {
      if (spot.id === id && spot.type === 'dock') {
        return { ...spot, group: newGroup };
      }
      return spot;
    }));
    
    // If modifying the currently selected spot, update that too
    if (selectedSpot && selectedSpot.id === id && selectedSpot.type === 'dock') {
      setSelectedSpot({ ...selectedSpot, group: newGroup });
    }
  };

  // Add new handler for yard zone changes
  const handleYardZoneChange = (id: string, newZoneId: string) => {
    setSpots(spots.map(spot => {
      if (spot.id === id && spot.type === 'yard') {
        return { ...spot, zoneId: newZoneId };
      }
      return spot;
    }));
    
    // If modifying the currently selected spot, update that too
    if (selectedSpot && selectedSpot.id === id && selectedSpot.type === 'yard') {
      setSelectedSpot({ ...selectedSpot, zoneId: newZoneId });
    }
  };

  // Add zone management
  const handleAddZone = () => {
    // Generate a random color
    const randomColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
    
    const newZone: YardZone = {
      id: `zone-${zones.length + 1}`,
      name: `Zone ${zones.length + 1}`,
      color: randomColor
    };
    
    setZones([...zones, newZone]);
  };

  const handleZoneNameChange = (id: string, newName: string) => {
    setZones(zones.map(zone => 
      zone.id === id ? { ...zone, name: newName } : zone
    ));
  };

  const handleZoneColorChange = (id: string, newColor: string) => {
    setZones(zones.map(zone => 
      zone.id === id ? { ...zone, color: newColor } : zone
    ));
  };

  const handleInboundDockPercentageChange = (newPercentage: number) => {
    setInboundDockPercentage(Math.min(100, Math.max(0, newPercentage)));
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center mt-4 w-full">
        <CircularProgress />
        <p className="mt-2">Loading...</p>
      </div>
    );
  }

  const statusOptions = [
    { value: 'all', label: 'All' },
    { value: 'available', label: 'Available' },
    { value: 'in-use', label: 'In Use' },
    { value: 'unavailable', label: 'Unavailable' }
  ];

  return (
    <div className="w-full">
      <div className="flex mb-4 items-center">
        <div className="mr-4">
          <button
            className="bg-sky-600 hover:bg-sky-500 text-white px-3 py-1 rounded flex items-center"
            onClick={handleYardEditOpen}
          >
            <Edit fontSize="small" className="mr-1" />
            Edit Yard Layout
          </button>
        </div>
      
      </div>

      {/* Main yard layout container */}
      <div className="border border-gray-300 bg-gray-100 relative mb-8">
        {/* Use CSS grid for overall layout */}
        <div className={`${layout === 'one-sided' ? 'grid grid-rows-[2fr_1fr]' : 'flex flex-col'} min-h-[550px]`}>
          
          {layout === 'one-sided' ? (
            // One-sided layout (yard above, warehouse below with doors on top)
            <>
              {/* Yard area - Updated to show zones */}
              <div className="bg-green-100 border-2 border-gray-600 p-4 m-4 mb-2 rounded flex flex-col">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-bold text-center">Yard</h3>
                  <div className="flex text-xs gap-2">
                    {zones.map(zone => (
                      <div key={zone.id} className="flex items-center">
                        <div className="w-3 h-3 mr-1" style={{ backgroundColor: zone.color }}></div>
                        <span>{zone.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
                
                {/* Yard zones layout */}
                <div className="flex-1 grid grid-cols-1 gap-4 auto-rows-max place-content-start overflow-y-auto p-2">
                  {/* Group spots by zone */}
                  {zones.map(zone => {
                    const zoneSpots = filteredSpots.filter(
                      spot => spot.type === 'yard' && (spot as YardSpot).zoneId === zone.id
                    );
                    
                    if (zoneSpots.length === 0) return null;
                    
                    return (
                      <div 
                        key={zone.id} 
                        className="rounded-lg p-3" 
                        style={{ backgroundColor: `${zone.color}20`, borderColor: zone.color, borderWidth: '2px' }}
                      >
                        <h4 className="font-bold mb-2" style={{ color: zone.color }}>{zone.name}</h4>
                        <div className="grid grid-cols-5 gap-3">
                          {zoneSpots.map(spot => (
                            <Tooltip 
                              key={spot.id} 
                              title={getSpotTooltip(spot)}
                              arrow
                            >
                              <div
                                className={`cursor-pointer flex items-center justify-center text-white font-bold 
                                  ${getStatusColor(spot.status)} w-14 h-10 rounded`}
                                onClick={() => handleSpotClick(spot)}
                              >
                                {spot.number}
                              </div>
                            </Tooltip>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Staging Area - New addition */}
              {showStagingArea && (
                <div className="bg-amber-100 border-2 border-amber-600 border-dashed p-2 m-4 mt-0 mb-2 rounded flex flex-col items-center justify-center">
                  <div className="flex items-center">
                    <div className="w-4 h-4 bg-amber-400 mr-2 rounded-full"></div>
                    <h3 className="font-bold text-center text-amber-800">Staging Area</h3>
                  </div>
                  <p className="text-xs text-amber-700 mt-1">Temporary holding area for trailers and equipment</p>
                </div>
              )}

              {/* Warehouse with dock doors on top */}
              <div className="bg-gray-300 border-2 border-gray-600 p-4 m-4 mt-0 rounded flex flex-col">
                {/* Dock doors section - Updated to show inbound/outbound groups */}
                <div className="mb-3">
                  <div className="flex justify-between items-center mb-1">
                    <h4 className="text-sm font-semibold">Dock Doors</h4>
                    <div className="flex text-xs">
                      <div className="flex items-center mr-3">
                        <div className="w-3 h-3 border-2 border-blue-500 mr-1"></div>
                        <span>Inbound</span>
                      </div>
                      <div className="flex items-center">
                        <div className="w-3 h-3 border-2 border-orange-500 mr-1"></div>
                        <span>Outbound</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center space-x-4 overflow-x-auto p-2 border-b border-gray-400 pb-3">
                    {filteredSpots.filter(spot => spot.type === 'dock').map(spot => (
                      <Tooltip 
                        key={spot.id} 
                        title={getSpotTooltip(spot)}
                        arrow
                      >
                        <div
                          className={`cursor-pointer flex items-center justify-center text-white font-bold 
                            ${getStatusColor(spot.status)} ${spot.type === 'dock' ? getGroupColor((spot as DockDoor).group) : ''} w-8 h-6 flex-shrink-0`}
                          onClick={() => handleSpotClick(spot)}
                        >
                          {spot.number}
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                </div>
                
                {/* Warehouse label in the middle */}
                <h3 className="font-bold text-center my-2">Warehouse</h3>
                
                {/* Empty bottom section to balance the layout */}
                <div className="mt-3 pt-3">
                  {/* This space intentionally left empty for visual balance */}
                </div>
              </div>
            </>
          ) : (
            // Two-sided layout (yard above, warehouse with doors on top AND bottom)
            <>
              {/* Yard area */}
              <div className="bg-green-100 border-2 border-gray-600 p-4 m-4 mb-2 rounded flex-grow flex flex-col">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-bold text-center">Yard</h3>
                  <div className="flex text-xs gap-2">
                    {zones.map(zone => (
                      <div key={zone.id} className="flex items-center">
                        <div className="w-3 h-3 mr-1" style={{ backgroundColor: zone.color }}></div>
                        <span>{zone.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
                
                {/* Yard zones layout */}
                <div className="flex-1 grid grid-cols-1 gap-4 auto-rows-max place-content-start overflow-y-auto p-2">
                  {/* Group spots by zone */}
                  {zones.map(zone => {
                    const zoneSpots = filteredSpots.filter(
                      spot => spot.type === 'yard' && (spot as YardSpot).zoneId === zone.id
                    );
                    
                    if (zoneSpots.length === 0) return null;
                    
                    return (
                      <div 
                        key={zone.id} 
                        className="rounded-lg p-3" 
                        style={{ backgroundColor: `${zone.color}20`, borderColor: zone.color, borderWidth: '2px' }}
                      >
                        <h4 className="font-bold mb-2" style={{ color: zone.color }}>{zone.name}</h4>
                        <div className="grid grid-cols-5 gap-3">
                          {zoneSpots.map(spot => (
                            <Tooltip 
                              key={spot.id} 
                              title={getSpotTooltip(spot)}
                              arrow
                            >
                              <div
                                className={`cursor-pointer flex items-center justify-center text-white font-bold 
                                  ${getStatusColor(spot.status)} w-14 h-10 rounded`}
                                onClick={() => handleSpotClick(spot)}
                              >
                                {spot.number}
                              </div>
                            </Tooltip>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              
              {/* Staging Area - New addition */}
              {showStagingArea && (
                <div className="bg-amber-100 border-2 border-amber-600 border-dashed p-2 m-4 mt-0 mb-2 rounded flex items-center justify-center">
                  <div className="w-4 h-4 bg-amber-400 mr-2 rounded-full"></div>
                  <h3 className="font-bold text-center text-amber-800">Staging Area</h3>
                  <p className="text-xs text-amber-700 ml-4">Temporary holding area for trailers and equipment</p>
                </div>
              )}
              
              {/* Warehouse with doors on top and bottom */}
              <div className="bg-gray-300 border-2 border-gray-600 p-4 m-4 mt-0 mb-4 rounded flex flex-col">
                {/* Top doors section */}
                <div className="mb-3">
                  <div className="flex justify-between items-center mb-1">
                    <div className="flex text-xs">
                      <div className="flex items-center mr-3">
                        <div className="w-3 h-3 border-2 border-blue-500 mr-1"></div>
                        <span>Inbound</span>
                      </div>
                      <div className="flex items-center">
                        <div className="w-3 h-3 border-2 border-orange-500 mr-1"></div>
                        <span>Outbound</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center space-x-4 overflow-x-auto p-2 border-b border-gray-400 pb-3">
                    {filteredSpots
                      .filter(spot => spot.type === 'dock')
                      .slice(0, Math.ceil(dockCount / 2))
                      .map(spot => (
                        <Tooltip 
                          key={spot.id} 
                          title={getSpotTooltip(spot)}
                          arrow
                        >
                          <div
                            className={`cursor-pointer flex items-center justify-center text-white font-bold 
                              ${getStatusColor(spot.status)} ${getGroupColor((spot as DockDoor).group)} w-8 h-6 flex-shrink-0`}
                            onClick={() => handleSpotClick(spot)}
                          >
                            {spot.number}
                          </div>
                        </Tooltip>
                      ))}
                  </div>
                </div>
                
                {/* Warehouse label */}
                <h3 className="font-bold text-center my-2">Warehouse</h3>
                
                {/* Bottom doors section */}
                <div className="mt-3">
                  <div className="flex justify-between items-center mb-1">
                  </div>
                  <div className="flex justify-center space-x-4 overflow-x-auto p-2 border-t border-gray-400 pt-3">
                    {filteredSpots
                      .filter(spot => spot.type === 'dock')
                      .slice(Math.ceil(dockCount / 2))
                      .map(spot => (
                        <Tooltip 
                          key={spot.id} 
                          title={getSpotTooltip(spot)}
                          arrow
                        >
                          <div
                            className={`cursor-pointer flex items-center justify-center text-white font-bold 
                              ${getStatusColor(spot.status)} ${getGroupColor((spot as DockDoor).group)} w-8 h-6 flex-shrink-0`}
                            onClick={() => handleSpotClick(spot)}
                          >
                            {spot.number}
                          </div>
                        </Tooltip>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        
        {/* Legend - updated with group indicators */}
        <div className="absolute bottom-4 right-4 bg-white p-2 border border-gray-300 rounded shadow-sm">
          <h4 className="font-bold text-sm mb-1">Legend:</h4>
          <div className="flex flex-col gap-1">
            <div className="text-xs font-semibold mb-1">Status:</div>
            <div className="flex items-center">
              <div className="w-4 h-4 bg-green-500 mr-2"></div>
              <span className="text-xs">Available</span>
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 bg-red-500 mr-2"></div>
              <span className="text-xs">In Use</span>
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 bg-gray-500 mr-2"></div>
              <span className="text-xs">Unavailable</span>
            </div>
            <div className="text-xs font-semibold mt-2 mb-1">Dock Doors:</div>
            <div className="flex items-center">
              <div className="w-4 h-4 border-2 border-blue-500 mr-2"></div>
              <span className="text-xs">Inbound</span>
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 border-2 border-orange-500 mr-2"></div>
              <span className="text-xs">Outbound</span>
            </div>
            {showStagingArea && (
              <div className="flex items-center mt-2">
                <div className="w-4 h-4 bg-amber-400 mr-2 rounded-full"></div>
                <span className="text-xs">Staging Area</span>
              </div>
            )}
            <div className="text-xs font-semibold mt-2 mb-1">Yard Zones:</div>
            <div className="max-h-20 overflow-y-auto">
              {zones.map(zone => (
                <div key={zone.id} className="flex items-center">
                  <div className="w-4 h-4 mr-2 rounded" style={{ backgroundColor: zone.color }}></div>
                  <span className="text-xs">{zone.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      {/* Statistics */}
      <div className="flex mt-8 gap-4">
        <div className="w-1/4 bg-white p-3 shadow">
          <h3 className="font-bold">Dock Doors</h3>
          <p>Total: {spots.filter(spot => spot.type === 'dock').length}</p>
          <p>Available: {spots.filter(spot => spot.type === 'dock' && spot.status === 'available').length}</p>
          <p>In Use: {spots.filter(spot => spot.type === 'dock' && spot.status === 'in-use').length}</p>
          <p>Unavailable: {spots.filter(spot => spot.type === 'dock' && spot.status === 'unavailable').length}</p>
        </div>
        <div className="w-1/4 bg-white p-3 shadow">
          <h3 className="font-bold">Yard Spots</h3>
          <p>Total: {spots.filter(spot => spot.type === 'yard').length}</p>
          <p>Available: {spots.filter(spot => spot.type === 'yard' && spot.status === 'available').length}</p>
          <p>In Use: {spots.filter(spot => spot.type === 'yard' && spot.status === 'in-use').length}</p>
          <p>Unavailable: {spots.filter(spot => spot.type === 'yard' && spot.status === 'unavailable').length}</p>
          <div className="mt-2 pt-2 border-t">
            <h4 className="font-semibold mb-1">By Zone:</h4>
            <div className="max-h-36 overflow-y-auto">
              {zones.map(zone => {
                const zoneSpots = spots.filter(
                  spot => spot.type === 'yard' && (spot as YardSpot).zoneId === zone.id
                );
                const availableCount = zoneSpots.filter(spot => spot.status === 'available').length;
                const inUseCount = zoneSpots.filter(spot => spot.status === 'in-use').length;
                
                return (
                  <div key={zone.id} className="mb-1">
                    <p className="text-sm font-medium" style={{ color: zone.color }}>{zone.name}: {zoneSpots.length}</p>
                    <p className="text-xs">Available: {availableCount} | In Use: {inUseCount}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        
      </div>

      {/* Spot Detail Modal - updated with group selection */}
      {showSpotModal && selectedSpot && (
        <Modal
          open={showSpotModal}
          onClose={handleSpotModalClose}
          className="flex justify-center items-center"
        >
          <div className="w-96 bg-white border p-5 max-h-[80vh] overflow-y-auto relative flex flex-col">
            <Close
              className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
              onClick={handleSpotModalClose}
            />
            <h1 className="text-xl mb-5">
              {selectedSpot.type === 'dock' ? 'Dock Door' : 'Yard Spot'} {selectedSpot.number}
            </h1>
            
            <div className="mb-4">
              <h2 className="font-bold mb-2">Status</h2>
              <div className="flex gap-2">
                {(['available', 'in-use', 'unavailable'] as Status[]).map(status => (
                  <button
                    key={status}
                    className={twMerge(
                      'px-3 py-1 rounded text-sm',
                      selectedSpot.status === status 
                        ? `${getStatusColor(status)} text-white` 
                        : 'bg-gray-200 hover:bg-gray-300'
                    )}
                    onClick={() => handleStatusChange(status)}
                  >
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </button>
                ))}
              </div>
            </div>
            
            {/* Conditional group/zone selection based on spot type */}
            {selectedSpot.type === 'dock' ? (
              // Dock Door Group Selection
              <div className="mb-4">
                <h2 className="font-bold mb-2">Group</h2>
                <div className="flex gap-2">
                  {(['inbound', 'outbound', 'unassigned'] as DockGroup[]).map(group => (
                    <button
                      key={group}
                      className={twMerge(
                        'px-3 py-1 rounded text-sm',
                        (selectedSpot as DockDoor).group === group 
                          ? `border-2 ${group === 'inbound' ? 'border-blue-500 bg-blue-100' : 
                              group === 'outbound' ? 'border-orange-500 bg-orange-100' : 
                              'border-gray-400 bg-gray-100'}` 
                          : 'bg-gray-200 hover:bg-gray-300 border border-gray-300'
                      )}
                      onClick={() => handleDockGroupChange(selectedSpot.id, group)}
                    >
                      {group.charAt(0).toUpperCase() + group.slice(1)}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              // Yard Spot Zone Selection
              <div className="mb-4">
                <h2 className="font-bold mb-2">Zone</h2>
                <select
                  value={(selectedSpot as YardSpot).zoneId}
                  onChange={(e) => handleYardZoneChange(selectedSpot.id, e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  {zones.map(zone => (
                    <option key={zone.id} value={zone.id}>
                      {zone.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            
            <div className="mt-auto pt-3 border-t flex justify-end">
              <button
                className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
                onClick={handleSpotModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* Yard Edit Modal - updated with zone management */}
      <Modal
        open={showYardEditModal}
        onClose={handleYardEditClose}
        className="flex justify-center items-center"
      >
        <div className="w-[800px] bg-white border p-5 max-h-[80vh] overflow-y-auto relative flex flex-col">
          <Close
            className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
            onClick={handleYardEditClose}
          />
          <h1 className="text-xl mb-5">Edit Yard Layout</h1>
          
          <div className="grid grid-cols-2 gap-8">
            {/* Layout Settings */}
            <div className="mb-6">
              <h2 className="font-bold mb-4 text-lg border-b pb-2">Layout Configuration</h2>
              
              <div className="mb-4">
                <h3 className="font-medium mb-2">Layout Type</h3>
                <ToggleButtonGroup
                  value={layout}
                  exclusive
                  onChange={handleLayoutChange}
                  aria-label="warehouse layout"
                  size="medium"
                  className="w-full"
                >
                  <ToggleButton value="one-sided" aria-label="one-sided layout" className="flex-1">
                    <ViewSidebar className="mr-2" />
                    <span>One Side</span>
                  </ToggleButton>
                  <ToggleButton value="two-sided" aria-label="two-sided layout" className="flex-1">
                    <ViewWeek className="mr-2" />
                    <span>Two Sides</span>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              
              <div className="mb-4">
                <h3 className="font-medium mb-2">Number of Dock Doors</h3>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="1"
                    max="20"
                    value={dockCount}
                    onChange={(e) => handleDockCountChange(parseInt(e.target.value) || 1)}
                    className="border p-2 w-20 mr-2 text-center"
                  />
                  <span className="text-gray-500">Maximum: 20</span>
                </div>
              </div>
              
              <div className="mb-4">
                <h3 className="font-medium mb-2">Number of Yard Spots</h3>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="1"
                    max="30"
                    value={yardCount}
                    onChange={(e) => handleYardCountChange(parseInt(e.target.value) || 1)}
                    className="border p-2 w-20 mr-2 text-center"
                  />
                  <span className="text-gray-500">Maximum: 30</span>
                </div>
              </div>

              <div className="mb-4">
                <h3 className="font-medium mb-2">Staging Area</h3>
                <div className="flex items-center">
                  <label className="inline-flex items-center cursor-pointer">
                    <input 
                      type="checkbox" 
                      checked={showStagingArea}
                      onChange={(e) => setShowStagingArea(e.target.checked)}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900">
                      {showStagingArea ? 'Enabled' : 'Disabled'}
                    </span>
                  </label>
                </div>
                <p className="text-xs text-gray-500 mt-1">Display a staging area between yard and warehouse sections</p>
              </div>
              
              {/* Dock group allocation */}
              <div className="mt-6">
                <h2 className="font-bold mb-4 text-lg border-b pb-2">Dock Door Allocation</h2>
                
                <div className="mb-4">
                  <h3 className="font-medium mb-2">Dock Doors - Inbound Percentage</h3>
                  <div className="flex items-center">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={inboundDockPercentage}
                      onChange={(e) => handleInboundDockPercentageChange(parseInt(e.target.value))}
                      className="w-full mr-2"
                    />
                    <span className="w-12 text-center">{inboundDockPercentage}%</span>
                  </div>
                  <div className="flex justify-between text-xs text-gray-500 mt-1">
                    <span>{Math.ceil((dockCount * inboundDockPercentage) / 100)} Inbound</span>
                    <span>{dockCount - Math.ceil((dockCount * inboundDockPercentage) / 100)} Outbound</span>
                  </div>
                </div>
              </div>
              
              {/* Zone Management - New section */}
              <div className="mt-6">
                <div className="flex justify-between items-center">
                  <h2 className="font-bold text-lg border-b pb-2">Yard Zones</h2>
                  <button 
                    className="text-sm bg-sky-600 text-white rounded px-2 py-1"
                    onClick={handleAddZone}
                  >
                    + Add Zone
                  </button>
                </div>
                
                <div className="mt-3 max-h-[200px] overflow-y-auto">
                  {zones.map((zone, index) => (
                    <div key={zone.id} className="flex items-center mb-2 p-2 border rounded">
                      <div 
                        className="w-5 h-5 mr-2 rounded-full" 
                        style={{ backgroundColor: zone.color }}
                      ></div>
                      <input
                        type="text"
                        value={zone.name}
                        onChange={(e) => handleZoneNameChange(zone.id, e.target.value)}
                        className="border p-1 mr-2 flex-1"
                        placeholder="Zone name"
                      />
                      <input
                        type="color"
                        value={zone.color}
                        onChange={(e) => handleZoneColorChange(zone.id, e.target.value)}
                        className="w-8 h-8 p-0 border-0"
                      />
                    </div>
                  ))}
                  
                  {zones.length === 0 && (
                    <p className="text-gray-500 text-sm italic">No zones created yet</p>
                  )}
                </div>
              </div>
            </div>
            
            {/* Spot Management - separated for docks and yard spots */}
            <div>
              <h2 className="font-bold mb-4 text-lg border-b pb-2">Spot Names & Assignments</h2>
              
              <div className="mb-4">
                <h3 className="font-medium mb-2">Dock Doors</h3>
                <div className="max-h-[200px] overflow-y-auto border rounded p-2">
                  {spots.filter(spot => spot.type === 'dock').map(spot => (
                    <div key={spot.id} className="flex items-center mb-2 last:mb-0">
                      <span className={`w-4 h-4 mr-2 ${
                        (spot as DockDoor).group === 'inbound' ? 'border-blue-500 border-2' : 
                        (spot as DockDoor).group === 'outbound' ? 'border-orange-500 border-2' : 
                        'border-gray-400 border'
                      }`}></span>
                      <span className="w-16 text-sm text-gray-600">Door {spot.id.split('-')[1]}:</span>
                      <input
                        type="text"
                        value={spot.number}
                        onChange={(e) => handleSpotNameChange(spot.id, e.target.value)}
                        className="border p-1 flex-1 text-sm mr-2"
                      />
                      <select 
                        value={(spot as DockDoor).group}
                        onChange={(e) => handleDockGroupChange(spot.id, e.target.value as DockGroup)}
                        className="border p-1 text-sm"
                      >
                        <option value="inbound">Inbound</option>
                        <option value="outbound">Outbound</option>
                        <option value="unassigned">Unassigned</option>
                      </select>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="mb-4">
                <h3 className="font-medium mb-2">Yard Spots</h3>
                <div className="max-h-[300px] overflow-y-auto border rounded p-2">
                  {spots.filter(spot => spot.type === 'yard').map(spot => (
                    <div key={spot.id} className="flex items-center mb-2 last:mb-0">
                      {/* Find the zone for this spot and use its color */}
                      {(() => {
                        const zone = zones.find(z => z.id === (spot as YardSpot).zoneId);
                        return (
                          <span 
                            className="w-4 h-4 mr-2 rounded-full" 
                            style={{ backgroundColor: zone ? zone.color : '#cccccc' }}
                          ></span>
                        );
                      })()}
                      <span className="w-16 text-sm text-gray-600">Spot {spot.id.split('-')[1]}:</span>
                      <input
                        type="text"
                        value={spot.number}
                        onChange={(e) => handleSpotNameChange(spot.id, e.target.value)}
                        className="border p-1 flex-1 text-sm mr-2"
                      />
                      <select 
                        value={(spot as YardSpot).zoneId}
                        onChange={(e) => handleYardZoneChange(spot.id, e.target.value)}
                        className="border p-1 text-sm"
                      >
                        {zones.map(zone => (
                          <option key={zone.id} value={zone.id}>{zone.name}</option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          
          <div className="mt-auto pt-5 border-t flex justify-end gap-3">
            <button
              className="bg-gray-200 hover:bg-gray-300 p-2 px-4 rounded"
              onClick={handleYardEditClose}
            >
              Cancel
            </button>
            <button
              className="bg-sky-600 hover:bg-sky-500 text-white p-2 px-4 rounded"
              onClick={handleYardEditSave}
            >
              Save Changes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default YardManagement; 