import { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Chip,
  Divider,
  Card,
  CardContent,
  IconButton,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Stack
} from '@mui/material';
import {
  Fullscreen,
  FullscreenExit,
  Refresh,
  AccessTime,
  DoorFront,
  LocalParking,
  Timer
} from '@mui/icons-material';
import { useDriverData } from '../UnassignedSlotDrivers/driverDataFetch';
import { useDockData } from '../DockManagement/dockDataFetch';

// Simplified color function based on driver type
const getDriverTypeColor = (type: string | undefined): "default" | "success" | "primary" | "error" | "secondary" | "info" | "warning" | undefined => {
  if (!type) return 'default';
  
  switch (type.toLowerCase()) {
    case 'inbound':
      return 'success';
    case 'outbound':
      return 'primary';
    default:
      return 'default';
  }
};

const WhiteBoards = () => {
  // State
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<'unassigned' | 'assigned'>('unassigned');
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshInterval, setRefreshInterval] = useState<number>(30); // seconds
  const [driverTimeInterval, setDriverTimeInterval] = useState<number>(12); // hours
  const [dockTimeInterval, setDockTimeInterval] = useState<number>(12); // hours
  
  // Get API data using the hooks from UnassignedSlotDriversWithRealData
  const {
    loading: apiDriverLoading,
    drivers: apiDrivers,
    error: driverError,
    facilities: driverFacilities,
    selectedFacility: driverSelectedFacility,
    setSelectedFacility: setDriverSelectedFacility,
    setTimeInterval: setDriverApiTimeInterval
  } = useDriverData();
  
  // Get dock data using the hook
  const {
    loading: dockLoading,
    dockDoors,
    error: dockError,
    selectedFacility: dockSelectedFacility,
    setSelectedFacility: setDockSelectedFacility,
    setTimeInterval: setDockApiTimeInterval
  } = useDockData();
  
  // Keep facility selection in sync between both hooks (same as UnassignedSlotDriversWithRealData)
  useEffect(() => {
    if (driverSelectedFacility && (!dockSelectedFacility || dockSelectedFacility.id !== driverSelectedFacility.id)) {
      setDockSelectedFacility(driverSelectedFacility);
    }
  }, [driverSelectedFacility, dockSelectedFacility, setDockSelectedFacility]);
  
  // Keep time intervals in sync
  useEffect(() => {
    setDriverApiTimeInterval(driverTimeInterval);
    setDockApiTimeInterval(dockTimeInterval);
  }, [driverTimeInterval, dockTimeInterval, setDriverApiTimeInterval, setDockApiTimeInterval]);
  
  // Handle dashboard refresh
  useEffect(() => {
    // Auto-refresh data based on interval
    const interval = setInterval(() => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }, refreshInterval * 1000);
    
    return () => clearInterval(interval);
  }, [refreshInterval]);
  
  // Toggle full screen
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message}`);
      });
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };
  
  // Handle manual refresh
  const handleRefresh = () => {
    setLoading(true);
    // In a real app, this would trigger re-fetching data
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  
  // Handle view mode change
  const handleViewModeChange = (event: any) => {
    setViewMode(event.target.value);
  };
  
  // Filter unassigned drivers - use real API data
  const unassignedDrivers = apiDrivers.filter(driver => 
    !dockDoors.some(door => door.assignedDriverId === driver.id)
  );
  
  // Get assigned doors with drivers
  const assignedDoors = dockDoors.filter(door => door.assignedDriverId);
  
  // Combine loading states
  const isLoading = loading || apiDriverLoading || dockLoading;
  
  // Handle facility change
  const handleFacilityChange = (event: any) => {
    const facilityId = event.target.value;
    const selectedFacility = driverFacilities.find(f => f.id === facilityId);
    if (selectedFacility) {
      setDriverSelectedFacility(selectedFacility);
    }
  };
  
  // Time interval options
  const timeIntervalOptions = [
    { value: 12, label: '12 Hours' },
    { value: 24, label: '24 Hours' },
    { value: 48, label: '48 Hours' },
    { value: 72, label: '3 Days' }
  ];
  
  // Handle time interval change
  const handleTimeIntervalChange = (event: any) => {
    const interval = parseInt(event.target.value);
    setDriverTimeInterval(interval);
    setDockTimeInterval(interval);
  };

  // Always use max time interval
  useEffect(() => {
    setDriverTimeInterval(8760); // Max - 1 year
    setDockTimeInterval(8760); // Max - 1 year
  }, [setDriverTimeInterval, setDockTimeInterval]);

  return (
    <div className={`whiteboard-container ${isFullScreen ? 'fullscreen' : ''}`} style={{ 
      padding: isFullScreen ? '0' : '20px',
      height: isFullScreen ? '100vh' : 'calc(100vh - 120px)',
      width: '100%',
      backgroundColor: '#f0f2f5',
      overflow: 'hidden',
      position: 'relative'
    }}>
      {/* Header section */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        backgroundColor: isFullScreen ? '#1976d2' : 'transparent',
        color: isFullScreen ? 'white' : 'inherit',
        padding: isFullScreen ? '12px 24px' : '0 0 16px 0',
        position: 'relative',
        zIndex: 10
      }}>
        <Typography variant="h4" component="h1">
          Driver Dashboard
        </Typography>
        
        <Stack direction="row" spacing={2} alignItems="center">
          {/* Facility Selector */}
          <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
            <InputLabel>Facility</InputLabel>
            <Select
              value={driverSelectedFacility?.id || ''}
              onChange={handleFacilityChange}
              label="Facility"
            >
              {driverFacilities.map(facility => (
                <MenuItem key={facility.id} value={facility.id}>
                  {facility.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          {/* Time Interval Selector */}
          <div className="w-40 flex items-center justify-center border rounded px-3 py-2 bg-gray-50">
            <span className="text-sm font-medium">Time: Max</span>
          </div>
          
          {/* View Mode Selector */}
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel>View</InputLabel>
            <Select
              value={viewMode}
              onChange={handleViewModeChange}
              label="View"
            >
              <MenuItem value="unassigned">Unassigned Drivers</MenuItem>
              <MenuItem value="assigned">Assigned Drivers</MenuItem>
            </Select>
          </FormControl>
          
          {/* Control buttons */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
              <Timer sx={{ mr: 0.5 }} />
              <Typography variant="body2" sx={{ mr: 1 }}>
                Refresh: {refreshInterval}s
              </Typography>
              <IconButton 
                size="small" 
                onClick={() => setRefreshInterval(refreshInterval > 15 ? refreshInterval - 15 : 60)}
                color={isFullScreen ? 'inherit' : 'primary'}
              >
                -
              </IconButton>
              <IconButton 
                size="small" 
                onClick={() => setRefreshInterval(refreshInterval < 60 ? refreshInterval + 15 : 15)}
                color={isFullScreen ? 'inherit' : 'primary'}
              >
                +
              </IconButton>
            </Box>
            <IconButton 
              color={isFullScreen ? 'inherit' : 'primary'} 
              onClick={handleRefresh} 
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} color={isFullScreen ? 'inherit' : 'primary'} /> : <Refresh />}
            </IconButton>
            <IconButton 
              color={isFullScreen ? 'inherit' : 'primary'} 
              onClick={toggleFullScreen}
            >
              {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
          </Box>
        </Stack>
      </Box>
      
      {/* Main Content */}
      <Box sx={{ mt: 2, height: 'calc(100% - 80px)', overflow: 'hidden' }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : driverError || dockError ? (
          <Paper sx={{ p: 3, textAlign: 'center' }}>
            <Typography color="error" variant="h6">
              Error loading data: {driverError || dockError}
            </Typography>
            <Button variant="contained" onClick={handleRefresh} sx={{ mt: 2 }}>
              Retry
            </Button>
          </Paper>
        ) : (
          viewMode === 'unassigned' ? (
            // Unassigned Drivers View
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">
                      <AccessTime sx={{ mr: 1, verticalAlign: 'middle' }} />
                      Waiting Drivers ({unassignedDrivers.length})
                    </Typography>
                  </Box>
                  
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Driver</TableCell>
                          <TableCell>Shipment</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Trailer</TableCell>
                          <TableCell>Truck</TableCell>
                          <TableCell>Arrival Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {unassignedDrivers.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={6} align="center" sx={{ py:.5 }}>
                              <Typography variant="subtitle1" color="text.secondary">
                                No unassigned drivers
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          unassignedDrivers.map(driver => (
                            <TableRow key={driver.id}>
                              <TableCell>
                                <Typography variant="body1" fontWeight="medium">
                                  {driver.name || 'Unknown Driver'}
                                </Typography>
                                {driver.company && (
                                  <Typography variant="caption" color="text.secondary">
                                    {driver.company}
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell>{driver.loadNumber || 'N/A'}</TableCell>
                              <TableCell>
                                {driver.type && (
                                  <Chip 
                                    label={driver.type.charAt(0).toUpperCase() + driver.type.slice(1)} 
                                    color={getDriverTypeColor(driver.type)}
                                    size="small"
                                  />
                                )}
                              </TableCell>
                              <TableCell>{driver.trailerNumber || 'N/A'}</TableCell>
                              <TableCell>{driver.truckNumber || 'N/A'}</TableCell>
                              <TableCell>{driver.arrivalTime || 'Unknown'}</TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            // Assigned Drivers View
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">
                      <DoorFront sx={{ mr: 1, verticalAlign: 'middle' }} />
                      Active Dock Doors ({assignedDoors.length})
                    </Typography>
                  </Box>
                  
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Door</TableCell>
                          <TableCell>Driver</TableCell>
                          <TableCell>Shipment</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Trailer</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {assignedDoors.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={6} align="center" sx={{ py: 5 }}>
                              <Typography variant="subtitle1" color="text.secondary">
                                No active dock doors
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          assignedDoors.map(door => {
                            const driver = apiDrivers.find(d => d.id === door.assignedDriverId);
                            
                            return (
                              <TableRow key={door.id}>
                                <TableCell>
                                  <Typography variant="body1" fontWeight="medium">
                                    {door.number}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body1">
                                    {door.driverName || (driver?.name) || 'Unknown Driver'}
                                  </Typography>
                                  {driver?.company && (
                                    <Typography variant="caption" color="text.secondary">
                                      {driver.company}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell>{door.shipmentNumber || driver?.loadNumber || 'N/A'}</TableCell>
                                <TableCell>
                                  {driver?.type && (
                                    <Chip 
                                      label={driver.type.charAt(0).toUpperCase() + driver.type.slice(1)} 
                                      color={getDriverTypeColor(driver.type)}
                                      size="small"
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body1">
                                    {(door.assignedDriverId && apiDrivers.find(d => d.id === door.assignedDriverId)?.trailerNumber) || 'N/A'}
                                  </Typography>
                                  {door.assignedDriverId && apiDrivers.find(d => d.id === door.assignedDriverId)?.truckNumber && (
                                    <Typography variant="body2" color="text.secondary">
                                      Truck: {apiDrivers.find(d => d.id === door.assignedDriverId)?.truckNumber}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    label="In Use"
                                    color="error"
                                    size="small"
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          )
        )}
      </Box>
    </div>
  );
};

export default WhiteBoards; 